.page-faq {
  padding-top: 80px;
  // padding-top: 0;

  background-color: $color-page-start;
  padding-bottom: 150px;

  @media (max-height: $smallScreenHeight) {
    // padding-top: 20px;
    padding-top: 0;
  }

  @media (min-width: $template1400) {
    max-width: none;
    padding-top: 50px;
  }

  .container {
    max-width: 980px;
    margin: auto;

    padding-left: 20px;
    padding-right: 20px;
  }

  .full-list {
    h2 {
      color: #000000;
      // font-size: 30px;
      font-size: 26px;
      line-height: 1.5;
      // padding-top: 50px;
      margin-bottom: 26px;
      font-family: $font700;
      font-weight: 700;

      .colored {
        color: $color-theme;
      }
    }

    p + h2 {
      // padding-top: 50px;
      padding-top: 70px;
    }

    h3 {
      color: $color-theme-2;
      // font-size: 30px;
      // font-size: 24px;
      font-size: 22px;
      line-height: 1.6;
      // margin-bottom: 23px;
      margin-bottom: 5px;
      font-family: $font700;
      font-weight: 700;

      @media (min-width: $tabletVMin) {
      }

      @media (min-width: $template1400) {
      }
    }

    p + h3 {
      padding-top: 35px;
    }

    p {
      // font-size: 24px;
      font-size: 20px;
      line-height: 1.4;
      color: #676767;

      @media (min-width: $tabletVMin) {
        // font-size: 20px;
        // color: #7a8491;
      }

      @media (min-width: $template1400) {
      }
    }
  }

  .faq-section--responsive {
    background: transparent;
  }
}
