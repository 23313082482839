#want-to-try {
  background-color: $color-theme-2;

  background-image: url('/images/try-mobile.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 100%;

  padding-top: 111px;
  padding-bottom: 91px;

  @media (min-width: $tabletVMin) {
    padding-top: 111px;
    padding-bottom: 168px;
  }

  @media (min-width: $template1400) {
    padding-top: 53px;
    height: 500px;

    background-image: url('/images/try-desktop.jpg');
  }

  h2 {
    margin: auto;
    margin-bottom: 26px;
    text-align: center;
    color: #fff;
    font-size: 24px;
    margin-bottom: 41px;

    @media (min-width: $template1400) {
      max-width: none;
      font-size: 38px;
      line-height: 1.2;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.26;
      letter-spacing: -1.1px;
      margin-bottom: 10px;
    }
  }

  p {
    text-align: center;
    color: #fff;
    font-size: 17px;
    line-height: 1.3;
    margin-bottom: 20px;
    padding-left: 0px;
    padding-right: 0px;

    @media (min-width: $template1400) {
      margin: auto;
      line-height: 1.56;
      // font-size: 18px;
      padding-left: 70px;
      padding-right: 70px;
      margin-bottom: 23px;
    }
  }

  .colored {
    color: $color-theme;
    text-align: center;
    // font-size: 18px;
    font-size: 17px;
    padding-top: 10px;
    margin-bottom: 12px;

    @media (min-width: $template1400) {
      font-size: 20px;
      padding-top: 0;
      margin-bottom: 5px;
    }
  }

  .button-row {
    padding-top: 3px;
    text-align: center;

    @media (min-width: $template1400) {
      padding-top: 0px;
      margin-top: 30px;
    }
  }

  .button {
    display: block;
    width: 224px;
    // font-size: 15px;
    font-size: 17px;
    height: 50px;
    line-height: 50px;

    @media (min-width: $template1400) {
      display: block;
      font-size: 20px;
      height: 60px;
      line-height: 60px;
      border-radius: 5px;
      width: 370px;
      max-width: 370px;
      padding: 0;
    }
  }

  .more {
    text-align: center;
    color: white;
    text-decoration: none;
    font-family: $font300;

    display: inline-block;
    font-size: 16px;
    margin-top: 10px;

    &:hover {
      text-decoration: underline;
    }
  }
}
