.steps-section-v32 {
  margin-bottom: 16px;

  .title {
    line-height: 1.5;
    font-family: $font600;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
    padding: 0 30px;

    font-size: 26px;

    @media (min-width: $tabletVMin) {
      font-size: 32px;
    }
  }

  .steps {
    display: flex;
    flex-direction: column;

    .step {
      display: flex;
      flex-direction: column;

      .step-title {
        display: flex;
        line-height: 1.7;
        margin-bottom: 0px;
        font-family: $font600;
        font-weight: 600;
        color: $color-theme;
        text-align: center;
        justify-content: center;

        font-size: 17px;
        margin-bottom: 4px;

        @media (min-width: $tabletVMin) {
          margin-bottom: 0px;

          font-size: 24px;
        }

        @media (min-width: $template1400) {
          margin-bottom: 18px;
        }
      }

      .step-content {
        display: flex;
        text-align: center;
        color: $color-step;
        line-height: 1.7;
        justify-content: center;
        font-size: 17px;

        padding-left: 15px;
        padding-right: 15px;

        @media (min-width: $tabletVMin) {
          padding-left: 0;
          padding-right: 0;
        }

        @media (min-width: $template1400) {
          line-height: 1.9;
        }
      }

      .step-divider {
        display: flex;
        justify-content: center;
        width: 1px;
        height: 40px;
        border-left: dashed 1px $color-important-border;
        margin-left: 50%;
        margin-bottom: 4px;

        @media (min-width: $tabletVMin) {
          // border-left: dashed 2px $color-important-border;
        }
      }
    }
  }
}
