.promo-banner {
    position: fixed;
    text-align: center;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    line-height: 1.2;
    padding-left: 70px;
    padding-right: 70px;
    background-color: #000;
    color: #fff;
    font-size: 16px;
    font-family: $font600;
    font-weight: 600;
    z-index: z('promo-banner');
    display: none;
    @media (min-width: $tabletVMin) {
        font-size: 20px;
    }

    .promo-close {
        width: 30px;
        height: 30px;
        background-image: url('/ico/close-lite.svg');
        background-repeat: no-repeat;
        background-position: 50% 100%;
        background-size: contain;
        background-size: 30px;
        position: relative;
        z-index: z('promo-banner--close');
        position: absolute;
        right: 20px;
        top: 30px;
        cursor: pointer;
    
        @media (min-width: $tabletVMin) {
            right: 20px;
            top: 17px;

            display: none !important;
        }

    }
}

// ! Promo Baner ON - START
// .promo-banner {
//     height: 98px;

//     @media (min-width: $tabletVMin) {
//         height: 64px;
//     }
// }

// .page-promo {
//     margin-top: 98px !important;

//     @media (min-width: $tabletVMin) {
//         margin-top: 64px !important;
//     }

//     .promo-banner {
//       display: flex;
//     }

//     .header-first-page progress,
//     .header-first-page,
//     .fixed-header {
//         margin-top: 98px !important;

//         @media (min-width: $tabletVMin) {
//             margin-top: 64px !important;
//         }
//     }
// }
// ! Promo Baner ON - END

// ! Promo Baner OFF - START
.promo-banner {
    display: none!important;
}
// ! Promo Baner OFF - END