.upgrade-popup {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10000;
	background-color: rgba(0,0,0,0.5);
	.upgrade-popup--wrap {
		width: 100%;
		height: 100%;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.upgrade-popup--inner {
		text-align: center;
		position: relative;
		background-color: #fff;
		border-radius: 30px;
		padding: 15px;
		padding-top: 20px;
		padding-bottom: 20px;
		width: 100%;
    	max-width: 1200px;
		max-height: 90%;
		overflow-y: auto;
		margin-left: 15px;
		margin-right: 15px;
		@media (min-width: $tabletVMin) {
			padding: 30px;
			padding-top: 40px;
			padding-bottom: 40px;
			margin-left: 40px;
			margin-right: 40px;
		}
	}
	.title {
		color: $color-theme-2;
		font-size: 20px;
		font-family: $font600;
		font-weight: 600;
		line-height: 1.5;
		margin: 0 0 20px;
		@media (min-width: $tabletVMin) {
			font-size: 42px;
			line-height: 1.15;
		}
	}
	.text {
		color: $color-theme-2;
		font-size: 16px;
		font-family: $font400;
		line-height: 1.2;
		margin: 0 0 25px;
		@media (min-width: $tabletVMin) {
			font-size: 20px;
		}
	}
	.img-map {
		max-width: 100%;
		@media (min-width: $tabletVMin) {
			max-width: 40vw;
		}
	}
	.btn-row {
		margin: 45px auto 0;
		display: flex;
		justify-content: center;
		flex-direction: column;
		gap: 5px;
		max-width: 100%;
		align-items: center;
		@media (min-width: $tabletVMin) {
				flex-direction: row;
		}
		.btn {
			border: 1px solid $color-theme;
			border-radius: 15px;
			color: #000;
			display: inline-block;
			font-family: $font600;
			font-weight: 600;
			font-size: 16px;
			padding: 15px 50px;
			text-decoration: none;
			width: 100%;
			max-width: 350px;
			@media (min-width: $tabletVMin) {
				max-width: 250px;
			}
			&.active {
				background-color: $color-theme;
				color: $color-white;
			}
		}
	}
}
.no-scrollbar::-webkit-scrollbar {
	display: none;
}
.no-scrollbar {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

// upsell page

.page-upgrage-multi-license {
	.upsell-wrap {
		padding-top: 32px;
		padding-bottom: 50px;
		@media (min-width: $tabletVMin) {
			padding-top: 45px;
		}
		.reschedule-logo {
			display: flex;
			justify-content: center;
		}
		.title {
			text-align: center;
			color: $color-theme-2;
			font-size: 20px;
			font-family: $font600;
			font-weight: 600;
			line-height: 1.5;
			margin: 45px 0 16px;
			text-wrap: balance;
			@media (min-width: $tabletVMin) {
				font-size: 42px;
				line-height: 1.15;
				margin: 80px 0 20px;
			}
		}
		.text {
			text-align: center;
			color: $color-theme-2;
			font-size: 16px;
			font-family: $font400;
			margin: 0 0 30px;
			@media (min-width: $tabletVMin) {
				font-size: 20px;
			}
		}
		.img-map {
			display: block;
			max-width: 100%;
			margin: 25px auto;
			@media (min-width: $tabletVMin) {
				margin: 60px auto 15px;
			}
		}
		.btn-row {
			margin: 0 auto;
			padding: 48px 0 15px;
			display: flex;
			justify-content: center;
			flex-direction: column;
			gap: 8px;
			max-width: 100%;
			align-items: center;
			left: 0;
			right: 0;
			@media (min-width: $tabletVMin) {
				flex-direction: row;
				padding: 88px 0 25px;
			}
			.btn {
				cursor: pointer;
				text-align: center;
				border: 1px solid $color-theme;
				border-radius: 15px;
				color: #000;
				display: inline-block;
				font-family: $font600;
				font-weight: 600;
				font-size: 16px;
				padding: 15px 15px;
				text-decoration: none;
				width: 100%;
				max-width: 350px;
				transition: all 0.15s;
				box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
				&:hover {
					background-color: $color-button-hover;
					color: $color-white;
				}
				@media (min-width: $tabletVMin) {
					max-width: 250px;
				}
				&.active {
					background-color: $color-theme;
					color: $color-white;
					&:hover {
						background-color: $color-button-hover;
					}
				}
			}
		}
	}
}