.video-philosophy {
  width: 100%;
  border-radius: 24px;
  overflow: hidden;
  position: relative;
  // margin-bottom: 54px;
  margin-bottom: 20px;

  background-image: url('/images/2023/live-fire.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;

  border: 4px solid white;

  @media (min-width: $tabletVMax) {
    max-width: 90%;
  }

  @media (min-width: $template1400) {
    width: 500px;
    float: left;
    position: relative;
    margin-bottom: 20px;
  }

  @media (min-width: $template1400Full) {
    width: 630px;
  }

  img {
    width: 100%;
  }
  video {
    width: 100%;
  }

  .play-btn {
    position: absolute;
    top: 0;
    left: 0;
    z-index: z('play-btn');
    width: 100%;
    height: 100%;

    box-shadow: none;
    border: 4px solid white;

    .img {
      cursor: pointer;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: z('play-btn-img');

      width: 70px;
      height: 70px;
      margin-left: -35px;
      margin-top: -35px;
      text-align: center;

      background-color: rgba(255, 255, 255, 0.5);
      padding-top: 17px;
      padding-left: 8px;
      border-radius: 50%;
      outline: none !important;

      img {
        display: block;
        width: 30px;
        margin: auto;
      }
    }
  }
}
