.who-for {
  background-color: #b3bdc8;
  background-image: url('/images/shallow-focus-shot-from-male-shooting-gun-gun-range-mobile.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 60% 60%;

  // display: flex;
  // align-items: center;
  // justify-content: center;

  padding: 154px 22px 31px 22px;
  margin-bottom: 20px;

  @media (min-width: $tabletVMin) {
    background-image: url('/images/shallow-focus-shot-from-male-shooting-gun-gun-range.jpg');
    background-size: cover;
    height: 460px;
    min-height: 460px;
    padding: 100px 0px 100px 0px;
    background-position: 50% 50%;

    margin-bottom: 0;
  }

  @media (min-width: $template1400) {
    background-image: url('/images/shallow-focus-shot-from-male-shooting-gun-gun-range.jpg');
    background-size: cover;
    background-position: 50% 50%;
    height: 540px;
    min-height: 540px;

    padding: 100px 0px 135px 0px;
    margin-bottom: 0;
  }

  .desktop-wide-wrap {
    @media (max-width: $mobileResponsiveMax) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .who-for-content-flex {
    @media (min-width: $template1400) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .who-for-content {
    background-color: #fff;
    border-radius: 10px;
    padding: 41px 33px 33px 33px;

    @media (min-width: $template1400) {
      padding: 46px 46px 60px 46px;
      width: 606px;
    }

    .title {
      line-height: 1.5;
      font-family: $font700;
      font-weight: 700;
      text-align: left;
      // font-size: 19px;
      font-size: 23px;
      margin-bottom: 20px;

      @media (min-width: $template1400) {
        // font-size: 22px;
        font-size: 24px;
        margin-bottom: 47px;
        text-align: center;
      }
    }

    .for {
      position: relative;
      padding-left: 16px;
      display: block;

      border-bottom: 1px dashed #ccd7e5;
      padding-bottom: 17px;
      margin-bottom: 23px;

      font-size: 14px;
      line-height: 1.9;

      &:last-child {
        border: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      @media (min-width: $tabletVMin) {
        padding-bottom: 16px;
        font-family: $font500;
        font-weight: 500;
        padding-left: 20px;
        font-size: 16px;
        line-height: 1.55;
        margin-bottom: 14px;
      }
      @media (min-width: $template1400) {
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 8px;
        left: 0;
        width: 8px;
        height: 8px;
        background-image: url('/images/who-for.svg');
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: contain;

        @media (min-width: $template1400) {
        }
      }
    }
  }
}
