.heading-section {
  height: 160px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  .title {
    display: flex;
    flex-direction: column;
    line-height: 1.5;

    @media (min-width: $tabletVMin) {
    }
  }

  .subtitle {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.3;
    text-align: center;
    color: #000;
    margin-bottom: 20px;

    @media (min-width: $tabletVMin) {
      font-size: 24px;
    }
  }

  strong {
    text-align: center;
    display: block;
    // font-size: 24px;
    font-size: 19px;
    line-height: 1.5;
    color: #000;
    font-family: $font700;
    font-weight: 700;
    @media (min-width: $tabletVMin) {
      font-size: 26px;
    }

    @media (max-width: $mobileResponsiveMax) {
      font-size: 26px;
    }
  }

  .button {
    width: 352px; // optional
    display: block;
  }
}
