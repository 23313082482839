.logos {
  padding-bottom: 50px;
  overflow: hidden;

  @media (min-width: $template1400) {
    padding-bottom: 52px;
  }

  .text-row {
    .title {
      line-height: 1;
      color: $color-theme-2;
      font-family: $font700;
      font-weight: 700;
      margin-bottom: 30px;
      font-size: 38px;
      text-align: center;

      @media (min-width: $tabletVMin) {
        font-size: 42px;
        text-align: left;
        margin-bottom: 21px;
      }

    }
  }
  .logos-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    flex-direction: column;

    @media (min-width: $tabletVMin) {
      flex-direction: row;
    }

    .item {
      width: 100%;
      text-align: center;
      @media (min-width: $tabletVMin) {
       width: 30%;
      }
      @media (min-width: $tabletH) {
        width: 30%;    
      }

      img {
        width: 100%;
        max-width: 300px;
        @media (min-width: $tabletVMin) {
          max-width: 100%;
        }
      }
    }
  }

}