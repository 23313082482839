.review-section-v3 {
  max-width: 1344px;
  margin: auto;

  @media (max-width: $mobileResponsiveMax) {
    padding-bottom: 128px;
    // width: 100vw;
    // overflow: hidden;
  }

  .review {
    .review-title-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      @media (min-width: $mobileResponsiveMin) {
        padding-left: 20px;
        padding-right: 20px;
      }
      @media (min-width: $tabletH) {
        justify-content: flex-start;
        margin-bottom: 40px;
        height: 48px;
        padding-left: 0;
        padding-right: 0;
      }

      .review-title {
        color: $color-theme-2;
        font-size: 38px;
        font-family: $font600;
        font-weight: 600;
        margin-right: 0;

        @media (min-width: $tabletVMin) {
          font-size: 42px;
          margin-right: 124px;
        }
      }
      .review-subtitle {
        color: #6C6D78;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        font-family: $fontComissioner;

        @media (max-width: $mobileResponsiveMax) {
            font-size: 12px;
            line-height: 16px;
        }
      }
    }

    .review-carousel-v3 {
      //position: relative;  
      max-width: 318px;
      margin: 0 auto;
      @media (min-width: $template1400) {
        margin-left: -12px;
        margin-right: -12px;
        max-width: 100%;
      }
    }

    // .item {
      // width: 318px;
      // height: 552px;
      // margin-right: 24px;
  
      // @media (max-width: $mobileResponsiveMax) {
      //   width: 100vw;
      //   padding-left: 20px;
      //   padding-right: 20px;
      // }
    // }

    .review-name-row {
      color: $color-theme-2;
      font-family: $font600;
      font-weight: 600;
      text-align: left;
      line-height: 1;
      font-size: 20px;
      margin-top: 15px;
      margin-bottom: 5px;

      @media (min-width: $tabletVMin) {
        padding-right: 12px;
        padding-left: 12px;
        font-size: 18px;
      }
    }

    .review-q {
      width: 24px;
      height: 24px;
      font-size: 12px;
      margin-bottom: 8px;
      background-color: #D8374F;
      border-radius: 50%;
      color: #fff;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-family: $fontComissioner;

      @media (max-width: $mobileResponsiveMax) {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 8px;
      }
    }

    .review-text {
      color: #6C6D78;
      font-size: 18px;
      line-height: 24px;
      text-align: left;
      font-family: $fontComissioner;

      @media (min-width: $tabletVMin) {
        padding-right: 12px;
        padding-left: 12px;
        font-size: 16px;
      }
    }
    .review-image-row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      overflow: hidden;
      height: 386px;

      @media (min-width: $tabletVMin) {
        margin-left: 12px;
        margin-right: 12px;
      }
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        display: block;
      }
    }
  }

  .slick-arrow.slick-prev {
    width: 48px;
    height: 48px;
    background-color: transparent;
    background-image: url('/assets-v3/arrow-carousel--disabled.svg');
    background-repeat: no-repeat;
    background-size: 48px;
    background-position: 50% 50%;
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    z-index: 5;
    top: -88px;
    right: 76px;
    left: auto;
    position: absolute;
    &:hover {
      background-image: url('/assets-v3/arrow-carousel--active.svg');
      transform: rotateZ(180deg);
    }
  
    @media (max-width: $mobileResponsiveMax) {
      top: auto;
      right: auto;
      bottom: -60px;
      left: calc(50% - 0px);
    }
  }
  
  .slick-next.slick-arrow {
    width: 48px;
    height: 48px;
    background-color: transparent;
    background-image: url('/assets-v3/arrow-carousel--disabled.svg');
    transform: rotateZ(180deg);
    background-repeat: no-repeat;
    background-size: 48px;
    background-position: 50% 50%;
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    z-index: 5;
    position: absolute;
    left: auto;
    top: -88px;
    right: 12px;
    margin-right: 0;
    &:hover {
      background-image: url('/assets-v3/arrow-carousel--active.svg');
      transform: rotateZ(360deg);
    }
  
    @media (max-width: $mobileResponsiveMax) {
      top: auto;
      right: auto;
      bottom: -60px;
      right: calc(50% - 48px);
      left: auto;
    }
  }
  
}


