.book-now-mobile-opener {
  // TODO: Check this ! (ML)
  display: none !important;

  @media (max-width: $mobileResponsiveMax) {
    display: none !important;
  }
}

// ! Chat now minus + Book floating button
.main-homepage,
.check-results,
.book-now-section {
  @media (max-width: $mobileResponsiveMax) {
    padding-bottom: 50px;
  }
  // .sms-mobile-opener {
  //   display: none!important;
  // }

  .book-now-mobile-opener {
    @media (max-width: $mobileResponsiveMax) {
      display: block !important;
      position: fixed;
      bottom: 20px;
      width: 100%;
      z-index: 9;
    }
    
    .desktop-wide-wrap {
      &.dropdown-wrap {
        .sign-up-dropdown {
          margin: 0 auto;
          max-width: 330px;
        }
      }
    }

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      &.active {
        .arrow-ico {
          transform: rotate3d(1, 0, 0, 180deg);
        }
      }
      .arrow-ico {
        margin-left: 5px;
        transition: all 0.2s ease-out;;
      }
    }
  }
}
