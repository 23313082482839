.map-section {
  background-color: $color-theme-2;
  padding-top: 50px;
  padding-bottom: 50px;
  .heading-section {
    margin-bottom: 50px;

    @media (min-width: $tabletVMin) {
      margin-bottom: 60px;
    }

    .title {
      color: $color-white;
      strong {
        color: $color-white;
        font-size: 38px;
        font-family: $font600;
        font-weight: 600;
  
        @media (min-width: $tabletVMin) {
          font-size: 42px;
        }
      }
    }
  }

  .img-map {
    width: 100%;
    max-width: 1168px;
    margin: 0 auto;
  }
}
