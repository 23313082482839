.footer-full-v3 {
  
  padding-bottom: 48px;

  @media (max-width: $mobileResponsiveMax) {
    padding-bottom: 20px;
  }

  .footer-full--content {

    padding: 150px 114px;
    max-width: 1344px;
    margin: auto;
    border-radius: 48px;

    padding-bottom: 48px;

    // margin-bottom: 48px;

    background-color: #17181B;

    @media (max-width: $mobileResponsiveMax) {
      padding: 32px 16px;
      width: calc(100% - 40px);
      margin: auto;
      border-radius: 20px;

      padding-bottom: 20px;
    }

    .container {
      margin: auto;

    }
  
    .row-1  {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      position: relative;

      
      @media (max-width: $mobileResponsiveMax) {
        flex-direction: column;
        align-items: center;
        margin-bottom: 24px;
      }


      &:before {
        display: block;
        content: "";
        position: absolute;
        z-index: 1;
        bottom: 73px;
        right: 0px;
        width: 80px;
        height: 80px;
        background-image: url('/assets-v3/bullet-footer-v3.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 0 0;
        opacity: 0.25;
  
        @media (max-width: $mobileResponsiveMax) {
          display: none;
        }
      }

      .col-1{
        display: flex;
        justify-content: flex-start;
        flex-direction: column;

        @media (max-width: $mobileResponsiveMax) {
          justify-content: center;
        }

        .title {
          font-size: 100px;
          line-height: 110px;
          color :#fff;
          margin-bottom: 64px; 
          text-align: left;
          font-family: $font600;
          font-weight: 600;

          @media (max-width: $mobileResponsiveMax) {
            font-size: 32px;
            line-height: 40px;
            text-align: center;
            margin-bottom: 18px;
          }
        }

        .button {
          float: left;
          height: 80px;
          line-height: 80px;
          width: 368px;
          max-width: 368px;
          border-radius: 24px;
          font-size: 16px;
          margin: 0;
          margin-bottom: 150px;
    
          @media (max-width: $mobileResponsiveMax) {
            height: 56px;
            line-height: 56px;
            width: 180px;
            border-radius: 20px;
            font-size: 14px;
            float: none;
            margin: auto;
            margin-bottom: 24px;
            font-family: $font500;
            font-weight: 500;
          }
          
        }
      }
      .col-2{
        // display: flex;
        // flex-direction: column;
        // align-items: flex-end;
        // gap: 24px;

        @media (max-width: $mobileResponsiveMax) {
          // gap: 24px;
          // align-items: flex-end;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          border-top: 1px solid #232528;
          border-bottom: 1px solid #232528;
          padding-top: 24px;
          padding-bottom: 24px;
        }

        .col-2--inner {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 24px;

          @media (max-width: $mobileResponsiveMax) {
            width: 115px;
            align-items: flex-end;
            display: flex;
            flex-direction: column;
          }
        }

        .a--footer__soc {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 24px;
          font-size: 20px;
          line-height: 28px;
          color: #9CA3AF;
          text-decoration: none;
          font-family: $font500;
          font-weight: 500;

          @media (max-width: $mobileResponsiveMax) {
            gap: 24px;
            font-size: 16px;
            line-height: 24px;
            font-family: $font600;
            font-weight: 600;
          }

          &:hover {
            text-decoration: underline;
          }

          img {
            width: 9px;
            height: 9px;
          }
        }
      }
    }
    .row-2  {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 48px;
      padding-bottom: 64px;
      margin-bottom: 48px;
      border-bottom: 1px solid #232528;
      font-family: $font600;
      font-weight: 600;

      @media (max-width: $mobileResponsiveMax) {
        justify-content: space-between;
        width: 288px;
        margin: auto;
        padding-bottom: 24px;
        margin-bottom: 24px;
      }

      .col-1,
      .col-2{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 48px;

        @media (max-width: $mobileResponsiveMax) {
          flex-direction: column;
          gap: 24px;
        }
      }

      a {
        color: #9CA3AF;
        font-size: 16px;
        line-height: 24px;
        text-decoration: none;
        font-family: $font600;
        font-weight: 600;

        &:hover {
          text-decoration: underline;
        }

        @media (max-width: $mobileResponsiveMax) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    .row-3  {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      color: #fff;
      // color: #9CA3AF;
      font-size: 14px;
      text-align: center;
      font-family: $fontComissioner;

      opacity: 0.5;
    

      @media (max-width: $mobileResponsiveMax) {
        font-size: 12px;
      }
    }
  }
} 
