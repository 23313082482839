.urgent-msg {
    position: relative;
    z-index: -1;
    font-weight: 500;
    background-color: #d8374f;
    color: #fff;
    text-align: center;
    width: 100%;
    padding: 5px 35px 5px 10px;
    .close-btn {
        width: 15px;
        height: 15px;
        background-image: url('/ico/close-lite.svg');
        background-repeat: no-repeat;
        background-position: 50% 100%;
        background-size: contain;
        background-size: 15px;
        position: relative;
        z-index: z('promo-banner--close');
        position: absolute;
        right: 15px;
        top: calc(50% - 7.5px);
        cursor: pointer;
        @media (min-width: $tabletVMin) {
            right: 15px;
        }
    }
}
.after--header-first--small {
    &.pd-msg {
        padding-top: 180px;
    }
}
.faq-section--responsive {
    &.pd-msg {
        .faq-section {
            padding-top: 110px;
        }
    }
}