.how-it-works-page {
    background-color: $color-white;
    color: $color-primary;
    font-size: 16px;
    @media (min-width: $tabletVMin) {
        font-size: 18px;
    }
    .container-how {
        margin: 0 auto;
        max-width: 900px;
    }
    .hero {
        margin-bottom: 12px;
        @media (min-width: $tabletVMin) {
            padding-top: 175px;
            margin-bottom: 28px;
        }
        .title {
            font-family: $font600;
            font-weight: 600;
            color: $color-theme-2;
            text-align: left;
            font-size: 28px;
            margin: 0 0 25px;
            line-height: 1.25;
            letter-spacing: -1px;
            @media (min-width: $tabletVMin) {
                font-size: 48px;
                margin: 0 0 50px;
            }
        }
    }
    .hero-img {
        max-width: 100%;
        border-radius: 20px;
        margin: 0 auto;
    }
    .step-item {
        margin-bottom: 20px;
        @media (min-width: $tabletVMin) {
            margin-bottom: 45px;
        }
    }
    .title-section {
        color: $color-theme-2;
        font-size: 24px;
        font-family: $font600;
        font-weight: 600;
        margin: 0 0 5px;
        @media (min-width: $tabletVMin) {
            font-size: 32px;
            margin: 0 0 28px;
        }
    }
    .how-list {
        font-size: 16px;
        list-style: none;
        font-family: $fontComissioner;
        padding-left: 20px;
        @media (min-width: $tabletVMin) {
            font-size: 20px;
        }
        li {
            width: 100%;
            position: relative;
            margin-bottom: 5px;
            border-radius: 6px;
            padding: 4px 6px 6px;
            max-width: max-content;
            @media (min-width: $tabletVMin) {
                margin-bottom: 9px;
            }
            &:before {
                position: absolute;
                top: 10px;
                left: -20px;
                z-index: 1;
                content: '';
                display: block;
                width: 13px;
                height: 13px;
                background-image: url('/ico/first-page/li.svg');
                background-repeat: no-repeat;
                background-size: contain;
                background-position: 50% 50%;
            }
            &.active {
                background: rgba(255, 134, 152, 0.2);
                color: #FF8698;
            }
        }
    }
    .description {
        margin-bottom: 25px;
        line-height: 1.6;
        @media (min-width: $tabletVMin) {
            margin-bottom: 55px;
        }
        a {
            color: $color-primary;
            transition: all .15s;
            &:hover {
                color: #D8374F;
            }
        }
        &.underline {
            font-size: 22px;
            text-decoration: underline;
            @media (min-width: $tabletVMin) {
                font-size: 28px;
            }
        }
        p {
            margin-bottom: 12px;
        }
    }
    .banner-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $color-theme-2;
        width: 100%;
        padding: 20px 32px;
        border-radius: 20px;
        color: $color-white;
        background-image: url('/images/how-it-works/banner-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        margin: 50px 0 25px;
        @media (min-width: $tabletVMin) {
            flex-direction: row;
            margin: 40px 0 85px;
        }
        .img-holder {
            margin: -55px auto 15px;
            @media (min-width: $tabletVMin) {
                margin: -55px auto -75px -65px;
            }
            img {
                max-width: 100%;
                @media (min-width: $tabletVMin) {
                    max-width: none;
                }
            }
        }
        .text-holder {
            @media (min-width: $tabletVMin) {
                max-width: 480px;
            }
        }
        .title {
            font-family: $font600;
            font-weight: 600;
        }
        .description {
            font-size: 18px;
            line-height: 1.5;
            margin: 12px 0 25px;
        }
        &.banner-certificate {
            .title {
                text-transform: uppercase;
                font-size: 18px;
                line-height: 2;
                padding-bottom: 30px;
            }
            .img-holder {
                img {
                    max-width: 100%;
                }
            }
            .text-holder {
                text-align: center;
                @media (min-width: $tabletVMin) {
                    text-align: left;
                    margin: 0 0 0 45px;
                    max-width: 50%;
                }
            }
        }
        &.banner-important {
            @media (min-width: $tabletVMin) {
                margin: 80px 0 150px;
            }
            .title {
                font-size: 28px;
            }
        }
        &.banner-guarantee {
            margin: 0 0 45px;
            @media (min-width: $tabletVMin) {
                margin: 40px 0 85px;
            }
            .img-holder {
                margin: 0 0 15px;
                @media (min-width: $tabletVMin) {
                    padding: 0 45px 0 0;
                    max-width: 50%;
                    margin: 0;
                }
                img {
                    max-width: 100%;
                }
            }
            .title {
                font-size: 28px;
                line-height: 1;
            }
            .text-holder {
                @media (min-width: $tabletVMin) {
                    max-width: 50%;
                }
            }
        }
        &.banner-license {
            background-color: transparent;
            background-image: none;
            color: $color-theme-2;
            padding: 20px 0;
            margin: 0 0 25px;
            @media (min-width: $tabletVMin) {
                margin: 40px 0 85px;
            }
            .title {
                font-size: 24px;
                @media (min-width: $tabletVMin) {
                    font-size: 30px;
                }
            }
            .text-holder {
                order: 2;
                @media (min-width: $tabletVMin) {
                    order: 1
                }
            }
            .img-holder {
                margin: 0 auto 15px;
                order: 1;
                @media (min-width: $tabletVMin) {
                    margin: 0 auto 0 45px;
                    order: 2;
                }
            }
            .description {
                margin: 12px 0 0;
            }
        }
        .btn {
            @media (min-width: $tabletVMin) {
                padding: 10px 15px;
            }
            &+.btn {
                margin: 10px 0 0;
            }
        }
    }
    .btn {
        border: 0;
        padding: 12px 15px;
        border-radius: 10px;
        font-size: 16px;
        line-height: 1.4;
        background-color: $color-theme;
        color: $color-white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $font500;
        font-weight: 500;
        text-decoration: none;
        text-align: center;
        transition: all .15s;
        width: 100%;
        &:hover {
            opacity: .85;
        }
        @media (min-width: $tabletVMin) {
            font-size: 18px;
            padding: 15px 25px;
            width: auto;
        }
    }
    .quote-section {
        background-color: #F0F7FF;
        color: $color-theme-2;
        font-family: $font600;
        font-weight: 600;
        border-radius: 20px;
        text-transform: capitalize;
        padding: 22px 20px 28px;
        margin: 0 0 25px;
        text-align: center;
        text-wrap: balance;
        @media (min-width: $tabletVMin) {
            padding: 34px 30px;
            margin: 0 0 40px;
        }
        .title {
            font-size: 24px;
            @media (min-width: $tabletVMin) {
                font-size: 30px;
                line-height: 1.2;
            }
        }
    }
    .table-section {
        .title-section {
            font-size: 24px;
            text-align: center;
            margin: 0 0 25px;
        }
    }
    .table-wrap {
        display: grid;
        grid-template-columns: calc(50% - 0.5px) calc(50% - 0.5px);
        align-items: center;
        justify-items: center;
        grid-gap: 1px;
        background-color: #E6E9F5;
        font-family: $font400;
        font-size: 14px;
        overflow: hidden;
        font-weight: 500;
        color: #1C2528;
        margin: 0 0 20px;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        @media (min-width: $tabletVMin) {
            font-size: 18px;
            border: 1px solid #E6E9F5;
            grid-template-columns: calc(24% - 2px) 38% 38%;
            margin: 0 0 32px;
        }
        .col-item {
            background-color: $color-white;
            color: #464646;
            width: 100%;
            height: 100%;
            line-height: 1.4;
            padding: 8px 10px;
            @media (min-width: $tabletVMin) {
                padding: 12px 20px;
            }
            &.table-title-col {
                background-color: $color-theme-2;
                color: $color-white;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                padding: 16px 20px;
                .table-title {
                    font-size: 18px;
                    font-family: $font600;
                    font-weight: 600;
                    text-wrap: balance;
                }
            }
            &:nth-child(3n+1) {
                font-family: $font700;
                font-weight: 700;
                grid-column-start: 1;
                grid-column-end: 3;
                @media (min-width: $tabletVMin) {
                    grid-column-end: 2;
                }
            }
            &.hidden-mob {
                display: none;
                @media (min-width: $tabletVMin) {
                    display: flex;
                }
            }
        }
        .wrap-btn {
            width: 100%;
        }
    }
    .colored {
        color: $color-theme;
    }

    &.page-v2 {
        .hero {
            .title {
                @media (min-width: $tabletVMin) {
                    font-size: 44px;
                }
            }
        }
    }
}