.dyq-switcher-v3 {
  background-color: #fff;
  max-width: 1392px;
  margin: auto;
  margin-bottom: 150px;


  width: 100%;

  @media (max-width: $mobileResponsiveMax) {
    width: 100%;
    width: calc(100% - 40px);
    margin: auto;
    margin-bottom: 48px;
  }
  
  .title {
    font-size: 48px;
    line-height: 56px;
    color: #000;
    text-align: left;
    margin-bottom: 100px;
    font-family: $font600;
    font-weight: 600;
    
    @media (max-width: $mobileResponsiveMax) {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 32px;
      text-align: center;
    }
  }

  .dyq-columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    height: 606px;

    @media (max-width: $mobileResponsiveMax) {
      flex-direction: column-reverse;
      height: auto;
    }

    .col-img {
      width: 546px;
      height: 100%;
      border-radius: 32px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 16px;

        @media (max-width: $mobileResponsiveMax) {
        width: 100%;
        height: auto;
        padding: 0;
        margin-bottom: 32px;
      }

      img {
        width: 100%;
        display: block;
        border-radius: 16px;
        overflow: hidden;

        @media (max-width: $mobileResponsiveMax) {
          width: 100%;
        }
      }

    }

    .col-switch {
        width: 660px;

      @media (max-width: $mobileResponsiveMax) {
        width: 100%;
      }

      .switch-tabs {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 24px;
        margin-bottom: 44px;

        @media (max-width: $mobileResponsiveMax) {
          margin-bottom: 20px;
        }

        .tab-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          gap: 24px;

          @media (max-width: $mobileResponsiveMax) {
          
          }
          .tab {
            width: 50%;
            background-color: #F1F1F1;
            border-radius: 24px;
            height: 130px;
            overflow: hidden;
            color: #17181B;
            font-size: 20px;
            line-height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            font-family: $font500;
            font-weight: 500;

            @media (max-width: $mobileResponsiveMax) {
              height: 100px;
              border-radius: 20px;
              font-size: 14px;
              line-height: 1.5;
              color: #17181B;
              text-align: center;
            }

          }

          &.full {
            gap: 0;
            @media (max-width: $mobileResponsiveMax) {
          
            }
            .tab {
              width: 100%;
              text-align: center;

              br {
                display: block;
              }
              @media (max-width: $mobileResponsiveMax) {
          
              }

            }
          }
        }
      }

      .dyq-still {
        color: #000000;
        font-size: 24px;
        line-height: 44px;
        margin-bottom: 24px;
        font-family: $font600;
        font-weight: 600;

        @media (max-width: $mobileResponsiveMax) {
          font-size: 20px;
          line-height: 28px;
          margin-bottom: 24px;
          text-align: center;
        }
      }

      .dyq-btn-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 180px;

        @media (max-width: $mobileResponsiveMax) {
          margin: auto;
        }

        .button {
          width: 180px;

          @media (max-width: $mobileResponsiveMax) {
            display: none;
          }
        }

        .link {
          display: none;

          @media (max-width: $mobileResponsiveMax) {
            display: block;
            margin: auto;
            color: #1E3C5C;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            text-decoration: none;
            font-family: $font600;
            font-weight: 600;
            display: inline-block;
            border-bottom: 1px solid #1E3C5C;
            padding-bottom: 5px;

          }
        }
      }

    }
  }

 }
