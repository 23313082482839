.button {
  &,
  &:hover,
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    width: 100%;
    color: #fff;
    background-color: $color-theme;
    height: 60px;
    line-height: 60px;
    border-radius: 6px;
    font-family: $font600;
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    cursor: pointer;
    display: block;
    text-decoration: none;
    max-width: 330px;
    margin: auto;
    box-shadow: none;
    border: 0;

    @media (min-width: $tabletVMin) {
      font-size: 18px;
    }
  }

  &:hover {
    background-color: $color-button-hover;
  }

  &.v3{
    font-size: 16px;
    font-family: $font600;
    font-weight: 600;
    color: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;

    img {
      width: 16px;
      height: 16px;
    }

    background-color: #1e3c5c;

    &:hover {
      background-color: #267fd8;
    }

    &.red,
    &.orig {
      background-color: $color-theme;

      &:hover {
        background-color: $color-button-hover;
      }
    }
  }

  &.blue-btn {
    font-family: $font400;
    background-color: $color-theme-2;
    outline: 1px solid $color-white;
  }

  
  &.btn--status__disabled {
    pointer-events: none!important;
    opacity: 0.5!important;
  }
}

.translate-wrap {
  position: fixed;
  left: 5px;
  bottom: 35vh;
  .translate-btn {
    display: flex;
    cursor: pointer;
    transition: 0.3s ease;
    outline: none;
    border: none;
    background: #fff;
    padding: 14px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    &:hover {
      transform: scale(1.1);
    }
    img {
      width: 100%;
      max-width: 30px;
      @media (min-width: $tabletVMin) {
        max-width: 40px;
      }
    }
  }
  .translate-btn + {
    .dropdown-wrap {
      display: none;
      flex-direction: column;
      background-color: $color-important-bg;
      padding: 10px;
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      margin: 0 0 0 auto;
      position: absolute;
      left: 0;
      bottom: 65px;
      @media (min-width: $tabletVMin) {
        bottom: 75px;
      }
      &.open {
        display: flex;
      }
      a {
        &.button {
          &.translate-item {
            display: flex;
            justify-content: center;
            margin: 0;
            &+.button {
              margin: 10px 0 0;
            }
          }
        }
      }
    }
  }
}
