.contact-section {
  position: relative;
  padding-top: 50px;

  @media (min-width: $tabletVMin) {
  }

  @media (min-width: $tabletH) {
    overflow: hidden;
    padding-top: 110px;
    padding-bottom: 110px;
    padding-right: 110px;
  }

  // @media (min-width: $template1400) {
  //   padding-top: 110px;
  // }

  .map-block {
    height: 1040px;
    width: 100%;

    @media (min-width: $tabletH) {
      // height: auto;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    // @media (min-width: $template1400) {
    // height: 770px;
    // }

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .contact-form-block {
    min-width: 310px;
    padding: 21px 24px 37px 24px;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);

    border-radius: 10px;

    position: absolute;
    top: 138px;
    right: 22px;
    z-index: z('contact-form-block');
    left: 22px;

    @media (min-width: $tabletVMin) {
      width: 310px;
      left: auto;
    }

    @media (min-width: $tabletH) {
      width: 415px;
      padding: 36px 39px 34px 38px;
      border-radius: 14px;
      position: relative;
      float: right;

      top: 55px;
      right: 0;

      // margin-top: 130px;
      // margin-right: 50px;
    }

    // @media (min-width: $template1400) {
    //   width: 415px;
    //   padding: 36px 39px 34px 38px;
    //   border-radius: 14px;
    //   top: 130px;
    //   right: 50px;
    // }
    // @media (min-width: $template1400Full) {
    //   right: calc(((100vw - 1400px) / 2) + 100px);
    //   top: 190px;
    // }

    .field-block {
      .alert.alert-danger {
        top: 0;
      }
      &.field-checkbox {
        display: flex;
        & > input {
          max-height: 20px;
          max-width: 20px;
          margin: 3px 10px 0 0;
        }
      }
    }
  }
}
