.page-request {
  background-color: #eef2f7;
  h1 {
    font-size: 36px;
    font-family: $font700;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #1e3c5c;
    margin-bottom: 27px;
  }
  h2 {
    font-family: $font700;
    font-weight: 700;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #1e3c5c;
    margin-bottom: 27px;
    width: 100%;
    @media (min-width: $tabletVMin) {
      text-align: left;
    }
  }
  .request-success {
    h2 {
      text-align: center;
      @media (min-width: $tabletVMin) {
        text-align: center;
      }
    }
  }
  h3 {
    font-family: $font700;
    font-weight: 700;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #000;
    margin-bottom: 6px;
    @media (min-width: $tabletVMin) {
      font-size: 20px;
    }
  }
  .location-section {
    background-color: transparent;
  }
  .request-form {
    padding: 0;
    margin: 0;
    width: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 140px;
    padding-bottom: 80px;
    @media (min-width: $tabletVMin) {
      flex-direction: row;
      max-width: 1000px;
      margin: auto;
      padding-top: 200px;
    }
    input {
      width: 100%;
      min-width: 302px;
      height: 76px;
      padding: 26px 16px 25.2px 10px;
      padding-left: 0;
      border: 0;
      font-family: $font400;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.28;
      letter-spacing: normal;
      text-align: left;
      color: #79899f;
      background-color: transparent;
      &,
      &:hover {
        -webkit-box-shadow: 0 0 0px 1000px #eef2f7 inset !important;
        background-color: transparent !important;
      }
      input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px #eef2f7 inset !important;
      }
    }
    .field-wrap {
      position: relative;
      margin-bottom: 42px;
      border: solid 1px #d8374f;
      border-radius: 6px;
      padding-left: 10px;
      @media (min-width: $tabletVMin) {
        margin-bottom: 0;
        float: left;
        &:nth-child(1) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          width: calc(100% - 430px);
          @media (min-width: $template1400) {
            width: calc(100% - 500px);
          }
        }
        &:nth-child(2) {
          border-left: 0;
          border-right: 0;
          border-radius: 0;
        }
      }
      .calendar-ico {
        position: absolute;
        width: 20px;
        height: 20px;
        right: 15px;
        top: 26px;
        img {
          width: 100%;
        }
      }
      &.calendar {
        margin-bottom: 68px;
        @media (min-width: $tabletVMin) {
          margin-bottom: 0;
        }
        input {
          padding-right: 10px;
        }
      }
      input[type='date'] {
        -webkit-appearance: none;
        padding-right: 0;
        &::-webkit-calendar-picker-indicator {
          opacity: 0;
        }
        & {
          background-color: transparent;
          border: 0px;
          outline: none;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none !important;
          -webkit-appearance: none;
          -webkit-border-radius: 0px;
          -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
          cursor: default;
        }
        &:-webkit-autofill {
          -webkit-box-shadow: inset 0 0 0 500px #fff !important;
          -webkit-text-fill-color: #bfbfbf !important;
          color: #bfbfbf !important;
          -webkit-box-shadow: 0 0 0 1000px #f6f6f6 inset !important;
        }
      }
      .alert.alert-danger {
        position: absolute;
        bottom: -18px;
        height: 16px;
        display: block;
        top: auto;
        padding: 0;
      }
      // Labels
      &:before {
        display: block;
        position: absolute;
        top: -30px;
        left: 4px;
        font-size: 18px;
        margin-bottom: 9px;
        font-weight: 600;
        color: #d8374f;
      }
      &.email {
        &:before {
          content: 'Email';
        }
      }
      &.calendar {
        &:before {
          content: 'Date of birth';
        }
      }
    }
    .button-row {
      @media (min-width: $tabletVMin) {
        float: left;
      }
      button {
        width: 125px;
        height: 78px;
        line-height: 76px;
        background-color: #d8374f;
        font-size: 18px;
        font-family: $font500;
        font-weight: 500;
        border-radius: 0;
        @media (min-width: $tabletVMin) {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
  }
  .request-success {
    max-width: 500px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 140px;
    padding-bottom: 80px;
    @media (min-width: $tabletVMin) {
      padding-bottom: 0;
    }
    .main-img {
      width: 200px;
      margin: auto;
      margin-bottom: 20px;
      img {
        width: 100%;
      }
    }
    .keep-columns {
      display: flex;
      flex-direction: column;
      .keep-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;
        .td-1 {
          color: #8597b1;
          text-align: left;
          font-size: 14px;
        }
        .td-2 {
          color: #000;
          text-align: right;
          font-size: 14px;
        }
        &.paid {
          padding-top: 20px;
          .td-1 {
            font-family: $font700;
            font-weight: 700;
            font-size: 20px;
          }
          .td-2 {
            font-family: $font700;
            font-weight: 700;
            font-size: 20px;
          }
        }
        &.id {
          .td-1 {
            font-size: 17px;
          }
          .td-2 {
            font-size: 17px;
          }
        }
      }
    }
    .btn-row {
      display: flex;
      font-display: row;
      justify-content: center;
      padding-top: 50px;
      padding-bottom: 30px;
      .button {
        margin: 0 10px;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        text-transform: uppercase;
      }
    }
    .note {
      text-align: center;
      color: #8597b1;
      font-size: 17px;
    }
  }
  .request-payment {
    padding-top: 140px;
    padding-bottom: 80px;
    @media (min-width: $tabletVMin) {
      padding-bottom: 0;
    }
    .cost-row {
      font-size: 20px;
      font-family: $font700;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      text-align: center;
      color: #000;
      margin-bottom: 33px;
      @media (min-width: $tabletVMin) {
        text-align: right;
        margin-bottom: 6px;
        font-size: 20px;
      }
      .cost-row--ammount {
        font-size: 40px;
        text-align: center;
        @media (min-width: $tabletVMin) {
          text-align: right;
        }
      }
    }
    .payment-form {
      padding: 0;
      margin: 0;
      width: 100%;
      max-width: 900px;
      margin: auto;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      form {
        width: 100%;
        left: 0;
        padding-left: 0;
        max-width: 600px;
        margin: auto;
        @media (min-width: $tabletVMin) {
          display: none !important;
          background-color: $color-white;
          padding: 20px 25px 25px 25px;
        }
        &.form-active {
          display: block !important;
        }
        &#google-payment-form {
          @media (max-width: $mobileResponsiveMax) {
            display: block !important;
          }
          .btn-row {
            @media (min-width: $tabletVMin) {
              margin-top: 50px;
              margin-bottom: 40px;
            }
          }
          .gpay-card-info-container {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            @media (min-width: $tabletVMin) {
              margin: auto;
              max-width: 240px;
            }
          }
        }
      }

      // ! resources\views\pages\certificate\payment.blade.php
      .payments-row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 40px;
        max-width: 600px;
        margin: auto;
        order: 2;
        padding-top: 17px;
        @media (min-width: $tabletVMin) {
          background-color: $color-white;
          order: inherit;
          padding-top: 0;
        }
        .payment-tabs {
          height: 57px;
          width: 230px;
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;
          @media (min-width: $tabletVMin) {
            display: flex;
            align-items: stretch;
            height: 108px;
          }
          .payment-tab {
            padding: 20px 20px 12px 20px;
            background-color: $color-white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 50%;
            border-bottom: 1px solid #f6f6f6;
            @media (max-width: $mobileResponsiveMax) {
              display: none !important;
            }
            &.active {
              @media (min-width: $tabletVMin) {
                border-bottom: 1px solid transparent;
                border-left: 1px solid #f6f6f6;
                border-right: 1px solid #f6f6f6;
              }
            }
            img {
              height: 28px;
            }
            &.paypal-tab {
              padding-top: 14px;
              img {
                height: 30px;
              }
            }
          }
          .card-tab {
            padding: 5px 16px 0;
            display: flex !important;
            @media (max-width: $mobileResponsiveMax) {
              font-size: 14px;
              font-family: $font600;
              font-weight: 600;
              flex-direction: row;
              height: 100%;
              width: 100%;
              justify-content: flex-start;
              align-items: center;
              border-radius: 6px;
              height: 60px;
              line-height: 60px;
            }
            img {
              display: block;
            }
            span {
              @media (min-width: $tabletVMin) {
                font-weight: 600;
                font-size: 12px;
                margin-top: 6px;
              }
            }
          }
        }
      }
      .fields-row {
        width: 100%;
        @media (min-width: $tabletVMin) {
          display: flex;
          flex-direction: row;
          margin: auto;
          justify-content: space-between;
          align-items: baseline;
          gap: 10px;
          &:last-child {
            align-items: flex-start;
          }
          &.amount {
            margin-bottom: 30px;
          }
        }
        &.fields-row--btn-and-ssl {
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          @media (min-width: $tabletVMin) {
            padding-top: 30px;
            // align-items: flex-start;
          }
          & + .fields-row {
            // ! hidden field height to zero was changed
            display: none !important;
          }
        }
      }
      .field-wrap {
        margin-bottom: 27px;
        position: relative;
        width: 100%;
        max-width: 320px;
        min-width: 320px;
        @media (max-width: $mobileResponsiveMax) {
          margin: auto;
          margin-bottom: 27px;
          max-width: none;
        }
        @media (min-width: $tabletVMin) {
          max-width: 370px;
          min-width: 270px;
        }
        @media (min-width: $template1400) {
          max-width: 420px;
        }
        &.card {
          input {
            padding-right: 70px;
          }
        }
        .master-ico {
          width: 50px;
          position: absolute;
          right: 10px;
          bottom: 10px;
          img {
            width: 100%;
          }
        }
        &.last {
          margin-bottom: 50px;
        }
        label {
          font-size: 17px;
          font-family: $font500;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.82;
          letter-spacing: normal;
          text-align: left;
          color: #000;
          margin-bottom: 16px;
          display: block;
        }
        input {
          height: 60px;
          padding: 0px 20px 0px 20px;
          border-radius: 6px;
          box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.06);
          background-color: #fff;
          border: 0;
          width: 100%;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: left;
          color: #697280;
          outline: none !important;

          @media (min-width: $tabletVMin) {
          }
        }
      }
      .btn-row {
        width: 100%;
        .button {
          border-radius: 6px;
          width: 100%;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: center;
          font-size: 14px;
          font-family: $font600;
          font-weight: 600;
          max-width: 166px;
          height: 40px;
          line-height: 40px;
          @media (min-width: $tabletVMin) {
            font-size: 13px;
            font-family: $font700;
            font-weight: 700;
            max-width: 242px;
            height: 46px;
            line-height: 46px;
          }
          .hidden-desktop {
            display: flex;
            align-items: center;
            @media (min-width: $tabletVMin) {
              display: none;
            }
          }
          .ico {
            margin: 0 15px 0 20px;
          }
        }
      }
      .ssl {
        margin: auto;
        width: 245px;
        @media (min-width: $tabletVMin) {
          margin: 0;
        }
        img {
          width: 100%;
        }
        &.pp {
          text-align: center;
          display: none;
          img {
            width: auto;
            height: 52px;
          }
        }
      }
    }
  }
}

.page-request {
  #make-payment-form {
    @media (max-width: $mobileResponsiveMax) {
      order: 3;
    }
    .fields-row {
      justify-content: center;
      .field-wrap {
        max-width: 548px;
      }
      .btn-row {
        margin-top: 20px;
        margin-bottom: 23px;
      }
    }
    .sq-card-wrapper {
      .sq-card-iframe-container {
        form {
          #card-number-wrapper {
            #cardNumber {
              font-family: Arial;
              font-size: 15px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              text-align: left;
              color: #697280;
            }
            .sq-input-wrapper {
              @media (min-width: $tabletVMin) {
                height: 60px !important;
              }
            }
          }
        }
      }
    }
  }
}

.page-request .request-payment .payment-form {
  @media (max-width: $mobileResponsiveMax) {
    #sq-paypal,
    #google-payment-form,
    #apple-payment-form {
      .fields-row {
        padding-top: 0;
        position: relative;
        .btn-row {
          .button {
            display: flex;
            align-items: center;
            max-width: 100%;
            font-size: 14px;
            font-family: $font600;
            font-weight: 600;
            background-color: $color-white;
            color: $color-primary;
            height: 60px;
            line-height: 60px;
            .mobile-tab-button {
              position: absolute;
              top: 0;
              left: 30px;
              width: 100%;
              height: 100%;
              opacity: 0;
            }
          }
        }
        .ssl {
          display: none;
        }
      }
    }
  }
  @media (min-width: $tabletVMin) {
    #sq-paypal,
    #google-payment-form,
    #apple-payment-form {
      .fields-row {
        padding-top: 0;
        .btn-row {
          position: relative;
          width: 100%;
          button.mobile-tab-button {
            position: absolute;
            top: 0;
            left: 30px;
            width: 100%;
            height: 100%;
            opacity: 0;
          }
        }
      }
    }
  }
}

.page-request .request-payment .payment-form .payments-row {
  // ! All tabs are removed !
  // display: none !important;
}

.page-request h2.select-payment-title {
  // display: none !important;
  margin-bottom: 0;
  @media (min-width: $tabletVMin) {
    text-align: center;
    margin-bottom: 27px;
  }
}
