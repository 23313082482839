.how-it-works-section {
  text-align: center;
  padding: 0 0 30px;
  @media (min-width: $tabletVMin) {
    padding: 22px 0 60px;
  }
  .title {
    color: $color-theme-2;
    font-size: 32px;
    font-family: $font600;
    font-weight: 600;
    margin: 0 0 7px;
    @media (min-width: $tabletVMin) {
      margin: 0 0 4px;
      font-size: 42px;
    }
  }
  .subtitle {
    color: #6B6B6B;
    font-size: 20px;
    font-family: $fontComissioner;
    line-height: 1.4;
    max-width: 750px;
    margin: 0 auto;
  }
  .steps {
    display: flex;
    flex-wrap: wrap;
    padding: 25px 0 5px;
    @media (min-width: $tabletVMin) {
      align-items: flex-start;
      flex-wrap: nowrap;
      padding: 60px 0;
      gap: 35px;
    }
  }
  .step {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 25px;
    @media (min-width: $tabletVMin) {
      max-width: 380px;
      margin-bottom: 0;
    }
  }
  .step-title  {
    color: #1C2528;
    font-size: 20px;
    font-family: $font600;
    font-weight: 600;
    margin: 13px 0 4px;
    width: 100%;
    @media (min-width: $tabletVMin) {
      margin: 20px 0 8px;
    }
  }
  .step-content {
    color: #6B6B6B;
    font-size: 18px;
    font-family: $fontComissioner;
    line-height: 1.4;
    max-width: 350px;
    @media (min-width: $tabletVMin) {
      max-width: 100%;
    }
  }
  .step-ico {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-theme-2;
    border-radius: 100%;
    height: 60px;
    width: 60px;
    position: relative;
    @media (min-width: $tabletVMin) {
      height: 120px;
      width: 120px;
    }
    svg {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 25px;
      height: 25px;
      @media (min-width: $tabletVMin) {
        width: 52px;
        height: 52px;
      }
      &.certificate-ico {
        @media (min-width: $tabletVMin) {
          height: 48px;
        }
      }
    }
  }
  .step-img {
    width: 100%;
    max-width: 370px;
    border-radius: 24px;
    @media (min-width: $tabletVMin) {
      height: 100%;
      max-height: 230px;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .important-block {
    border-radius: 24px;
    background: #F5FAFF;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 15px 15px;
    max-width: 808px;
    margin: 0 auto;
    @media (min-width: $tabletVMin) {
      flex-wrap: nowrap;
      text-align: left;
      padding: 32px 32px;
    }
  }
  .important-ico {
    margin: 0 auto;
    @media (min-width: $tabletVMin) {
      margin: 0;
    }
    svg {
      width: 100px;
      height: 100px;
    }
  }
  .text-holder {
    padding-top: 15px;
    @media (min-width: $tabletVMin) {
      padding-top: 0;
      padding-left: 20px;
    }
    .title {
      color: #1C2528;
      font-size: 20px;
      font-family: $font600;
      font-weight: 600;
    }
    .description {
      color: #6B6B6B;
      font-size: 18px;
      font-family: $fontComissioner;
      line-height: 1.6;
    }
  }
}