.form-with-map {
  background-color: #1e3c5c;

  padding-top: 57px;
  padding-bottom: 55px;

  @media (min-width: $tabletVMin) {
    // padding-left: 24px;
    // padding-right: 24px;
  }

  @media (min-width: $template1400) {
    padding-top: 93px;
    padding-bottom: 106px;
  }

  .desktop-wide-wrap {
    @media (min-width: $template1400) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .map-block {
    display: none;
    text-align: center;

    @media (min-width: $template1400) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 47%;
      padding-top: 30px;
    }

    img {
      width: 100%;
      margin-bottom: 50px;
    }

    .text {
      text-align: center;
      font-size: 15px;
      color: #fff;

      small {
        font-size: 12px;
      }
    }
  }

  .form-block {
    width: 100%;

    @media (min-width: $template1400) {
      width: 46%;
    }

    form {
      margin: 0;
      padding: 0;

      @media (min-width: $template1400) {
        padding: 0;
      }
    }

    .title {
      color: #ff8698;
      font-size: 22px;
      margin-bottom: 15px;
      font-weight: 600;
      text-align: left;

      @media (min-width: $template1400) {
        font-size: 27px;
        margin-bottom: 26px;
      }
    }

    .cost-block {
      color: #fff;
      background-color: #17314d;
      padding: 14px 12px;
      border: 1px dashed #cdd9e8;
      margin-bottom: 32px;

      @media (min-width: $template1400) {
        padding: 24px 30px 20px 21px;
        margin-bottom: 40px;
      }

      span {
        display: block;

        font-size: 14px;
        line-height: 1.7;

        @media (min-width: $template1400) {
          display: inline-block;
          font-size: 15px;
          line-height: 1;
        }
      }

      .separator {
        display: none;

        @media (min-width: $template1400) {
          display: inline-block;
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }

    .field-block {
      margin-bottom: 24px;
      box-sizing: border-box;
      position: relative;

      width: 100%;

      @media (min-width: $template1400) {
        width: 47.5%;
        float: right;
        margin-bottom: 23px;

        &:nth-child(2n) {
          float: left;
        }
      }

      label {
        display: block;
        color: #fff;
        font-size: 14px;
        margin-bottom: 9px;

        font-weight: 600;

        @media (min-width: $template1400) {
          font-size: 14px;
          margin-bottom: 10px;
        }
      }

      .alert.alert-danger {
        position: absolute;
        bottom: -18px;
        height: 16px;
        display: block;
        top: auto;
        padding: 0;
      }

      select,
      input {
        background-color: transparent;
        height: 52px;
        border-radius: 3px;
        border: 1px solid #3b638d;
        padding-left: 21px;
        box-sizing: border-box;

        color: white;
        font-size: 14px;

        width: 100%;

        padding-right: 30px;
        white-space: nowrap;
        text-overflow: ellipsis;

        @include input-placeholder {
          color: rgba(255, 255, 255, 0.4);
        }

        @media (min-width: $template1400) {
          height: 48px;
          font-size: 14px;
        }
      }

      input[type='date'] {
        -webkit-appearance: none;
        padding-right: 0;

        &::-webkit-calendar-picker-indicator {
          opacity: 0;
        }

        & {
          background-color: transparent;
          outline: none;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none !important;
          -webkit-appearance: none;
          // -webkit-border-radius: 0px;
          -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
          cursor: default;
        }

        &:-webkit-autofill {
          -webkit-box-shadow: inset 0 0 0 500px #fff !important;
          -webkit-text-fill-color: #bfbfbf !important;
          color: #bfbfbf !important;

          -webkit-box-shadow: 0 0 0 1000px #f6f6f6 inset !important;
        }
      }

      input[type='date']::-webkit-inner-spin-button,
      input[type='date']::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }

      option {
        background: #1e3c5c;
        line-height: 2;
        height: 30px;
        padding: 5px 0;
      }
    }

    .field-block--select {
      background-image: url('/ico/dropdown.svg');
      background-repeat: no-repeat;
      background-position: right 20px bottom 20px;
      background-size: 14px;
      padding-right: 0px;

      cursor: pointer;

      @media (min-width: $template1400) {
        background-position: right 20px bottom 20px;
        background-size: 14px;
      }

      &.active {
        background-image: url('/ico/dropdown-active.svg');
        background-repeat: no-repeat;
        background-position: right 20px bottom 20px;
        background-size: 14px;
      }
    }

    .agree-row {
      margin-bottom: 18px;
      font-size: 14px;
      line-height: 1.6;
      color: #fff;
      clear: both;
      opacity: 0.76;

      padding-top: 10px;

      @media (min-width: $template1400) {
        padding-top: 10px;
        font-size: 14px;
        line-height: 2.1;
        margin-bottom: 26px;
      }
    }

    .button-row {
      .button {
        display: block;
        width: 100%;
        font-size: 13px;
        height: 52px;
        line-height: 52px;
        margin: 0;

        @media (min-width: $tabletVMin) {
          display: block;
          width: 180px;
          font-size: 13px;
          height: 52px;
          line-height: 52px;
        }

        @media (min-width: $template1400) {
          display: block;
          font-size: 14px;
          width: 260px;
          height: 46px;
          line-height: 46px;
          border-radius: 3px;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}
