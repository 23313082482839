.hero-home {
  overflow: hidden;
  position: relative;

  height: 100vh;

  @media (min-width: $tabletVMin) {
    height: auto;
  }

  .hero-text-wrapper {
    text-align: center;
    position: absolute;
    z-index: z('hero-text');
    top: 30px;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: $tabletVMax) {
      top: 0;
    }

    .hero-text {
      color: white;
      width: 100%;
      max-width: 800px;

      @media (min-width: $tabletVMin) {
        // width: 460px;
        position: relative;
        top: -30px;
      }

      @media (min-width: $template1400) {
        // width: 600px;
        position: relative;
        top: -30px;
      }

      .title {
        font-size: 34px;
        line-height: 1.4;
        margin-bottom: 14px;
        font-family: $font500;
        font-weight: 500;
        text-wrap: balance;

        @media (min-width: $tabletVMin) {
          font-size: 40px;
          margin-bottom: 10px;
          line-height: 1.4;
        }

        @media (min-width: $template1400) {
          font-size: 54px;
          margin-bottom: 0;
          line-height: 1.4;
        }
      }

      .subtitle {
        font-size: 22px;
        opacity: 0.76;
        line-height: 1.9;
        font-family: $font400;

        @media (min-width: $tabletVMin) {
          font-size: 28px;
          line-height: 1.9;
        }

        @media (min-width: $template1400) {
          font-size: 30px;
          line-height: 1.9;
        }
      }

      .hero-text---btns--mobile {
        display: none;

        @media (max-width: $mobileResponsiveMax) {
          display: flex;
          flex-direction: column;
          // padding: 10px 0 20px;
          padding: 20px 0 30px;
          gap: 20px;
          justify-content: center;
          align-items: center;

          .signup, .ifqualif  {
            display: block;
            font-size: 18px;
            text-decoration: none;
            text-transform: uppercase;
            color: white;
            white-space: nowrap;
            border-radius: 5px;
            font-family: $font500;
            font-weight: 500;
            height: 51px;
            line-height: 50px;
            width: 240px;
            background: $color-theme;
            border: 1px solid $color-theme;
          }

          .signup {

          }

          .ifqualif {
            font-family: $font400;
            background: transparent;
              border: 1px solid white;
          }
        }
      }
    }
  }

  .img--mobile--fader {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(30, 60, 92, 0.7);


    @media (min-width: $tabletVMin) {
      display: none;
    }
  }

  .img--mobile {
    display: block;

    width: 100%;
    object-fit: cover;
    height: 100%;

    z-index: 1;
    position: relative;

    @media (min-width: $tabletVMin) {
      display: none;
    }
  }

  .img--desktop {
    display: none;

    @media (min-width: $tabletVMin) {
      display: block;
      width: 100%;
    }

    img {
      width: 100%;
    }
  }

  .hero-video {
    display: none;
    width: 100%;
    position: relative;

    @media (min-width: $tabletVMin) {
      display: block;
    }

    video {
      width: 100%;
      display: none;
      @media (min-width: $tabletVMin) {
        display: block;
      }
    }

    .hero-video-fader {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: z('hero-video-fader');
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}