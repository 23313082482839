
.header-sticky {
  &.fixed-header {
    position: fixed;
    top: 0; 
    left: 0;
    width: 100%;
    background-color: rgba(255,255,255,0.7);
    box-shadow: 0 1px rgba(0,0,0,0.1);


    &.home {
      // background-color: rgba(0,0,0,0.3);
      background-color: rgba(30,60,92,0.9);
    }

    // height: 50px;
    height: 90px;
    z-index: z('header-transparent');
  
    @media (min-width: $tabletVMin) {
      height: 80px;
    }
  
    @media (min-width: $template1400) {
      // height: 110px;
      height: 70px;
    }

    .header-transparent-content {
      height: 70px;
      
      @media (min-width: $tabletVMin) {
        height: 80px;
      }

      @media (min-width: $template1400) {
        // height: 110px;
        height: 70px;
      }
      
    }

    &.header-transparent--check-result {
      height: 70px;
          
      @media (min-width: $tabletVMin) {
        height: 80px;
      }
    
      @media (min-width: $template1400) {
        // height: 110px;
        height: 70px;
      }
      .header-transparent-content .menu-mobile-opener {
        top: 20px;
      }
    }
  }
}

.header-transparent--like-home {
  &.header-transparent {
    .header-transparent-content {
      .menu-row.home {
        // a {
        //   color: #000;
          
        //   &:hover {
        //     color: #d8374f;
        //   }
        // }
      }
    }

  }
}

.header-transparent--like-home.header-transparent.header-sticky.fixed-header .header-transparent-content .menu-row.home a {
  color: #fff;
        
  &:hover {
    color: #d8374f;  
  }
}