// Common style START
.noselect {
  -webkit-user-select: none;
  /* Chrome/Safari */

  -moz-user-select: none;
  /* Firefox */

  -ms-user-select: none;
  /* IE10+ */
}
#event-days-calendar {
  font-family: $font500;
  font-weight: 500;
  margin: 0 auto;
}
#event-days-calendar .clndr {
}
#event-days-calendar .clndr .controls {
  color: white;
}
#event-days-calendar .clndr .controls .clndr-previous-button,
#event-days-calendar .clndr .controls .clndr-next-button {
  width: 15%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  /* Chrome/Safari */

  -moz-user-select: none;
  /* Firefox */

  -ms-user-select: none;
  /* IE10+ */

  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
  transition: background-color 0.5s;
}
#event-days-calendar .clndr .controls .month {
  width: 70%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}
#event-days-calendar .clndr .days-container {
  position: relative;
  display: inline-block;
}
#event-days-calendar .clndr .days-container .days {
  position: absolute;
  left: 0;
  -webkit-transition: left 0.5s;
  -moz-transition: left 0.5s;
  -ms-transition: left 0.5s;
  -o-transition: left 0.5s;
  transition: left 0.5s;
}
#event-days-calendar .clndr .days-container .days .day,
#event-days-calendar .clndr .days-container .days .empty {
  width: 70px;
  height: 70px;
  display: block;
  float: left;
  padding-top: 23px;
  font-size: 12px;
  text-align: center;
  color: #212121;

  @media (max-width: $mobileResponsiveMax) {
    width: 47px;
    height: 47px;
    padding-top: 15px;

  }
}
#event-days-calendar .clndr .days-container .days .day.event,
#event-days-calendar .clndr .days-container .days .empty.event {
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
  transition: background-color 0.5s;
  cursor: pointer;
}
#event-days-calendar .clndr .days-container .days .day.adjacent-month,
#event-days-calendar .clndr .days-container .days .empty.adjacent-month {
  color: rgba(0, 0, 0, 0.3);
}
#event-days-calendar .clndr .days-container .days .empty {
  height: 31px;
  vertical-align: bottom;
}
#event-days-calendar .clndr .days-container .days .headers {
  background-color: #97ce7f;
  padding-top: 5px;
  padding-bottom: 5px;
}
#event-days-calendar .clndr .days-container .days .headers .day-header {
  width: 70px;
  display: inline-block;
  text-align: center;
  color: white;
}
#event-days-calendar .clndr .days-container .events {
  position: absolute;
  left: 490px;
  width: 490px;
  height: 490px;

  @media (max-width: $mobileResponsiveMax) {
    left: 329px;
    width: 329px;
    height: 360px;
  }

  -webkit-transition: left 0.5s;
  -moz-transition: left 0.5s;
  -ms-transition: left 0.5s;
  -o-transition: left 0.5s;
  transition: left 0.5s;
  background-color: white;
}
#event-days-calendar .clndr .days-container .events .headers {
  position: relative;
}
#event-days-calendar .clndr .days-container .events .event-header {
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}

#event-days-calendar .clndr .days-container.show-events .days {
  left: -300px;
}
#event-days-calendar .clndr .days-container.show-events .events {
  left: 0;
}
// Common style END

#event-days-calendar .clndr {
  // Calendar START
  $calendar-width: 490px;
  $calendar-width--mobile: 329px;

  width: $calendar-width;
  margin: auto;
  display: block;

  @media (max-width: $mobileResponsiveMax) {
    width: $calendar-width--mobile;
  }

  .days-container {
    width: $calendar-width;
    height: 540px !important;

    @media (max-width: $mobileResponsiveMax) {
      width: $calendar-width--mobile;
      height: 400px !important;
    }

    // Month Row
    .controls {
      background-color: #f5faff;
      display: flex;

      .month,
      .clndr-next-button,
      .clndr-previous-button {
        background-color: #f5faff;
        height: 48px;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;

        &.inactive {
        }
      }

      .month {
        padding-top: 8px;
      }
    }

    // Says Row
    .days {
      width: $calendar-width;
      height: auto;

      @media (max-width: $mobileResponsiveMax) {
        width: $calendar-width--mobile;
      }

      .headers {
        display: flex;
        padding: 0;
        height: 68px;
        align-items: center;
        background-color: #f5faff;
        color: black;
        border-top: 10px solid white;
        border-bottom: 10px solid white;

        .day-header {
          color: black;
          font-weight: 500;
        }
      }

      .days-grid {
        border-top: 1px dashed #cdd9e8;
        width: $calendar-width;
        background: white;

        @media (max-width: $mobileResponsiveMax) {
          width: $calendar-width--mobile;
        }
      }

      // Day style
      .day {
        border-right: 1px dashed #cdd9e8;
        border-bottom: 1px dashed #cdd9e8;
        background-color: #fff;
        color: rgba(0, 0, 0, 0.2);
        font-size: 20px;
        font-weight: 500;

        &:nth-child(7n + 1) {
          border-left: 1px dashed #cdd9e8;
        }

        @media (max-width: $mobileResponsiveMax) {
          font-size: 12px;
        }

        &.event {
          color: black;

          -webkit-user-select: none;
          /* Chrome/Safari */
        
          -moz-user-select: none;
          /* Firefox */
        
          -ms-user-select: none;
          /* IE10+ */

          // ! Mobile active day  START
          &.event--current{
            @media (max-width: $tabletH) {
              background-color: $color-theme;
              color: #fff;

              .schedule {
                display: block;
              }
            }
          }
          // ! Mobile active day  END
          
          // ! Desktop hover START
          @media (min-width: $tabletHPlus) {
            &:hover{
              background-color: $color-theme;
              color: #fff;

              .schedule {
                display: block;
              }
            }
          }
          // ! Desktop hover END
        }

        &.today,
        &.selected {
          position: relative;

          &:after {
            display: block;
            content: '';
            width: 10px;
            height: 10px;
            margin-left: -5px;
            border-radius: 50%;
            position: absolute;
            left: 50%;
            bottom: 7px;
            background-color: $color-theme;

            @media (max-width: $mobileResponsiveMax) {
              width: 6px;
              height: 6px;
              margin-left: -3px;
            }
          }
        }
      }
    }
    .events {
      .headers {
        display: flex;
        padding: 0;
        height: 68px;
        align-items: center;
        background-color: #f5faff;
        color: black;
        border-top: 10px solid white;
        border-bottom: 10px solid white;
        flex-direction: row;
      }
    }
  }

  // Calendar END
}

.event-days-calendar-wrap {
  padding-right: 0px;
  padding-bottom: 10px;
}
#event-days-calendar .clndr .days-container .days .day {
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  position: relative;

  .schedule {
    background-color: #000;
    color: #fff;
    font-size: 10px;
    border-radius: 6px;
    text-align: left;
    display: none;
    white-space: nowrap;

    padding: 5px 8px;
    position: absolute;
    top: -30px;
    left: -20px;
    z-index: z('schedule');

    @media (max-width: $mobileResponsiveMax) {
      font-size: 14px;
    }

    @media (min-width: $tabletVMin) {
      position: absolute;
      padding: 5px 8px;
      background-color: #000;
      color: #fff;
      font-size: 10px;
      border-radius: 6px;
      top: -30px;
      text-align: left;
      display: none;
      white-space: nowrap;
      width: auto;
      left: -90px;
      z-index: z('schedule');
    }

    @media (min-width: $template1400) {
      font-size: 14px;
      top: -50px;
      left: -50px;
      padding: 8px 14px;
    }

    a {
      color: white;
    }

    span {
      font-size: 14px;

      @media (min-width: $template1400) {
        font-size: 20px;
      }
    }
  }

  &:nth-child(7n) {
    .schedule {
      @media (max-width: $mobileResponsiveMax) {
        left: -200px;
      }
    }
  }
  &:nth-child(7n-1) {
    .schedule {
      @media (max-width: $mobileResponsiveMax) {
        left: -150px;
      }
    }
  }
  &:nth-child(7n-2) {
    .schedule {
      @media (max-width: $mobileResponsiveMax) {
        left: -100px;
      }
    }
  }
  &:nth-child(7n-3) {
    .schedule {
      @media (max-width: $mobileResponsiveMax) {
        left: -50px;
      }
    }
  }
}


// ! Sold Out Start

#event-days-calendar .clndr .days-container .days .day {
  &:before {
    display: block;
    // content: "Sold Out";
    content: " ";
    position: absolute;
    bottom: 2px;
    left: 0;
    color: #000;
    font-size: 10px;
    width: 100%;
    text-align: center;
    font-style: italic;
    
    @media (max-width: $mobileResponsiveMax) {
      font-size: 8px;
    }
  }

  &.inactive {
    &:before {
      display: block;
      // content: "Sold Out";
      // content: "X";
    }
  }
  &.past {
    &:before {
      display: block;
      content: "X";
    }
  }
  &.today {
    &:before {
      display: none;
    }
  }
  &.event { 
    &:before {
      display: none;
    }
  }
}

// ! Sold Out Emd