.coupon-code--section {
    // width: 170px;
    width: 100%;

    position: relative;

    display: flex; flex-direction: column; align-items: center; justify-content: center;
   
    padding-top: 10px;
    padding-bottom: 10px;

    @media (min-width: $tabletVMin) {
        font-size: 20px;
    } 

    span {
        width: calc(100% - 24px); 
        margin-bottom: 10px;
        font-size: 15px;
        color: #d8374f;

        padding-top: 5px;
        margin-bottom: 0;
    }

    input {
        overflow: hidden;
        width: 100%;
        padding: 0;
        width: calc(100% - 24px);
        height: 62px;
        box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.06);
        background-color: #ffffff;
        margin-bottom: 17px;
        line-height: 62px;
        padding-left: 10px;
        padding-right: 20px;
        font-size: 15px;
        color: #697280;
        border-radius: 6px;
        border: 1px solid rgba(0, 0, 0, 0.03);

        margin-bottom: 0;
    }

    button {
        outline: none;
        box-shadow: none;
        width: 100%;
        width: calc(100% - 24px);
        color: #fff;
        background-color: #d8374f;
        height: 60px;
        line-height: 60px;
        border-radius: 6px;
        font-family: "PoppinsMedium", sans-serif;
        font-size: 15px;
        text-align: center;
        cursor: pointer;
        display: block;
        text-decoration: none;
        max-width: 330px;
        margin: auto;
        box-shadow: none;
        border: 0;

        width: 80px;
        height: 40px;
        line-height: 40px;
        position: absolute;
        top: 21px;
        right: 24px;


        @media (max-width: $mobileResponsiveMax) {
            width: 60px;
            font-size: 14px;
        } 
    }
}