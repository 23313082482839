.save-time-section {
  text-align: center;
  padding: 0 0 30px;
  @media (min-width: $tabletVMin) {
    padding: 22px 0 60px;
  }
  .title {
    color: $color-theme-2;
    font-size: 38px;
    font-family: $font600;
    font-weight: 600;
    line-height: 1.25;
    margin: 0 0 10px;
    @media (min-width: $tabletVMin) {
      margin: 0 0 6px;
      font-size: 42px;
    }
  }
  .subtitle {
    color: #6B6B6B;
    font-size: 18px;
    font-family: $fontComissioner;
    line-height: 1.4;
    margin: 0 auto;
    @media (min-width: $tabletVMin) {
      font-size: 20px;
    }
  }
  .save-time-list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 25px 0;
    row-gap: 12px;
    column-gap: 6px;
    @media (min-width: $tabletVMin) {
      padding: 42px 0 52px;
    }
    li {
      border-radius: 12px;
      background: $color-important-bg;
      color: #6B6B6B;
      display: flex;
      align-items: center;
      font-size: 18px;
      font-family: $fontComissioner;
      padding: 10px 20px;
      text-align: left;
    }
    svg {
      height: 20px;
      width: 20px;
      margin-right: 8px;
      flex: 1 0 auto;
    }
  }
  .btn-wrap {
    margin-bottom: 32px;
    @media (min-width: $tabletVMin) {
      margin-bottom: 56px;
    }
  }
  .btn {
    font-family: $font600;
    font-weight: 600;
    font-size: 16px;
    border-radius: 16px;
    background-color: $color-theme-2;
    color: $color-white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    padding: 16px 24px;
    transition: all .25s;
    margin: 8px 0;
    min-width: 335px;
    @media (min-width: $tabletVMin) {
      margin: 0 6px;
      min-width: 188px;
    }
    &:hover {
      opacity: .85;
    }
  }
  .laptop-img {
    max-width: 100%;
  }
}