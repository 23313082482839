.course-options-section {
	display: flex;
	flex-direction: column;
	padding: 0 0 30px;
	@media (min-width: $tabletVMin) {
		padding: 22px 0 60px;
	}
	.title {
		text-align: center;
		color: $color-theme-2;
		font-size: 32px;
		font-family: $font600;
		font-weight: 600;
		margin: 0 0 20px;
		order: 1;
		line-height: 1.25;
		@media (min-width: $tabletVMin) {
			font-size: 42px;
			margin: 0 0 40px;
		}
	}
	.subtitle {
		color: #6B6B6B;
		font-size: 16px;
		font-family: $fontComissioner;
		line-height: 1.4;
		max-width: 550px;
		margin: 0 auto 20px;
		text-align: center;
		order: 2;
		@media (min-width: $tabletVMin) {
			display: none;
		}
	}
	.course-options-table {
		display: grid;
		grid-template-columns: calc(50% - 0.5px) calc(50% - 0.5px);
		align-items: center;
		justify-items: center;
		grid-gap: 1px;
		background-color: #E6E9F5;
		font-family: $fontComissioner;
		font-size: 18px;
		overflow: hidden;
		font-weight: 500;
		color: #1C2528;
		margin: 0 0 20px;
		order: 4;
		@media (min-width: $tabletVMin) {
			border-radius: 4px;
			border: 1px solid #E6E9F5;
			grid-template-columns: calc(24% - 2px) 38% 38%;
			margin: 0 0 32px;
			order: 3;
		}
		.col-item {
			background-color: $color-white;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			width: 100%;
			height: 100%;
			line-height: 1.4;
			padding: 12px 20px;
			position: relative;
			@media (min-width: $tabletVMin) {
				height: 80px;
				padding: 0 30px;
			}
			&.table-title-col {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: space-between;
				text-align: left;
				padding: 12px 0;
				height: 100%;
				@media (min-width: $tabletVMin) {
					padding: 24px 30px 28px;
				}
				.table-course-subtitle {
					margin: 0 0 16px;
					text-align: left;
					font-size: 18px;
				}
			}
			&.hidden-mob {
				display: none;
				@media (min-width: $tabletVMin) {
					display: flex;
				}
				.table-course-subtitle {
					margin: 50px 0 0;
					text-wrap: balance;
				}
			}
			svg {
				max-width: 20px;
				max-height: 20px;
			}
			@media (max-width: $mobileResponsiveMax) {
				&:nth-child(3) {
					padding: 12px 0 40px;
					&::after {
						content: "";
						position: absolute;
						top: -1px;
						left: 0;
						width: 100%;
						height: 1px;
						background-color: $color-white;
					}
				}
			}
			&:nth-child(3n+1) {
				justify-content: flex-start;
			}
			.mob-item-title {
				display: flex;
				width: 100%;
				justify-content: center;
				color: #6F6C90;
				font-size: 18px;
				font-weight: 500;
				margin-bottom: 8px;
				@media (min-width: $tabletVMin) {
					display: none;
				}
			}
			&.cost-wrap {
				@media (min-width: $tabletVMin) {
					line-height: 1;
					padding: 20px 20px;
				}
			}
			&:nth-last-child(-n+3) {
				@media (min-width: $tabletVMin) {
					min-height: 80px;
					height: 100%;
				}
			}
			@media (max-width: $mobileResponsiveMax) {
				&:nth-child(-n+3),
				&:nth-child(3n+1) {
					grid-column-start: 1;
					grid-column-end: 3;
				}
				&:nth-child(3n+1) {
					border-left: 1px solid #E6E9F5;
					border-right: 1px solid #E6E9F5;
				}
				&:nth-child(3n + 5) {
					border-left: 1px solid #E6E9F5;
				}
				&:nth-child(3n + 6) {
					border-right: 1px solid #E6E9F5;
				}
				&:nth-last-child(-n+2) {
					border-left: 1px solid #E6E9F5;
					border-right: 1px solid #E6E9F5;
					grid-column-start: 1;
					grid-column-end: 3;
					.mob-item-title,
					.table-course-subtitle {
						text-align: left;
						justify-content: flex-start;
						width: 100%;
					}
				}
				&:last-child {
					border-bottom: 1px solid #E6E9F5;
				}
			}
			.table-course-subtitle {
				font-size: 16px;
			}
		}
		.table-course-title {
			color: #1C2528;
			font-family: $font600;
			font-weight: 600;
			font-size: 24px;
			line-height: 1.4;
			text-wrap: balance;
			margin: 0 0 16px;
		}
		.above-course-title {
			color: #6F6C90;
			font-size: 18px;
			margin: 0 0 10px;
			min-height: 22px;
			font-family: $fontComissioner;
			font-weight: 600;
		}
		.table-course-subtitle {
			color: #6F6C90;
			font-size: 14px;
			line-height: 1.7;
			font-family: $fontComissioner;
			text-align: center;
			@media (min-width: $tabletVMin) {
				font-size: 16px;
				line-height: 1.5;
			}
		}
		.wrap-btn {
			width: 100%;
		}
		.btn {
			font-family: $font600;
			font-weight: 600;
			font-size: 16px;
			border-radius: 16px;
			background-color: $color-theme-2;
			color: $color-white;
			display: flex;
			align-items: center;
			justify-content: center;
			text-decoration: none;
			padding: 16px 48px;
			transition: all .25s;
			&:hover {
				opacity: .85;
			}
		}
		.cost {
			color: #1C2528;
			font-family: $font600;
			font-weight: 600;
			font-size: 35px;
			@media (min-width: $tabletVMin) {
				font-size: 40px;
			}
		}
	}
	.course-options-list {
		color: #6B6B6B;
		font-size: 18px;
		font-family: $fontComissioner;
		line-height: 1.5;
		list-style-position: inside;
		text-align: center;
		order: 3;
		margin: 0 0 14px;
		text-wrap: balance;
		@media (min-width: $tabletVMin) {
			color: #1C2528;
			text-align: left;
			margin: 0;
			order: 4;
		}
		li {
			margin: 0 0 4px;
			@media (min-width: $tabletVMin) {
				margin: 0 0 8px;
			}
		}
	}
	.bold {
		@media (min-width: $tabletVMin) {
			font-weight: 600;
		}
	}
	.hidden-desktop {
		display: inline-block;
		@media (min-width: $tabletVMin) {
			display: none;
		}
	}
}

