.share-section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 0 50px;
    .title {
        color: #2F5277;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: 2px;
        text-transform: uppercase;
        text-align: center;
        width: 100%;
        margin: 0 0 26px;
    }
    .share-row {
        display: flex;
        align-items: center;
    }
    ul,
    ol {
        list-style: none;
    }
    .item,
    .sharing-providers li {
        display: inline-block;
        margin: 0 9px;
        img {
            max-width: 48px;
            @media (min-width: $tabletVMin) {
                max-width: 60px;
            }
        }
    }
    .item {
        cursor: pointer;
        transition: 0.2s all;
        &:hover {
            img {
                opacity: 0.85;
            }
        }
    }
    .sharing-providers {
        li {
            a {
                display: block;
                text-decoration: none;
                transition: 0.2s all;
                &:hover {
                    opacity: 0.85;
                }
            }
        }
    }
}
