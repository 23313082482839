.philosophy {
  padding-top: 51px;
  padding-bottom: 50px;
  overflow: hidden;

  @media (min-width: $template1400) {
    padding-top: 58px;
    padding-bottom: 52px;
  }

  .video-philosophy {
    // border-radius: 24px;
    margin-bottom: 54px;
    
    @media (min-width: $template1400) {
      margin-bottom: 0;
    }
  }

  .text-block {
    text-align: left;

    @media (min-width: $template1400) {
      width: calc(100% - 500px);
      padding-top: 38px;
      padding-left: 77px;
      float: right;
    }

    @media (min-width: $template1400Full) {
      width: calc(100% - 630px);
      padding-top: 98px;
    }

    .title {
      line-height: 1;
      font-family: $font700;
      font-weight: 700;
      margin-bottom: 10px;
      font-size: 23px;

      @media (min-width: $template1400) {
        // font-size: 24px;
        font-size: 32px;
        margin-bottom: 21px;
      }
    }

    .description {
      line-height: 2;
      color: $color-theme-2;
      // font-size: 14px;
      font-size: 17px;

      @media (min-width: $template1400) {
        font-size: 17px;
        line-height: 2.3;
      }
    }
  }
}