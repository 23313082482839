.input-block.card {
  padding-left: 64px;
  position: relative;
  height: 62px;
  // ! All dropdowns removed
  // background-image: url('/ico/dropdown.svg');
  background-repeat: no-repeat;
  background-position: right 20px top 50%;
  background-size: 14px;
  padding-right: 0px;

  // &.active {
    // ! All dropdowns removed
    // background-image: url('/ico/dropdown-active.svg');
  // }

  // &#sq-google-pay,
  // &#sq-apple-pay {
    // background-image: none;
  // }

  .ico {
    position: absolute;
    display: flex;
    width: 64px;
    height: 62px;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    z-index: 1;

    img {
      display: flex;
      height: 24px;
    }
  }

  &#credit-card-parent {
    padding-left: 168px;
    .ico {
      width: 160px;
    }
  }

  .card-title {
    color: #000;
    font-family: $font700;
    font-weight: 700;
    font-size: 14px;
    padding-left: 0;

    @media (min-width: $tabletVMin) {
      font-size: 17px;
    }
  }

  .payment-type-block {
    display: block;
    width: 100%;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    // Replaced due to one tab only
    // cursor: pointer;
    cursor: default;

    -webkit-tap-highlight-color: transparent;
  }
}
