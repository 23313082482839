.faq-section {
  padding-top: 38px;

  .desktop-wide-wrap {
    @media (max-width: $mobileResponsiveMax) {
      padding-left: 14px;
      padding-right: 14px;
    }
  }

  .faq-section-title {
    color: $color-theme-2;
    font-size: 38px;
    font-family: $font600;
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 22px;

    @media (min-width: $tabletVMin) {
      font-size: 42px;
      margin-bottom: 37px;
    }
  }
  .faq-section-subtitle {
    margin-bottom: 25px;
    line-height: 1.5;
    font-family: $fontComissioner;
    font-weight: 600;
    text-align: left;
    padding-left: 10px;
    font-size: 18px;
    margin-bottom: 22px;
    color: #6B6B6B;

    @media (min-width: $tabletVMin) {
      font-size: 20px;
      margin-bottom: 37px;
    }
  }
  .faq-list + .faq-section-subtitle {
    padding-top: 50px;
  }

  .faq-section-link {
    line-height: 1.85;
    text-align: center;
    padding-top: 15px;
    font-size: 22px;
    @media (min-width: $tabletVMin) {
      font-size: 16px;
    }

    a {
      color: $color-theme;
    }
  }
  .faq {
    box-shadow: 0 0 12px 0 rgba(228, 229, 230, 0.62);
    border: solid 1px rgba(205, 217, 232, 0.15);
    background-color: #ffffff;
    padding: 17px 30px;
    color: $color-important-description;
    margin: 10px;

    @media (min-width: $tabletVMin) {
      padding: 17px 30px;
      padding-left: 40px;
    }

    .title {
      font-weight: 600;
      line-height: 1.4;
      text-align: left;
      position: relative;
      font-size: 17px;
      margin-bottom: 8px;
      cursor: pointer;

      @media (min-width: $tabletVMin) {
        font-size: 17px;
        margin-bottom: 12px;
      }

      &:before {
        content: '';
        // width: 6px;
        // height: 6px;
        // top: 6px;
        // left: -12px;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 4px;
        left: -20px;
        display: block;
        background-image: url('/ico/faq-1.svg');
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
        transition: .3s ease;

        @media (min-width: $tabletVMin) {
        }
        @media (min-width: $template1400) {
          // width: 6px;
          // height: 6px;
          // left: -21px;
          // top: 8px;
          left: -25px;
        }

        // @media (max-width: $mobileResponsiveMax) {
        //   top: 9px;
        // }
      }

      &.open {
        &:before {
          transform: rotate(45deg);
        }
      }

    }
    .description {
      display: none;
      line-height: 1.54;
      position: relative;
      font-size: 17px;

      @media (min-width: $tabletVMin) {
        font-size: 17px;
        line-height: 1.84;
      }
      @media (min-width: $template1400) {
        // font-size: 16px;
        // line-height: 1.84;
      }

      &:before {
        content: '';
        width: 7px;
        height: 7px;
        position: absolute;
        top: 6px;
        left: -11px;
        display: block;
        background-image: url('/ico/faq-2.svg');
        background-repeat: no-repeat;
        background-size: contain;

        @media (min-width: $template1400) {
          width: 8px;
          height: 8px;
          top: 10px;
          left: -20px;
        }

        @media (max-width: $mobileResponsiveMax) {
          top: 9px;
        }
      }
    }
  }
}
