.page-lane-reservation {
  .lane-reservation-section {
    position: relative;

    @media (min-width: $tabletVMin) {
    }

    @media (min-width: $template1400) {
      padding-top: 123px;
    }
    
  }
}
