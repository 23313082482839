.page-first {
  padding: 0;
  max-width: none;

  padding-top: 140px;

  .section {
    .container {
      padding-left: 34px;
      padding-right: 27px;
      width: 100%;

      @media (min-width: $tabletVMin) {
        padding-left: 34px;
        padding-right: 27px;
      }

      @media (min-width: $template1400) {
        // max-width: 1549px;
        // margin: auto;
      }
    }

    h2 {
      color: #000000;
      font-size: 30px;
      line-height: 1.5;
      margin-bottom: 26px;
      font-family: $font700;
      font-weight: 700;

      .colored {
        color: $color-theme;
      }
    }

    h3 {
      color: $color-theme-2;
      font-size: 30px;
      line-height: 1.6;
      margin-bottom: 23px;
      font-family: $font700;
      font-weight: 700;

      @media (min-width: $tabletVMin) {
      }

      @media (min-width: $template1400) {
      }
    }

    p {
      font-size: 24px;
      line-height: 1.4;
      color: #676767;

      @media (min-width: $tabletVMin) {
        font-size: 20px;
        color: #7a8491;
      }

      @media (min-width: $template1400) {
      }
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    li {
      font-size: 24px;
      line-height: 1.4;
      color: #676767;
      margin-bottom: 14px;

      @media (min-width: $tabletVMin) {
        color: #7a8491;
      }

      @media (min-width: $template1400) {
        // font-size: 34px;
        // color: #7a8491;
      }
    }

    &.how {
      padding-bottom: 5px;

      @media (min-width: $tabletVMin) {
      }

      @media (min-width: $template1400) {
        // padding-bottom: 86px;
      }

      h2 {
        font-size: 34px;

        @media (min-width: $tabletVMin) {
        }

        @media (min-width: $template1400) {
          // font-size: 65px;
          // font-weight: 600;
          // font-stretch: normal;
          // font-style: normal;
          // line-height: 1.46;
          // letter-spacing: -1.3px;
          // margin-bottom: 54px;
        }
      }

      p {
        margin-bottom: 34px;

        @media (min-width: $tabletVMin) {
        }

        @media (min-width: $template1400) {
          // margin-bottom: 52px;
        }
      }
    }

    &.quote {
      background-color: $color-theme-2;

      p {
        font-family: $font700;
        font-weight: 700;
        font-size: 24px;
        line-height: 1.5;
        padding: 32px 42px;
        color: #fff;
        position: relative;
        z-index: 3;

        .colored {
          color: $color-theme;
        }

        @media (min-width: $tabletVMin) {
        }

        @media (min-width: $template1400) {
          // padding-top: 140px;
          // padding-bottom: 162px;
          // font-size: 55px;
        }
      }

      .container {
        position: relative;
        padding: 0;

        &:before {
          position: absolute;
          top: 24px;
          left: 11px;

          z-index: 1;
          content: '';
          display: block;
          width: 46px;
          height: 40px;
          background-image: url('/ico/first-page/quote-start.svg');
          background-repeat: no-repeat;
          background-size: contain;
          background-position: 50% 50%;

          @media (min-width: $tabletVMin) {
          }

          @media (min-width: $template1400) {
            // width: 130px;
            // height: 112px;
            // top: 80px;
          }
        }

        &:after {
          position: absolute;
          right: 22px;
          bottom: 31px;
          z-index: 1;
          content: '';
          display: block;
          width: 46px;
          height: 40px;
          background-image: url('/ico/first-page/quote-end.svg');
          background-repeat: no-repeat;
          background-size: contain;
          background-position: 50% 50%;

          @media (min-width: $tabletVMin) {
          }

          @media (min-width: $template1400) {
            // width: 130px;
            // height: 112px;
            // bottom: 120px;
          }
        }
      }
    }

    &.step-1 {
      padding-top: 29px;
      padding-bottom: 27px;

      @media (min-width: $tabletVMin) {
        .mobile-only {
          display: none;
        }
      }

      @media (min-width: $template1400) {
        // padding-top: 145px;
        // padding-bottom: 89px;

        // .mobile-only {
        //   display: none;
        // }
      }

      h2 {
        @media (min-width: $tabletVMin) {
          font-size: 35px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.42;
          letter-spacing: -1.1px;
          text-align: left;
          color: $color-theme-2;

          .colored {
            color: $color-theme;
          }
        }

        @media (min-width: $template1400) {
          // font-size: 55px;
          // font-weight: 600;
          // font-stretch: normal;
          // font-style: normal;
          // line-height: 1.42;
          // letter-spacing: -1.1px;
          // text-align: left;
          // color: $color-theme-2;

          // .colored {
          //   color: $color-theme;
          // }
        }
      }
    }

    &.step-2 {
      padding-top: 26px;
      padding-bottom: 27px;

      @media (min-width: $tabletVMin) {
        .mobile-only {
          display: none;
        }
      }

      @media (min-width: $template1400) {
        // padding-top: 84px;
        // padding-bottom: 82px;

        // .mobile-only {
        //   display: none;
        // }
      }

      p {
        margin-bottom: 11px;

        @media (min-width: $tabletVMin) {
        }

        @media (min-width: $template1400) {
          // margin-bottom: 17px;
        }
      }

      h2 {
        @media (min-width: $tabletVMin) {
          font-size: 35px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.42;
          letter-spacing: -1.1px;
          text-align: left;
          color: $color-theme-2;

          .colored {
            color: $color-theme;
          }
        }

        @media (min-width: $template1400) {
          // font-size: 55px;
          // font-weight: 600;
          // font-stretch: normal;
          // font-style: normal;
          // line-height: 1.42;
          // letter-spacing: -1.1px;
          // text-align: left;
          // color: $color-theme-2;

          // .colored {
          //   color: $color-theme;
          // }
        }
      }
    }

    &.ready {
      padding-bottom: 53px;

      @media (min-width: $tabletVMin) {
      }

      @media (min-width: $template1400) {
        // padding-bottom: 148px;
      }

      h4 {
        text-align: center;
        color: $color-theme-2;
        font-size: 20px;
        font-family: $font700;
        font-weight: 700;
        margin-bottom: 20px;

        @media (min-width: $tabletVMin) {
        }

        @media (min-width: $template1400) {
        }
      }

      .button {
        width: 180px;
        // font-size: 13px;
        font-size: 14px;

        @media (min-width: $tabletVMin) {
          display: block;
          margin-top: 20px;
          width: 180px;
          // font-size: 13px;
          font-size: 15px;
          height: 50px;
          line-height: 50px;
        }

        @media (min-width: $template1400) {
          // font-size: 24px;
          // width: 327px;
          // height: 88px;
          // line-height: 88px;
          // border-radius: 15px;
          // padding-left: 29px;
          // padding-right: 29px;
        }
      }
    }

    &.map-bg {
      margin-bottom: 25px;

      .button {
        width: 180px;
        // font-size: 13px;
        font-size: 14px;

        @media (min-width: $tabletVMin) {
          display: block;
          margin-top: 20px;
          width: 180px;
          font-size: 13px;
          height: 50px;
          line-height: 50px;
        }

        @media (min-width: $template1400) {
          // font-size: 24px;
          // width: 327px;
          // height: 88px;
          // line-height: 88px;
          // border-radius: 15px;
          // padding-left: 29px;
          // padding-right: 29px;
        }
      }
    }

    &.image {
      margin-bottom: 25px;

      @media (min-width: $tabletVMin) {
      }

      @media (min-width: $template1400) {
        // margin-bottom: 109px;
      }

      .container {
        padding: 0;
      }

      .img-block {
        text-align: center;

        img {
          width: 100%;

          @media (min-width: $tabletVMin) {
          }

          @media (min-width: $template1400) {
            // max-width: 1314px;
          }
        }
      }
    }

    &.list {
      padding-bottom: 33px;

      @media (min-width: $tabletVMin) {
      }

      @media (min-width: $template1400) {
        // padding-bottom: 76px;
      }

      p {
        margin-bottom: 43px;

        @media (min-width: $tabletVMin) {
        }

        @media (min-width: $template1400) {
          // padding-bottom: 41px;
        }
      }

      ul {
        overflow: hidden;

        li {
          width: 100%;
          position: relative;
          padding-left: 25px;

          &:before {
            position: absolute;
            top: 10px;
            left: 0px;
            z-index: 1;
            content: '';
            display: block;
            width: 13px;
            height: 13px;
            background-image: url('/ico/first-page/li.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: 50% 50%;

            @media (min-width: $tabletVMin) {
            }

            @media (min-width: $template1400) {
              // width: 33px;
              // height: 33px;
              // top: 28px;
              // left: 0px;
            }
          }

          @media (min-width: $tabletVMin) {
            float: left;
            width: 33%;
            height: 92px;
            margin-bottom: 53px;
            padding-left: 60px;
            padding-right: 90px;
            display: flex;
            align-items: center;

            &:last-of-type {
              padding-right: 0;
            }
          }

          @media (min-width: $template1400) {
            // float: left;
            // width: 33%;
            // height: 92px;
            // margin-bottom: 53px;
            // padding-left: 60px;
            // padding-right: 90px;
            // display: flex;
            // align-items: center;

            // &:last-of-type {
            //   padding-right: 0;
            // }
          }
        }
      }
    }

    &.important {
      background-color: #f5faff;
      border: none;
      padding-top: 36px;
      padding-bottom: 42px;

      @media (min-width: $tabletVMin) {
        .container {
          padding-right: 0;
        }
      }

      @media (min-width: $template1400) {
        // padding-top: 146px;
        // padding-bottom: 146px;

        .container {
          padding-right: 0;
        }
      }

      .img-block {
        width: 100%;
        margin: auto;
        margin-bottom: 30px;

        img {
          width: 100%;
        }

        @media (min-width: $tabletVMin) {
          float: left;
          margin-bottom: 0;
          width: 300px;
        }

        @media (min-width: $template1400) {
          // float: left;
          // width: 494px;
          // margin-bottom: 0;
        }
      }

      .text-block {
        @media (min-width: $tabletVMin) {
          padding-left: 350px;
        }

        @media (min-width: $template1400) {
          // padding-left: 570px;
        }

        h2 {
          margin-bottom: 18px;
          font-size: 24px;
          color: $color-theme-2;
          padding-right: 60px;

          .colored {
            color: $color-theme;
          }

          @media (min-width: $tabletVMin) {
            margin-bottom: 33px;
            padding-right: 50px;

            font-size: 35px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.36;
            letter-spacing: -1.1px;

            position: relative;
          }

          @media (min-width: $template1400) {
            // font-size: 55px;
            // margin-bottom: 33px;
            // padding-right: 50px;

            // font-size: 55px;
            // font-weight: 600;
            // font-stretch: normal;
            // font-style: normal;
            // line-height: 1.36;
            // letter-spacing: -1.1px;

            // position: relative;
          }
        }

        p {
          margin-bottom: 12px;

          @media (min-width: $tabletVMin) {
          }

          @media (min-width: $template1400) {
            // margin-bottom: 27px;
            // font-size: 34px;
          }
        }
      }
    }

    &.google-map {
      height: 296px;

      @media (min-width: $tabletVMin) {
      }

      @media (min-width: $template1400) {
        height: 886px;
      }

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  .top-social {
    padding-top: 42px;
    padding-bottom: 26px;
    overflow: hidden;
    display: flex;
    align-items: center;

    a {
      float: left;
      margin-right: 17px;
    }

    .ico-sh--top {
      position: relative;
      top: 5px;

      @media (min-width: $tabletVMin) {
        top: 10px;
      }

      @media (min-width: $template1400) {
        // top: 10px;
      }
    }
  }
}

// ! Quiz page FOOTER global issue
.page-first--quiz {
  .footer--global {
    display: none !important;
  }
  .footer--global + .location-section {
    display: none !important;
  }
  .footer--global + .location-section + .footer-39 {
    display: none !important;
  }
  .footer-first-page .col-3 .contact-block {
    // padding-left: 30px;
    padding-left: 40px;
  }
}
