.page-schedule {
    &~.sms-mobile-opener {
        display: none;
    }
    .schedule-wrap-inner {
        flex: 1;
        padding-top: 110px;
    }
}
.hide-nav {
    .header-transparent {
        .header-transparent-content {
            .menu-row,
            .menu-mobile-opener {
                display: none;
            }
        }
    }
}
.schedule-wrap {
    height: 100%;
    display: flex;
    align-items: center;
    .container {
        max-width: 600px;
        margin: 0 auto;
    }
    .title {
        text-align: center;
        color: $color-primary;
        font-size: 32px;
        line-height: 1.3;
        margin-bottom: 15px;
        font-family: $font600;
        font-weight: 600;
    }
    .subtitle {
        text-align: center;
        color: $color-primary;
        opacity: .8;
        font-size: 16px;
        line-height: 1.3;
        margin-bottom: 36px;
        font-family: $fontComissioner;
    }
    #class-schedule-form {
        padding: 0;
        width: 100%;
        left: auto;
        right: auto;
        .input-title {
            text-align: left;
        }
        .input-block {
            width: 100%;
        }
        .button {
            max-width: 100%;
            @media (min-width: $tabletVMin) {
                max-width: 330px;
            }
        }
    }
    &.class-top-section {
        background-image: none;
        padding: 0;
        min-height: calc(100vh - 46px);
        @media (min-width: $tabletVMin) {
            min-height: calc(100vh - 81px);
        }
        .desktop-wide-wrap {
            padding-left: 24px;
            padding-right: 24px;
        }    
    }
    @media (min-width: $tabletVMin) {
        .title {
            font-size: 40px;
            line-height: 1.5;
        }
        .subtitle {
            font-size: 18px;
            margin-bottom: 58px;
        }
    }
}