.location-section {
  .desktop-wide-wrap {

    // padding-top: 120px;
    @media (max-width: $mobileResponsiveMax) {
      padding: 0;
    }

    @media (min-width: $template1400) {
      // padding-top: 168px;
    }
  }
}

.location-row {
  background-color: $color-footer;
  padding-top: 19px;
  padding-bottom: 19px;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;

  min-height: 120px;

  @media (min-width: $tabletVMin) {
    height: 150px;
    border-radius: 13px;
  }

  @media (min-width: $template1400) {
    // height: 140px;
    height: 170px;
    padding-top: 31px;
  }

  p {
    text-align: center;
    color: #9cabbf;
    line-height: 1.4;
    // font-size: 13px;
    // font-size: 12px;
    font-size: 15px;

    @media (min-width: $tabletVMin) {
      line-height: 1.5;
      // font-size: 11px;
      // font-size: 14px;
      font-size: 17px;
    }

    &.title {
      color: #ff8698;
      margin-bottom: -6px;
      font-weight: bold;
      line-height: 1.7;

      // font-size: 12px;
      font-size: 15px;

      @media (min-width: $tabletVMin) {
        // font-size: 11px;
        // font-size: 14px;
        font-size: 17px;
        margin-bottom: 8px;
      }
    }

    &.location {

      &,
      & a {

        text-decoration: none;
        color: #fff;
        line-height: 2.15;
        margin-bottom: 0px;
        // font-size: 13px;
        // font-size: 12px;
        font-size: 15px;

        @media (min-width: $tabletVMin) {
          // font-size: 12px;
          // font-size: 14px;
          font-size: 17px;
          margin-bottom: 2px;
          line-height: 2;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &.bottom {
      color: #9cabbf;

      @media (min-width: $tabletVMin) {}

      span {
        display: none;

        @media (min-width: $template1400) {
          width: 40px;
          display: inline-block;
        }
      }

      br {
        @media (min-width: $template1400) {
          display: none;
        }
      }
    }
  }
}