.hiw-switcher-v3 {
  background-color: #E4E7EA;
  padding: 64px 24px;
  border-radius: 48px 48px 0 0;
  max-width: 1392px;
  margin: auto;
  width: 100%;
  padding-top: 133px;
  background-image: url('/assets-v3/bullet-2.png');
  background-repeat: no-repeat;
  background-size: 64px;
  background-position: 226px 114px;
  position: relative;

  @media (max-width: $mobileResponsiveMax) {
    width: calc(100% - 40px);
    margin: auto;
    padding: 32px 16px;
    border-radius: 24px 24px 0 0;
    padding-top: 22px;
    background-size: 43px;
    background-position: top 22px right 16px;
  }
  
  .title {
    font-size: 48px;
    line-height: 56px;
    color: #17181B;
    text-align: left;
    margin-bottom: 64px;
    font-family: $font600;
    font-weight: 600;
    
    @media (max-width: $mobileResponsiveMax) {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 27px;

      br {
        display: none;
      }
    }
  }

  .hiw-columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    height: 824px;

    @media (max-width: $mobileResponsiveMax) {
      flex-direction: column;
      height: auto;
    }

    .col-img {
      width: 654px;
      height: 100%;
      background-color: #F9F9F9;
      border-radius: 32px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 16px;

      @media (max-width: $mobileResponsiveMax) {
        display: none!important;
      }

      img {
        width: 100%;
        display: block;
        border-radius: 16px;
        overflow: hidden;
      }

     .tab-1 {
      display: none;
     }
     .tab-2 {
      display: none;
     }
     .tab-3 {
      display: none;
     }
     .active {
      display: flex;
     }
    }

    .col-switch {
      width: 438px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      @media (max-width: $mobileResponsiveMax) {
        width: 100%;
      }

      .switch-tabs {
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        @media (max-width: $mobileResponsiveMax) {
          margin-bottom: 20px;
        }

        .switch-tab {
          width: 100%;
          border-top: 1px dashed #CCD9E8;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          position: relative;
          padding-top: 40px;
          padding-bottom: 40px;

          @media (max-width: $mobileResponsiveMax) {
            padding-top: 24px;
          }

          .step-title {
            text-align: left;
            margin-bottom: 24px;
            font-size: 24px;
            line-height: 32px;
            color: #94959F;
            font-family: $font600;
            font-weight: 600;

            @media (max-width: $mobileResponsiveMax) {
              font-size: 16px;
              line-height: 24px;
              color :#1E3C5C;
            }
          }
          .step-content {
            text-align: left;
            color :#94959F;
            font-size: 24px;
            line-height: 32px;
            font-family: $font600;
            font-weight: 600;

            @media (max-width: $mobileResponsiveMax) {
              font-size: 16px;
              line-height: 24px;
              color :#17181B;
            }
          }
          .step-arrow {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            flex-shrink: 0;
            background-image: url('/assets-v3/arrow-hiw.svg');
            background-repeat: no-repeat;
            background-position: 0 0;
            background-size: contain;
            position: absolute;
            top: 40px;
            right: 0;
            display: none;

            @media (max-width: $mobileResponsiveMax) {
              display: none!important;
            }
          }
         
          &:hover{
            .step-title {
              color: #1E3C5C;
            }
            .step-content {
              color: #17181B;
            }
            .step-arrow {
              display: none;
            }
          }
          &.active {
            .step-title {
              color: #1E3C5C;
            }
            .step-content {
              color: #17181B;
            }
            .step-arrow {
              display: block;
            }
          }

          @media (max-width: $mobileResponsiveMax) {

          }
          
        }
      }
    }
  }
}
