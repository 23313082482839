.form-block {
  .title {
    color: #000;
    font-size: 17px;
    margin-bottom: 36px;
    font-weight: 600;
    text-align: center;

    @media (min-width: $template1400) {
      font-size: 19px;
      margin-bottom: 43px;
    }
  }
  form {
    margin: 0;
    padding: 0;
    width: 100%;
    left: 0;
  }

  .field-block {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 26px;

    @media (min-width: $template1400) {
      margin-bottom: 20px;
    }

    .alert.alert-danger {
      font-size: 12px;
      color: #d8374f;
      padding-top: 5px;
    }

    label {
      display: block;
      color: #000;
      // font-size: 13px;
      font-size: 14px;
      margin-bottom: 6px;

      @media (min-width: $template1400) {
        margin-bottom: 8px;
      }
    }

    textarea,
    input {
      background-color: transparent;
      height: 33px;
      border-radius: 6px;
      box-sizing: border-box;
      border: 1px solid transparent;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      padding-left: 11px;
      // font-size: 10px;
      // font-size: 12px;
      font-size: 14px;
      width: 100%;

      @include input-placeholder {
        color: #697280;
      }

      @media (min-width: $template1400) {
        height: 40px;
        padding-left: 14px;
        font-size: 14px;
      }
    }

    textarea {
      height: 89px;
      padding-top: 8px;

      @media (min-width: $template1400) {
        height: 90px;
        padding-top: 12px;
      }
      @media (min-width: $template1400Full) {
        height: 120px;
        padding-top: 12px;
      }
    }
  }
  .form-message {
    margin-bottom: 20px;
    color: green;
  }

  .g-recaptcha {
    margin: auto;
    margin-bottom: 20px;

    div {
      margin: auto;
    }
  }
  .button-row {
    padding-top: 15px;
    position: relative;
    clear: both;

    @media (min-width: $template1400) {
      padding-top: 8px;
    }

    input,
    label {
      position: relative;
      z-index: 5;
    }

    .button {
      position: relative;
      z-index: 2;

      display: block;
      width: 100%;
      font-size: 13px;
      width: 166px;
      height: 40px;
      line-height: 40px;
      margin: auto;

      border: none;
      margin-top: 20px;

      @media (min-width: $tabletVMin) {
        display: block;
        width: 166px;
        height: 40px;
        line-height: 40px;
        font-size: 13px;
      }
      @media (min-width: $template1400) {
        display: block;
        font-size: 13px;
        width: 242px;
        // height: 51px;
        // line-height: 51px;
        height: 46px;
        line-height: 46px;
      }
    }

    // .button-row-checkbox--attention---section {
    //   .button-row-checkbox--attention--blocker {
    //     position: absolute;
    //     display: block;
    //     z-index: 3;
    //     width: 100%;
    //     height: 100%;
    //     background-color: rgba(0, 0, 0, 0);
    //     top: 0;
    //     left: 0;

    //     @media (max-width: $mobileResponsiveMax) {
    //       top: -80px;
    //       z-index: 4;
    //     }
    //   }

    //   input:checked + .button-row-checkbox--attention--blocker {
    //     display: none;
    //   }

    //   .button-row-checkbox--attention {
    //     width: 90%;
    //     display: inline-block;
    //     vertical-align: top;
    //     margin-left: 10px;
    //     line-height: 1.2;
    //     font-size: 15px;
    //     color: #9a9a9a;

    //     @media (max-width: $mobileResponsiveMax) {
    //       // width: 80%;
    //     }

    //     a {
    //       color: inherit;
    //     }
    //     span {
    //       text-decoration: underline;
    //       color: inherit;
    //     }
    //   }

    //   .button-row-checkbox--attention--message {
    //     display: none;

    //     width: 90%;
    //     vertical-align: top;
    //     margin-left: 30px;
    //     line-height: 2.2;
    //     font-size: 13px;
    //     color: red;
    //   }

    //   input:checked + .button-row-checkbox--attention--blocker {
    //     & + label + .button-row-checkbox--attention--message {
    //       display: none !important;
    //     }
    //   }
    // }
  }
}

// .tac-popup {
//   display: none;
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   z-index: 10000;
//   background-color: rgba(0,0,0,0.5);
//   // background-color: rgba(255, 255, 255, 0.7);

//   .tac-popup--wrap {
//     width: 100%;
//     height: 100%;
//     padding: 0;

//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }

//   .tac-popup--inner {
//     width: 90%;
//     max-width: 600px;
//     // height: 90%;
//     // max-height: 600px;
//     position: relative;
//     background-color: #fff;
//     border-radius: 30px;
//     padding: 30px;

//     padding-top: 40px;
//     padding-bottom: 30px;

//     @media (min-width: $tabletVMin) {
//       padding-top: 60px;
//     padding-bottom: 60px;
//     }
//   }
//   .tac-popup--close {
//     position: absolute;
//     // top: 30px;
//     top: 10px;
//     right: 20px;
//     cursor: pointer;
//     z-index: 10;

//     @media (min-width: $tabletVMin) {
//       top: 30px;
//       right: 30px;
//     }
//   }

//   .tac-popup--text {
//     .tac-popup--text-title {
//       line-height: 1.5;
//       font-family: $font700;
//       font-weight: 700;
//       text-align: center;
//       // font-size: 24px;
//       font-size: 23px;
//       margin: auto;
//       margin-bottom: 18px;
//       padding: 0;
//       // width: 270px;
//       width: 230px;

//       @media (min-width: $tabletVMin) {
//         margin-bottom: 48px;
//         width: 100%;
//       }

//       @media (min-width: $template1400) {
//         // font-size: 20px;
//         font-size: 24px;
//         margin-bottom: 32px;
//         text-align: center;
//         padding: 0 42px;
//       }

//     }

//     .tac-popup--text-p {
//       color: $color-theme-2;
//       // font-size: 12px;
//       // font-size: 14px;
//       font-size: 12px;
//       line-height: 1.6;
//       margin-bottom: 24px;
//       font-family: $font500;

//       @media (min-width: $tabletVMin) {
//         font-size: 17px;
//         line-height: 1.6;
//         // line-height: 2;
//         line-height: 1.6;
//       }

//       @media (min-width: $template1400) {
//         // font-size: 14px;
//         font-size: 17px;
//         // line-height: 1.8;
//         line-height: 1.6;
//         margin-bottom: 38px;
//       }

//       li {
//         // padding-left: 20px;
//         margin-bottom: 5px;

//         @media (min-width: $tabletVMin) {
//           // padding-left: 30px;
//           margin-bottom: 15px;
//         }
//       }
//     }

//   }
//   .button-row {
//     margin: auto;
//     // padding-top: 30px;
//     text-align: center;

//     @media (min-width: $tabletVMin) {
//       // padding-top: 30px;
//       // text-align: center;
//     }

//     .tac-popup--agree {
//       display: block;
//       font-size: 14px;
//       width: 260px;
//       max-width: 260px;
//       height: 48px;
//       line-height: 48px;
//       border-radius: 6px;
//       padding-left: 29px;
//       padding-right: 29px;
//       cursor: pointer;

//       @media (min-width: $tabletVMin) {
//         // display: block;
//         // font-size: 14px;
//         // width: 260px;
//         // max-width: 260px;
//         // height: 48px;
//         // line-height: 48px;
//         // border-radius: 6px;
//         // padding-left: 29px;
//         // padding-right: 29px;
//       }
//     }
//   }
// }

#agree-terms,
#agree-terms--paypal,
#agree-terms--google,
#agree-terms--apple {
  // display: inline-block;
  width: 20px;
  height: 20px;
  // float: left;

  display: block;
  float: left;
  cursor: pointer;
}
