.footer-main {
  margin-left: -24px;
  width: calc(100% + 48px);

  @media (min-width: $template1400) {
    width: 100%;
    margin-left: 0;
  }

  .container {
    // max-width: 200px;
    margin: auto;

    @media (min-width: $tabletVMin) {
      max-width: none;
    }
  }
  // .footer-main-top {
  //     background-color: $color-footer;
  //     padding-top: 19px;
  //     padding-bottom: 19px;
  //     width: 100%;
  //     padding-left: 10px;
  //     padding-right: 10px;

  //     min-height: 150px;
  //     @media (min-width: $tabletVMin) {
  //         height: 150px;
  //     }

  //     p {
  //         text-align: center;
  //         color: #9cabbf;
  //         line-height: 1.5;

  //         font-size: 20px;
  //         @media (min-width: $tabletVMin) {
  //             font-size: 15px;
  //         }

  //         &.title {
  //             color: #ff8698;
  //             margin-bottom: 0px;
  //             font-weight: bold;
  //             line-height: 1.7;
  //             font-size: 20px;
  //             @media (min-width: $tabletVMin) {
  //                 font-size: 15px;
  //             }
  //         }
  //         &.location {
  //             color: #fff;
  //             line-height: 2.15;
  //             margin-bottom: 0px;
  //             font-size: 22px;
  //             @media (min-width: $tabletVMin) {
  //                 font-size: 16px;
  //             }
  //         }
  //     }

  // }
  //   .footer-main-bottom {
  //     background-color: $color-page-bg;
  //     padding-top: 18px;
  //     padding-bottom: 24px;

  //     .container {
  //       // max-width: 300px;
  //       margin: auto;

  //       @media (min-width: $tabletVMin) {
  //         max-width: none;
  //       }
  //     }

  //     p {
  //       text-align: center;
  //       margin-bottom: 10px;

  //       a {
  //         // font-size: 12px;
  //         line-height: 2;
  //         text-decoration: none;
  //         color: #000;
  //         margin: 0 20px;

  //         // font-size: 15px;
  //         font-size: 18px;
  //         @media (min-width: $tabletVMin) {
  //           font-size: 15px;
  //         }

  //         &:hover {
  //           text-decoration: underline;
  //         }
  //       }
  //     }

  //     .bottom-title {
  //       // font-size: 11px;
  //       // line-height: 20px;
  //       line-height: 1.8;
  //       color: #2a3e58;
  //       text-align: center;

  //       // font-size: 14px;
  //       font-size: 19px;
  //       @media (min-width: $tabletVMin) {
  //         font-size: 14px;
  //       }
  //     }
  //   }
}
