.map-legends {
  padding-top: 50px;

  .legend--one {
    overflow: hidden;
  }

  .legend--one .legend {
    width: 100%;
    float: left;
    padding-left: 30px;
    padding-right: 15px;
    position: relative;
    margin-bottom: 15px;

    @media (min-width: $tabletVMin) {
      width: 50%;
    }

    &.clear-mobile {
      @media (max-width: $mobileResponsiveMax) {
        clear: both;
      }
    }
  }

  @media (min-width: 1024px) {
    .legend--one .legend {
      width: 33%;
      padding-right: 20px;
    }
  }

  .legend--one .legend .ico {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 0;
    border: 1px solid #fff;
  }

  @media (min-width: 1024px) {
    .legend--one .legend .ico {
      width: 22px;
      height: 22px;
    }
  }

  .legend--one .legend .ico.blue {
    background-color: #167a91;
  }

  .legend--one .legend .ico.red {
    background-color: #d8374f;
  }

  .legend--one .legend .ico.transparent {
    background-color: transparent;
  }

  .legend--one .legend .title {
    color: #fff;
    font-size: 16px;
    line-height: 1.6;
  }

  @media (min-width: 1024px) {
    .legend--one .legend .title {
      font-size: 18px;
      line-height: 22px;
      width: 90%;
    }
  }

  @media (min-width: 1470px) {
    .legend--one .legend .title {
      font-size: 21px;
      line-height: 18px;
    }
  }

  .legend--two {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin-top: 22px;
    padding-top: 26px;
    overflow: hidden;
  }

  @media (min-width: 1024px) {
    .legend--two {
      margin-top: 47px;
      padding-top: 107px;
    }
  }

  .legend--two .legend {
    margin-bottom: 35px;
  }

  @media (min-width: 1024px) {
    .legend--two .legend {
      width: 49%;
      padding-right: 50px;
      float: left;
    }
  }

  @media (min-width: 1470px) {
    .legend--two .legend {
      width: 49%;
      padding-right: 50px;
      float: left;
    }
  }

  .legend--two .legend .title {
    padding-left: 36px;
    position: relative;
    overflow: hidden;
    color: #fff;
    font-size: 17px;
    line-height: 2;
    margin-bottom: 15px;
  }

  @media (min-width: 1024px) {
    .legend--two .legend .title {
      padding-left: 50px;
      font-size: 20px;
      line-height: 36px;
    }
  }

  .legend--two .legend .title .ico {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 0;
    top: 5px;
    border: 1px solid #fff;
  }

  @media (min-width: 1024px) {
    .legend--two .legend .title .ico {
      top: 0;
      width: 32px;
      height: 32px;
    }
  }

  .legend--two .legend .title .ico.blue {
    background-color: #167a91;
  }

  .legend--two .legend .title .ico.red {
    background-color: #d8374f;
  }

  .legend--two .legend .title .ico.blue-2 {
    background-color: #00b400;
  }

  .legend--two .legend .title .ico.red-2 {
    background-color: #eb1b4a;
  }

  .legend--two .legend .title .ico.transparent {
    background-color: transparent;
  }

  .legend--two .legend .description {
    font-size: 17px;
    line-height: 1.5;
    color: #fff;
    .txt {
      font-style: italic;
      font-weight: 300;
      font-size: 14px;
      line-height: 1.6;
      margin: 25px 0 0;
      color: rgba(255, 255, 255, 0.7);
    }
  }

  @media (min-width: 1024px) {
    .legend--two .legend .description {
      line-height: 32px;
    }
  }

  @media (min-width: 1470px) {
    .legend--two .legend .description {
      line-height: 38px;
    }
  }
}

// legends v2
.map-legends-gorizontal {
  display: grid;
  gap: 5px;
  padding-top: 10px;
  @media (min-width: $tabletVMin) {
    padding-top: 45px;
  }
  .legend {
    display: flex;
    flex-direction: column;
    border: 1px solid #E4E7EA;
    @media (min-width: $tabletVMin) {
      flex-direction: row;
      min-height: 108px;
    }
    .legend-title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $font500;
      font-weight: 500;
      font-size: 16px;
      color: $color-white;
      padding: 10px;
      width: 100%;
      flex: 1 0 auto;
      @media (min-width: $tabletVMin) {
        font-size: 20px;
        max-width: 238px;
      }
    }
    .legend-text {
      font-family: $fontComissioner;
      font-size: 14px;
      padding: 15px 10px;
      @media (min-width: $tabletVMin) {
        font-size: 18px;
        display: flex;
        align-items: center;
        padding: 10px 15px;
      }
    }
    &.legend-green {
      .legend-title {
        background-color: #2BB11E;
      }
    }
    &.legend-red {
      .legend-title {
        background-color: $color-theme;
      }
    }
  }
}
