.free-v32 {
  width: 100%;
  margin-bottom: 150px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: $mobileResponsiveMax) {
    width: 100%;
    margin-bottom: 48px;
  }

  .img {
    display: block;

    width: 450px;
    margin-bottom: 48px;

    @media (max-width: $mobileResponsiveMax) {
      // width: 100%;
      width: 333px;
      margin-bottom: 32px;
    }
  }

  .button {
    width: 305px;
    height: 56px;

    @media (max-width: $mobileResponsiveMax) {
      width: 100%;
      height: 48px;
      font-size: 14px;
    }
  }

 }
