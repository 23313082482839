.page-thank-you {
  padding-top: 50px;
  background-color: $color-page-start;
  padding-bottom: 101px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-height: $smallScreenHeight) {
    padding-top: 20px;
  }

  .container {
    max-width: 980px;
    margin: auto;
  }

  .action-row {
    .ico {
      float: right;
      position: relative;
      top: 5px;
    }
    .logo-03 {
      float: left;
    }
  }

  .hero {
    height: 160px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;

    padding-top: 100px;
    @media (min-width: $tabletVMin) {
      padding-top: 200px;
    }

    @media (max-height: $smallScreenHeight) {
      // height: 80px;
    }

    img {
      margin-bottom: 21px;

      @media (max-height: $smallScreenHeight) {
        margin-bottom: 10px;
      }
    }

    .title {
      display: flex;
      margin-bottom: 23px;
      flex-direction: column;
    }

    strong {
      text-align: center;
      display: block;
      font-size: 20px;
      line-height: 30px;
      color: #000;
      font-family: $font700;
      font-weight: 700;
      @media (min-width: $tabletVMin) {
        font-size: 30px;
      }

      @media (max-height: $smallScreenHeight) {
        font-size: 14px;
        line-height: 1.4;
      }

      &.main {
        color: $color-theme;
      }
    }

    .important {
      text-align: center;
      background-color: #fff;
      width: 100%;
      padding: 22px;
      margin-bottom: 20px;

      p {
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;

        @media (min-width: $tabletVMin) {
          // font-size: 18px;
          font-size: 17px;
        }
      }

      .date {
        padding-top: 4px;
        font-size: 13px;
        color: $color-input;

        @media (min-width: $tabletVMin) {
          font-size: 17px;
        }
      }
    }

    .also {
      text-align: center;
      padding: 0 20px;
      // font-size: 12px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.4);

      font-size: 16px;
      @media (min-width: $tabletVMin) {
        font-size: 16px;
      }

      @media (max-height: $smallScreenHeight) {
        font-size: 12px;
      }

      padding-bottom: 100px;
      @media (min-width: $tabletVMin) {
        padding-bottom: 200px;
      }
    }
  }

  .share-row {
    display: flex;
    align-items: center;
    flex-direction: column;

    margin-top: 50px;
    @media (max-height: $smallScreenHeight) {
      margin-top: 10px;
    }

    .sharethis-inline-share-buttons {
      margin-top: 20px;

      @media (max-height: $smallScreenHeight) {
        margin-top: 5px;
      }
    }
  }

  &.reschedule-thank-you {
    .reschedule-logo {
      position: absolute;
      top: 50px;
      left: calc(50% - 100px);
      @media (min-width: $tabletVMin) {
        top: 100px;
      }
    }
    .hero {
      padding-left: 15px;
      padding-right: 15px;
      strong {
        color: #1E3C5C;
        line-height: 1.5;
        @media (min-width: $tabletVMin) {
          font-size: 23px;
        }
        &.main {
          color: #D8374F;
        }
      }
      .important {
        margin-bottom: 25px;
        p {
          font-family: $font500;
          font-weight: 500;
          @media (min-width: $tabletVMin) {
            font-size: 20px;
          }
        }
        .date {
          font-family: $font500;
          font-weight: 500;
          padding-top: 17px;
          @media (min-width: $tabletVMin) {
            font-size: 18px;
          }
        }
      }
      .also {
        font-family: $font500;
        font-weight: 500;
      }
    }
    .footer-small-fixed {
      height: 140px;
      p {
        font-family: $font500;
        font-weight: 500;
        color: rgba(255, 255, 255, .8);
        @media (min-width: $tabletVMin) {
          font-size: 20px;
        }
        a {
          font-family: $font600;
          font-weight: 600;
          color: #fff;
          @media (min-width: $tabletVMin) {
            font-size: 24px;
          }
        }
      }
    }
  }

  .button {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.thank-you-wrap {
  .header-transparent {
    .header-transparent-content {
      .menu-row,
      .menu-mobile-opener {
        display: none;
      }
    }
  }
  &.upsell-thank-you {
    .header-transparent {
      display: none;
    }
    .page-thank-you {
      padding-bottom: 200px;
      .hero {
        padding-top: 100px;
      }
      .reschedule-logo {
        top: 50px;
      }
    }
  }

  // share thank-you page
  .share-thank-you {
    text-align: center;
    text-wrap: balance;
    font-weight: 600;
    padding: 0 20px;
    line-height: 22px;
    margin: 25px 0 50px;
    .button-share {
      margin: 20px 0 0;
    }
    @media (min-width: $mobileResponsiveMin) {
      display: none;
    }
  }
}