html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  box-sizing: border-box;
  font-family: $fontNoto;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0; 
  // font-weight: normal;
}

strong,
b {
  font-family: $font700;
  font-weight: 700;
}

.page {
  min-height: 100%;
  // padding-left: 24px;
  // padding-right: 24px;
  position: relative;
  width: 100%;
  margin: auto;

  &.page-full-height {
    height: 100%;
  }
}

#st-el-5 .st-close {
  bottom: 5px !important;
  padding: 8px !important;
}
