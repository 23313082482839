.header-full-v32{

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  height: 124px;
  // height: 76px;
  display: flex; 
  align-items: flex-start;

  // border-top: 24px solid #fff;


  @media (max-width: $mobileResponsiveMax) {
    height: 60px;
    padding-left: 0;
    background-color: #fff;
    width: 100%;
  }

  &.default {
    background-color: #fff;
  }

  .header-full-content {

    width: 100%;
    // max-width: 1344px;
    max-width: 1444px;
    // border-left: 50px solid #fff;
    // border-right: 50px solid transparent;
    margin: auto;

    // border-top: 24px solid transparent;
    border-top: 24px solid #fff;
    border-bottom: 24px solid transparent;

    @media (max-width: $mobileResponsiveMax) {
      border-top: 0 solid transparent;
      border-bottom: 24px solid transparent;
    }
  

    .header-left-container {
      display: flex;
      align-items: center;
      gap: 64px;
      // height: 124px;
      height: 76px;
      padding: 38px 0px;

      width: 380px;
      background-color: #fff;
      border-radius: 0 0 24px 0;
      border-left: 50px solid #fff;

      @media (max-width: $mobileResponsiveMax) {
        gap: 24px;
        width: 100%;
        height: 60px;
        padding: 16px 0px;
        padding-left: 20px;

        border-radius: 0;
      }
    }


    .logo {
      float: left;

      width: 203px;
      height: 32px;


      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      @media (max-width: $mobileResponsiveMax) {
        width: 159px;
        height: 24px;

      }
  
      img {
        width: 100%;
      }
    }
  }
  

  .menu-row--uscca {

    width: 49px;
    height: 48px;

    float: left;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    @media (max-width: $mobileResponsiveMax) {
      width: 29px;
      height: 28px;
    }

    
  }
}