.location-section {
  background-color: $color-white;

  &.desktop-wide-wrap {
    @media (max-width: $mobileResponsiveMax) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media (min-width: $tabletVMin) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .location-section--inner {
    background-color: $color-footer;
    padding-top: 19px;
    padding-bottom: 19px;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    min-height: 120px;

    @media (min-width: $tabletVMin) {
      height: 140px;
      padding-top: 31px;
      height: 170px;
      border-radius: 13px;
    }

    @media (min-width: $template1400) {
      height: 170px;
      padding-top: 31px;
    }
  }

  p {
    text-align: center;
    color: #9cabbf;
    line-height: 1.4;
    font-size: 15px;

    @media (min-width: $tabletVMin) {
      line-height: 1.5;
      font-size: 17px;
    }

    &.title {
      color: $color-footer-link;
      margin-bottom: -6px;
      font-weight: bold;
      line-height: 1.7;
      font-size: 15px;

      @media (min-width: $tabletVMin) {
        font-size: 17px;
        margin-bottom: 8px;
      }
    }

    &.location {
      &,
      & a {
        color: $color-white;
        line-height: 2.15;
        margin-bottom: 0px;
        font-size: 15px;
        text-decoration: none;

        @media (min-width: $tabletVMin) {
          font-size: 17px;
          margin-bottom: 2px;
          line-height: 2;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &.bottom {
      color: #9cabbf;
    }
  }
  .divider {
    display: none;
    @media (min-width: $template1400) {
      width: 40px;
      display: inline-block;
    }
  }
  .text-row {
    display: block;
    @media (min-width: $template1400) {
      display: inline-block;
    }
  }
}

.reschedule-row {
  background-color: #eef2f7;
  .location-section {
    background-color: transparent;
    .location-row {
      p {
        font-family: $font400;
        font-size: 16px;
        @media (min-width: $tabletVMin) {
          font-size: 20px;
        }
        &.title {
          color: #fff;
          font-size: 20px;
          font-family: $font400;
        }
        &.location {
          a {
            font-size: 18px;
            font-family: $font600;
            font-weight: 600;
            @media (min-width: $tabletVMin) {
              font-size: 24px;
            }
          }
        }
      }
    } 
  }
}