.page-course-login {
    text-align: center;
    background-color: #F5F8FE;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .page-flat-content {
        display: flex;
        align-items: center;
        flex: 1;
    }
    .title {
        color: $color-primary;
        font-size: 32px;
        line-height: 1.3;
        margin-bottom: 15px;
        font-family: $font600;
        font-weight: 600;
    }
    .remark {
        color: rgba($color-primary, .8);
        font-size: 16px;
        margin: 0 auto 15px;
        text-wrap: balance;
    }
    .subtitle {
        color: rgba($color-primary, .8);
        font-size: 16px;
        line-height: 1.3;
        margin-bottom: 36px;
        font-family: $fontComissioner;
    }
    .form-login {
        width: 100%;
        max-width: 536px;
        margin: 0 auto;
        padding: 0;
        left: 0;
        right: 0;
    }
    .form-group {
        display: flex;
        flex-direction: column;
    }
    label {
        color: #6C6D78;
        font-size: 14px;
        font-family: $fontComissioner;
        text-align: left;
        margin-bottom: 10px;
    }
    input {
        font-size: 14px;
        line-height: 1.6;
        padding: 16px 24px;
        border-radius: 12px;
        border: 1px solid var(--Background-Quinary, #E4E7EA);
        background: rgba(255, 255, 255, 0.50);
        margin-bottom: 48px;
    }
    .btn {
        font-size: 16px;
        font-family: $font600;
        font-weight: 600;
        border-radius: 16px;
        background-color: #1E3C5C;
        color: $color-white;
        padding: 16px 48px;
        width: 100%;
        border: none;
        cursor: pointer;
        transition: all .25s;
        &:hover {
            opacity: .85;
        }
    }

    @media (min-width: $tabletVMin) {
        .title {
            font-size: 40px;
            line-height: 1.5;
        }
        .remark {
            font-size: 18px;
        }
        .subtitle {
            font-size: 18px;
            margin-bottom: 58px;
        }
        label {
            font-size: 16px;
        }
        input {
            font-size: 16px;
            line-height: 1.4;
        }
        .btn {
            width: auto;
        }
    }
}