.footer-first-page {
  background-color: $color-footer;

  width: 100%;
  overflow: hidden;

  padding-top: 30px;
  padding-bottom: 40px;

  @media (min-width: $tabletVMin) {
  }
  @media (min-width: $template1400) {
  }

  .logo-first-footer {
    margin-bottom: 17px;

    @media (min-width: $tabletVMin) {
    }
    @media (min-width: $template1400) {
    }
  }

  p {
    color: #fff;
    font-size: 20px;
    text-decoration: none;
    font-weight: normal;
    text-align-last: left;
    font-size: 17px;

    @media (min-width: $tabletVMin) {
    }
    @media (min-width: $template1400) {
    }
  }

  a {
    color: #fff;
    font-size: 20px;

    text-decoration: none;
    font-weight: normal;
    display: inline-block;

    @media (min-width: $tabletVMin) {
      font-size: 17px;
    }
    @media (min-width: $template1400) {
    }
  }

  .social-footer {
    a {
      margin-right: 14px;

      @media (min-width: $tabletVMin) {
      }
      @media (min-width: $template1400) {
      }
    }
  }
  .col {
    @media (min-width: $tabletVMin) {
    }
    @media (min-width: $template1400) {
      width: 30%;
      float: left;
      overflow: hidden;
      text-align-last: left;
    }
  }

  .col-1 {
    margin-bottom: 30px;

    @media (min-width: $tabletVMin) {
    }
    @media (min-width: $template1400) {
      width: 36%;
      margin-bottom: 0;
    }

    p {
      margin-bottom: 17px;
      font-family: $font700;
      font-weight: 700;

      @media (min-width: $tabletVMin) {
      }

      @media (min-width: $template1400) {
        font-weight: 600;
      }
    }
  }

  .col-2 {
    margin-bottom: 30px;

    @media (min-width: $tabletVMin) {
    }

    @media (min-width: $template1400) {
      margin-bottom: 0;
    }

    p {
      margin-bottom: 20px;

      @media (min-width: $tabletVMin) {
        margin-bottom: 45px;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.5;
        letter-spacing: -0.18px;
      }

      @media (min-width: $template1400) {
      }
    }
  }
  .col-3 {
    p {
      margin-bottom: 20px;
      font-family: $font400;

      @media (min-width: $tabletVMin) {
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.5;
        letter-spacing: -0.18px;
      }

      @media (min-width: $template1400) {
      }
    }
  }

  .contact-block {
    padding-left: 30px;

    @media (min-width: $tabletVMin) {
    }
    @media (min-width: $template1400) {
      padding-left: 44px;
    }

    a {
      display: block;
      font-size: 20px;
      line-height: 1.5;
      position: relative;

      @media (min-width: $tabletVMin) {
        font-size: 17px;
      }
      @media (min-width: $template1400) {
      }

      .ico {
        position: absolute;
        display: block;
        left: -28px;
        top: 10px;

        @media (min-width: $tabletVMin) {
        }
        @media (min-width: $template1400) {
          left: -40px;
          top: 10px;
        }
      }
    }
    span {
      color: #fff;
      display: block;
      font-size: 18px;
      line-height: 1.2;
      margin-bottom: 20px;

      @media (min-width: $template1400) {
        font-size: 15px;
        margin-bottom: 30px;
      }
    }
  }
}

// Col-1 and Col-2 Temporarily removed
.footer-first-page {
  .col-1 {
    // @media (min-width: $tabletH) {
    display: none !important;
    // }
  }
  .col-2 {
    // @media (min-width: $tabletH) {
    display: none !important;
    // }
  }
  .col-3 {
    // @media (min-width: $tabletH) {
    float: none;
    margin: auto;
    // }

    .contact-block {
      padding-left: 0;
    }
  }
}
