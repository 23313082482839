.map-switcher-v3 {
  background-color: #E4E7EA;
  padding: 124px 48px;
  border-radius: 0 0 48px 48px;
  max-width: 1392px;
  margin: auto;
  margin-bottom: 150px;
  top: -4px;
  position: relative;

  @media (max-width: $mobileResponsiveMax) {
    padding-top: 22px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 18px;
    font-weight: 900;
    border-radius: 0 0 24px 24px;
    width: calc(100% - 40px);
    margin: auto;
    margin-bottom: 48px;
  }

  &:before {
    width: 686px;
    height: 686px;
    display: block;
    content: "";
    position: absolute;
    z-index: 0;
    background-image: url('/assets-v3/target.svg');
    background-repeat: no-repeat;
    background-size: 686px;
    background-position: 0px 0px;
    top: -125px;
    left: 10px;
    opacity: 0.3;

    @media (max-width: $mobileResponsiveMax) {
      width: 300px;
      height: 300px;
      left: 10px;
      top: 670px;
      background-size: contain;
    }
  }

  .title {
    font-size: 48px;
    line-height: 56px;
    color: #17181B;
    text-align: left;
    margin-bottom: 100px;
    font-family: $font600;
    font-weight: 600;
    
    @media (max-width: $mobileResponsiveMax) {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 24px;
    }
  }

  .map-columns {
    margin-bottom: 120px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    font-family: $font600;
    font-weight: 600;

    @media (max-width: $mobileResponsiveMax) {
      flex-direction: column-reverse;
      margin-bottom: 32px;
    }

    .col-map {
      width: 654px;
      height: 432px;
      position: relative;
      z-index: 1;

      &.basic {
        width: 756px;
        height: 445px;

        @media (max-width: $mobileResponsiveMax) {
          width: 100%;
          height: auto;
        }
      }
      &.multy {
        width: 654px;
        height: 432px;

        @media (max-width: $mobileResponsiveMax) {
          width: 100%;
          height: auto;
        }
      }
    
      img {
        width: 100%;
      }

      .under_map {
        background-color: #fff;
        color: #6C6D78;
        border-radius: 32px;
        margin-left: 115px;
        float: left;
        margin-top: 26px;
        padding: 14px 24px;
        font-size: 14px;
        font-family: $fontComissioner;

        @media (max-width: $mobileResponsiveMax) {
          font-size: 14px;
          line-height: 20px;
          padding: 14px 24px;
          margin-top: 32px;
          margin-bottom: 32px;
          margin-left: 0;
        }
      }
    }
    .col-switch {
        width: 432px;

      @media (max-width: $mobileResponsiveMax) {
        width: 100%;
      }

      .cost {
        color: #6C6D78;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 24px;
        text-transform: uppercase;
        text-decoration: none;
        font-family: $font500;
        font-weight: 500;

        @media (max-width: $mobileResponsiveMax) {
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 24px;

        }
  
      }

      .switch-tabs {
        margin-bottom: 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        @media (max-width: $mobileResponsiveMax) {
          gap: 16px;
          margin-bottom: 24px;
          padding-right: 3px;
        }

        .switch-tab {
          width: 204px;
          border-radius: 24px;
          cursor: pointer;
          padding: 24px 27px 17px 24px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          background-color: #fff;
          border: 1px dashed #CCD9E8;

          @media (max-width: $mobileResponsiveMax) {
            width: 50%;
            padding: 24px 9px;
            border-radius: 24px;
            padding-right: 0;
          }

          .q {
            background-color: #D8374F;
            
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            color: #fff;
            flex-shrink: 0;

            width: 24px;
            height: 24px;
            font-size: 12px;

            @media (max-width: $mobileResponsiveMax) {
              width: 24px;
              height: 24px;
              font-size: 12px;
            }
          }
          .col {
            padding-left: 12px;

            @media (max-width: $mobileResponsiveMax) {
              padding-left: 8px;
            }

            .price {
              color: #17181B;
              font-size: 20px;
              line-height: 28px;
              margin-bottom: 8px;
              text-align: left;
              font-family: $font500;
              font-weight: 500;
              
              @media (max-width: $mobileResponsiveMax) {
                font-size: 20px;
                line-height: 28px;
                margin-bottom: 8px;
              }
            }
            .text {
              color: #6C6D78;
              font-size: 16px;
              line-height: 24px;
              text-align: left;
              font-family: $fontComissioner;
              font-weight: normal;

              @media (max-width: $mobileResponsiveMax) {
                font-size: 14px;
                line-height: 20px;
              }
            }
          }

          &.active {
           
            border: 1px dashed #CCD9E8;
            background-color: #39628C;

            .col {
              .price {
                color: #fff;
              }
              .text {
                color: #fff;
              }
            }
          }

          @media (max-width: $mobileResponsiveMax) {

          }
        }
      }

      .switch-tabs--content {
        .tab-content {
          display: none;

          &.active {
            display: block;
          }

          .desc {
            color: #000000;
            font-size: 16px;
            line-height: 24px;
            text-align: left;
            margin-bottom: 24px;
            font-family: $font600;
            font-weight: 600;

            @media (max-width: $mobileResponsiveMax) {
              font-size: 16px;
              line-height: 24px;
              margin-bottom: 24px;
            }
          }

          .dropdown {
            border: 1px solid #CCD9E8;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 32px;
            color: #6C6D78;
            font-size: 16px;
            margin-bottom: 16px;
            background-color: #fff;
            height: 80px;
            border-radius: 24px;
            font-family: $fontComissioner;

            @media (max-width: $mobileResponsiveMax) {
              height: 56px;
              line-height: 56px;
              border-radius: 20px;
              padding-left: 18px;
              font-size: 14px;
              line-height: 20px;
              margin-bottom: 8px;
            }
          }
          .button {
            width: 100%;
            height: 80px;
            max-width: none;
            border-radius: 24px;

            @media (max-width: $mobileResponsiveMax) {
              height: 56px;
              border-radius: 20px;
              font-size: 14px;
              margin-bottom: 44px;
              font-family: $font500;
              font-weight: 500;
            }
          }
        }
      }

    }
  }

  .legend-wrap {

    @media (max-width: $mobileResponsiveMax) {

    }

    &.basic {
      color: #000000;
      font-size: 18px;
      padding: 32px 24px;
      line-height: 1.3;
      font-family: $fontComissioner;

      border: 1px solid #E4E7EA;

      @media (max-width: $mobileResponsiveMax) {
        padding: 24px 16px;
        font-size: 14px;
      }
    }
    &.multi {
      @media (max-width: $mobileResponsiveMax) {

      }
    }
  }
  .legend {

    @media (max-width: $mobileResponsiveMax) {

    }

    .legend-row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 4px;

      @media (max-width: $mobileResponsiveMax) {
        flex-direction: column;
        margin-bottom: 32px;
      }

      .col-color {
        font-size: 20px;
        color: #fff;
        width: 238px;
        min-height: 68px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        // flex-grow: 0;
        font-family: $font500;
        font-weight: 500;

        @media (max-width: $mobileResponsiveMax) {
          width: 100%;
          margin-bottom: 16px;
          height: 56px;
          font-size: 16px;
          line-height: 24px;
          
        }

        &.yes {
          background-color: #2BB11E;
          height: 92px;

          @media (max-width: $mobileResponsiveMax) {
            height: 56px;
          }
        }
        &.no {
          background-color: #D8374F;
        }
      }
      .col-text {
        padding-left: 16px;
        color : #000;
        font-size: 18px;
        line-height: 1.3;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: $fontComissioner;

        @media (max-width: $mobileResponsiveMax) {
          padding-left: 0;
          padding: 16px;
          font-size: 14px;
        }
      }
    }
   
  }
 }


 .map-switcher-v3 {
  &.basic {

    &:before {
      @media (max-width: $mobileResponsiveMax) {
        top: 620px;
      }
    }

    .basic {
      display: block;
    }
    .multi {
      display: none;
    }
  }
  &.multi {
    .multi {
      display: block;
    }
    .basic {
      display: none;
    }
  }
 }