.page-quiz--v2 {

  .action-row {
    display: none;
  }

  #quiz-wizard {
    margin: auto;
    width: 100%;

    background-color: #F5F8FE; 
    border: none;

    @media (max-width: $mobileResponsiveMax) {
      width: 100%;
    }

    .page-wrapper {
      margin: auto;
      width: 809px;
      padding-top: 64px;
    
      @media (max-width: $mobileResponsiveMax) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 32px;
    
      }
    }

  }

  .page-step--logo {
      text-align: center;
      @media (max-width: $mobileResponsiveMax) {
      
      }

    .logo-quiz {
      width: 203px;
      height: 32px;
      margin: auto;
      margin-bottom: 64px;
      display: block;

      @media (max-width: $mobileResponsiveMax) {
        margin-bottom: 44px;
      } 

      img {
        width: 100%;
      }
    }
  }

  .page-step--logo--under {
    font-family: $font600;
    font-weight: 600;
    text-align: center;
    font-size: 32px;
    margin-bottom: 64px;
    color: #17181B;
    
    @media (max-width: $mobileResponsiveMax) {
      font-size: 24px;
      margin-bottom: 24px;
    }

  }

  .page-step{
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .counter {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      width: 100%;

      // ! For mobile only
      display: none;

      @media (max-width: $mobileResponsiveMax) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        width: 100%;

        border-bottom: 1px dashed #D8E2EF;
        padding-bottom: 32px;
        margin-bottom: 32px;
      }

      span {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #D8374F;
        color: #fff;
        border-radius: 50%;
        font-size: 16px;
        width: 40px;
        height: 40px;
        font-family: $font500;
        font-weight: 500;
        padding-top: 2px;

        @media (max-width: $mobileResponsiveMax) {
          width: 32px;
          height: 32px;

        }
      }
    
      .divider {
        display: none;
        @media (max-width: $mobileResponsiveMax) {
          display: block;
          font-size: 32px;
          color: #D8E2EF;
          margin-left: 3px;
          margin-right: 3px;
        }
      }
      
      .divider + span {
        border: 1px solid #CCD9E8;
        color :#39628C;
        background-color: transparent;
      }
    }

    .counter--dots-row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      width: 100%;
      margin-bottom: 64px;
      gap: 18px;

      a {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        flex-shrink: 0;

        border: 2px solid #CCD9E8;
        background-color: transparent;
        color: #39628C;
        text-decoration: none;
        position: relative;
        border-radius: 50%;
        font-size: 16px;
        width: 40px;
        height: 40px;
        font-family: $font500;
        font-weight: 500;

        &.inactive {

        }

        &.active {
          background-color: #D8374F;
          border-color: #D8374F;
          color: #fff;
        }
        
        &.done {
          position: relative;

          background-color: #CCD9E8;
          border-color: #CCD9E8;
          color: transparent;
          font-size: 0px;

          &:before {
            display: block;
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            z-index: 1;
            background-image: url('/assets-v3/check.png');
            background-repeat: no-repeat;
            background-size: 12px;
            background-position: 50% 50%;
            background-color: #CCD9E8;
            border-color: #CCD9E8;
            color: #39628C;
            border-radius: 50%;
          }
        }
      }
      
      .counter--divider {
        height: 1px;
        width: 74px;
        border-top: 1px dashed #CCD9E8;
        display: flex;
        flex-shrink: 1;

        &:last-child{
          display: none;
        }
      }

      .done + .counter--divider  {
       
        border-top: 1px solid #CCD9E8;
      }

      @media (max-width: $mobileResponsiveMax) {
        display: none;
      }
    }

    .question {
      color: #17181B;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 32px;
      text-align: center;
      font-family: $font500;
      font-weight: 500;

      @media (max-width: $mobileResponsiveMax) {
        line-height: 1.3;

        font-size: 20px;
        margin-bottom: 24px;
      }

    }

    
    .input-block {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      box-shadow: none;
      border: 1px dashed #CCD9E8;

      margin: 0;
      padding: 0;
      width: 100%;
      left: 0;
      overflow: hidden;

      border-radius: 24px;
      width: 256px;
      height: 125px;
      font-size: 40px;


      @media (max-width: $mobileResponsiveMax) {
        width: 160px;
        height: 90px;
        font-size: 24px;
      }
    }

    form { 
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: self-start;

      margin: 0;
      padding: 0;
      width: 100%;
      left: 0;
      
      margin-bottom: 48px;
      gap: 13px;

      @media (max-width: $mobileResponsiveMax) {
        margin-bottom: 44px;
        gap: 16px;
      }

      input[type='radio'] {
        display: none;

        & + label {
          position: relative;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          font-family: $font600;
          font-weight: 600;
          padding: 0;
          width: 100%;
          height: 100%;

          // Blue highlight disable
          -webkit-tap-highlight-color: transparent;

          span {
            position: relative;
            z-index: 2;
            color: #fff;
            font-family: $font600;
            font-weight: 600;
          }
          &:before {
            position: absolute;
            left: 0;
            top: 0;
            content: '';
            display: block;
            background-image: none;

            background-color: #39628C;
            width: 100%;
            height: 100%;
            z-index: 1;
          }
        }

        &:checked + label {
          span {
            color: #000;
          }
          &:before {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            background-image: none;

            display: block;
            z-index: 1;

            background-color: #fff;
          }
        }
      }

      input[type='text']:disabled {
        background-color: white;
      }

    }


    .button-row {

      @media (max-width: $mobileResponsiveMax) {
    
      }
    
      .button-next {

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        gap: 4px;

        font-size: 16px;
        width: 200px;
        height: 56px;
        border-radius: 16px;



        @media (max-width: $mobileResponsiveMax) {
          height: 48px;
        }
      

        img {
          width: 16px;
        }
      }
    } 
  }


  .sw-theme-progress>.nav {
    @media (max-width: $mobileResponsiveMax) {
    display: none!important;
    }
  }

}