.faq-section-v3 {

  max-width: 1116px;
  margin: auto;
  padding-bottom: calc(150px - 16px);

  @media (max-width: $mobileResponsiveMax) {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 28px;
  }

  .faq-section-title {
    font-family: $font600;
    font-weight: 600;
    text-align: center;
    position: relative;
    color: #17181B;
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 32px;

    @media (max-width: $mobileResponsiveMax) {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 8px;
    }
  
    &:before {
      display: block;
      content: "";
      position: absolute;
      z-index: 1;
      top: 27px;
      left: -110px;
      width: 80px;
      height: 80px;
      background-image: url('/assets-v3/bullet.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 0 0;

      @media (max-width: $mobileResponsiveMax) {
        display: none;
      }
    }

  }
  .faq-section-subtitle {
    font-family: $font400;
    text-align: left;
    padding-left: 10px;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 96px;
    text-align: center;
    color: #6C6D78;
    padding-top: 70px;

    @media (max-width: $mobileResponsiveMax) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 24px;
      padding-top: 10px;
    }
  }

  .faq-section-link {
    line-height: 1.85;
    text-align: center;
    padding-top: 15px;
    font-size: 22px;

    @media (max-width: $mobileResponsiveMax) {
      
    }

    a {
      color: $color-theme;
    }
  }
  .faq {
    background-color: #ffffff;
    padding: 26px 112px 22px 32px;
    color: $color-important-description;
    margin-bottom: 16px;
    position: relative;
    border: 1px solid #E4E7EA;
    border-radius: 24px;
    min-height: 128px;
    transition: all .3s;
    padding-top: 48px;

    @media (max-width: $mobileResponsiveMax) {
      padding: 20px 16px 20px 16px;
      padding-top: 24px;
      border-radius: 16px;
      min-height: 72px;
    }

    .title {
      font-family: $font600;
      font-weight: 600;
      text-align: left;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px;
      cursor: pointer;
      text-align: left;
      color: #6C6D78;

      @media (max-width: $mobileResponsiveMax) {
       font-size: 16px;
       line-height: 24px;
       margin-bottom: 8px;
       padding-right: 38px;
      }

      &:before {
        content: '';
        width: 48px;
        height: 48px;
        position: absolute;
        top: 40px;
        right: 32px;
        display: block;
        background-image: url('/assets-v3/accordion--open.svg');
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;

        @media (max-width: $mobileResponsiveMax) {
          width: 32px;
          height: 32px;
          top: 50%;
          margin-top: -16px;
          right: 16px;
        }
      }
    }
    .description {
      display: none;
      position: relative;
      font-size: 20px;
      line-height: 30px;
      text-align: left;
      color: #6C6D78;

      @media (max-width: $mobileResponsiveMax) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    &.active {
      background-color: #F5F8FE;
      border-color: #F5F8FE;
      padding-top: 32px;
      @media (max-width: $mobileResponsiveMax) {
       padding-top: 20px;
      }
      
      .title {
        color: #17181B;
  
        &:before {
          background-image: url('/assets-v3/accordion--close.svg');
          top: 20px;
          margin-top: 0;
        }
      }
    }
  }
}
