.due-today {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: #f5faff;
  border: 1px dashed #cdd9e8;
  min-height: 80px;

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #495667;
    padding-left: 20px;
    align-items: flex-start;
    min-height: 80px;

    h3 {
      font-family: $font700;
      font-weight: 700;
      line-height: 1.7;

      font-size: 17px;
      @media (min-width: $tabletVMin) {
        font-size: 18px;
      }
    }

    // p {
    //   font-size: 14px;
    //   line-height: 1.2;
    //   @media (min-width: $tabletVMin) {
    //     font-size: 15px;
    //   }
    // }
  }

  .amount {
    display: flex;
    font-family: $font700;
    font-weight: 700;
    font-size: 22px;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    border-left: 1px dashed #cdd9e8;
    color: #d8374f;
    text-align: right;
    min-height: 80px;
    min-width: 130px;
    width: 30%;
    justify-content: center;
    text-align: right;
    flex-grow: 1;
    padding: 10px 20px;

    @media (min-width: $tabletVMin) {
      font-size: 22px;
    }
  }

  // &.double {
  //     padding-right: 0;
  //     flex-direction: row;

  //     .text {
  //         justify-content: left;
  //         width: 70%;
  //         padding-right: 24px;
  //         border-right: dashed 1px $color-important-border;
  //     }

  //     .amount {
  //         width: 30%;
  //         // justify-content: center;
  //         // text-align: center;
  //     }

  // }

  @media (max-width: $mobileResponsiveMax) {
    margin-top: 20px;
    margin-bottom: 20px;

    .text {
      width: 100%;
      max-width: 70%;
    }
  }

  // reschedule
  &.reschedule-due-today {
    width: 100%;
    max-width: 775px;
    margin-left: auto;
    margin-right: auto;
    border: none;
    border-radius: 24px;
    background-color: #fff;
    flex-direction: column;
    padding: 35px 20px 50px;
    @media (min-width: $tabletVMin) {
      padding: 50px 64px 70px;
    }
    .text {
      width: 100%;
      padding: 0;
      margin-bottom: 9px;
      align-items: center;
      h3 {
        color: #1E3C5C;
        font-size: 23px;
      }
    }
    .amount {
      width: 100%;
      border: none;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 13px;
    }
    .amount-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #F5F8FE;
      padding: 19px 22px;
      width: 100%;
      border-radius: 16px;
      &.main-item {
        background-color: #fff;
        border: 1px solid #D8374F;
      }
      &.total-item {
        color: #fff;
        background-color: #39628C;
        border-radius: 16px;
        margin-top: 20px;
        @media (min-width: $tabletVMin) {
          font-size: 18px;
        }
        .amount-description,
        .amount-price {
          font-family: $font600;
          font-weight: 600;
          color: #fff;
        }
      }
      &.fee-item {
        .amount-description {
          color: #D8374F;
        }
      }
      .amount-price {
        color: #D8374F;
        font-size: 16px;
        font-family: $font600;
        font-weight: 600;
        @media (min-width: $tabletVMin) {
          font-size: 18px;
        }
      }
      .amount-description {
        color: #000;
        border-radius: 14px;
        font-family: $font400;
        font-size: 14px;
        text-align: left;
        @media (min-width: $tabletVMin) {
          font-size: 16px;
        }
        .semibold {
          font-family: $font600;
          font-weight: 600;
          display: block;
          @media (min-width: $tabletVMin) {
            display: inline;
          }
        }
      }
    }
  }
}
.class-top-section {
  &.reschedule-due {
    background-image: none;
    .form-group {
      form {
        padding-left: 0;
        padding-top: 10px;
      }
      .button {
        background-color: #D8374F;
        text-transform: uppercase;
        font-size: 16px;
        font-family: $font600;
        font-weight: 600;
        border-radius: 16px;
        max-width: 200px;
      }
    }
  }
}