
.sms-mobile-opener {
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: 100% 50%;
  background-size: contain;
  background-size: 30px;
  position: relative;
  // z-index: z('sms-mobile-opener');
  z-index: 101;
  cursor: pointer;
  position: fixed;
  right: 20px;
  bottom: 20px;
  height: 40px;
  width: 180px;
  background-color: #009199;
  border-radius: 20px;
  justify-content: space-between;
  align-items: center;
  padding: 0 23px 0 4px;

  display: none;
  &.show {
    display: flex;
  }

  span {
    color: white;
  }

  &:before {
    // display: block;
    // content: '?';
    content: '';
    color: #fff;
    font-size: 44px;
    width: 60px;
    height: 60px;
    // background-color: #009199;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    font-weight: 900;
     background-image: url('/images/2023//comment-dots.svg');
    //  background-size: 30px;
     background-size: 26px;
     background-repeat: no-repeat;
     background-position: 50% 50%;
  }

  @media (min-width: $tabletVMin) {
    display: none !important;
  }
}

.sms-mobile-close {
  width: 30px;
  height: 30px;
  background-image: url('/ico/close-lite.svg');
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: contain;
  background-size: 30px;
  position: relative;
  // z-index: 3;
  z-index: z('sms-mobile-close');
  position: absolute;
  right: 20px;
  top: 30px;
  cursor: pointer;

  @media (min-width: $tabletVMin) {
    display: none !important;
  }
}

.sms-mobile {
  display: none;
  z-index: 2000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  @media (min-width: $tabletVMin) {
    display: none !important;
  }

  .sms-mobile-wrap {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .sms-mobile-fader {
    background-color: rgba(0, 0, 0, 0.9);

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // z-index: 1;
    z-index: z('sms-mobile-fader');
  }

  .sms-col {
    padding: 50px;
    position: relative;
    // z-index: 2;
    z-index: z('sms-col');
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    h3 {
      color: #fff;
      line-height: 30px;
      text-decoration: none;
      text-transform: uppercase;
      margin-bottom: 20px;

      padding-top: 150px;
      text-align: center;
    }

    .button {
      float: none;
      height: 41px;
      line-height: 41px;
      font-size: 13px;
      border-radius: 6px;
      padding-left: 17px;
      padding-right: 17px;

      font-stretch: normal;
      font-style: normal;
      width: auto;
      margin: 0 auto;

      &,
      &:hover {
        color: white !important;
      }
    }
  }
}
.page-first--quiz {
  .sms-mobile-opener {
    bottom: 70px;
  }
}
