.expected {
  background-color: #fff;
  padding-top: 82px;
  padding-bottom: 52px;

  background-image: url('/images/expected-mobile.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 0;

  @media (min-width: $tabletVMin) {
  }

  @media (min-width: $template1400) {
    padding-top: 75px;
    padding-bottom: 68px;
    background-image: url('/images/expected-desktop.jpg');
  }

  .title {
    font-family: $font700;
    font-weight: 700;
    color: #fff;
    text-align: center;
    margin: auto;
    font-size: 18px;
    margin-bottom: 36px;
    

    // width: 100%;
    width: 350px;
    max-width: 90%;

    @media (min-width: $tabletVMin) {
      width: 250px;
      // width: 400px;
      margin: auto;
      font-size: 21px;
      margin-bottom: 40px;
      text-align: center;
      line-height: 1.57;
    }

    @media (min-width: $template1400) {
    }
  }

  .expectaions {
    display: flex;
    flex-direction: column;

    @media (min-width: $template1400) {
      flex-direction: row;
      justify-content: space-between;
      max-width: 1100px;
      margin: auto;
    }

    .expectaion {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 11px;
      height: 74px;
      padding-left: 11px;
      padding-right: 11px;

      background-color: rgba(30, 60, 92, 0.75);
      border: 1px dashed #ccd7e5;
      border-radius: 16px;

      @media (min-width: $template1400) {
        height: 146px;
        width: 28%;
        flex-direction: column;
      }

      .ico {
        width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        @media (min-width: $template1400) {
          width: 100%;
          height: 80px;
          padding-top: 13px;
          flex-direction: column;
        }

        img {
          &.clock {
            width: 22px;

            @media (min-width: $template1400) {
              width: 30px;
            }
          }
          &.shooting {
            width: 26px;

            @media (min-width: $template1400) {
              width: 38px;
            }
          }
          &.map-pin {
            width: 29px;

            @media (min-width: $template1400) {
              width: 44px;
            }
          }
        }
      }

      .text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        font-size: 12px;
        color: #fff;
        font-weight: 600;

        @media (min-width: $tabletVMin) {
          font-size: 14px;
          line-height: 1.7;
        }
        @media (min-width: $template1400) {
          font-size: 14px;
          text-align: center;
        }
      }
    }
  }
}
