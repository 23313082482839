.articles-v3--full-carousel {
  max-width: 1396px;
  margin: auto;

  padding-left: 24px;
  padding-right: 24px;

  padding-top: 70px;
  padding-bottom: 70px;

  @media (max-width: $mobileResponsiveMax) {
    padding-top: 50px;
    padding-bottom: 50px;

    padding-left: 0;
    padding-right: 0;

    width: 342px;
    margin: auto;

  }

  .title {
    color: $color-theme-2;
    font-size: 42px;
    line-height: 56px;
    margin-bottom: 24px;
    font-family: $font600;
    font-weight: 600;

    @media (max-width: $mobileResponsiveMax) {
      font-size: 38px;
      line-height: 40px;
      margin-bottom: 8px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .text {
    font-size: 20px;
    text-align: left;
    max-width: 774px;
    margin-bottom: 40px;
    font-family: $fontComissioner;
    color: #6B6B6B;

    @media (max-width: $mobileResponsiveMax) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 24px;
      padding-left: 20px;
      padding-right: 20px;

      br {
        display: none;
      }
    }
  }
  .articles-list--full--v3 {
    margin-bottom: 64px;

    @media (max-width: $mobileResponsiveMax) {
      margin-bottom: 24px;
    }
  }

  .slide-wrap {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    // width: 660px;
    width: 318px;
    // gap: 24px;

    margin-right: 24px;

    @media (max-width: $mobileResponsiveMax) {
      flex-direction: column;
      align-items: center;

      width: 318px;
      gap: 16px;
      margin-left: 12px;
      margin-right: 12px;
    }
  }

  .slide-wrap--single {
    width: 318px;
    // gap: 24px;

    margin-right: 24px;

    @media (max-width: $mobileResponsiveMax) {

    }
  }

  .slide-wrap--double {
    width: 660px;
    gap: 24px;

    @media (max-width: $mobileResponsiveMax) {

    }
  }


  .item {
    width: 318px;
    height: 380px;
    border-radius: 16px;
    overflow: hidden;
    position: relative;

    @media (max-width: $mobileResponsiveMax) {

    }

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      display: block;
    }

    // &:before {
    //   display: block;
    //   content: "";
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background-image: url('/assets-v3/fader-article.svg');
    //   background-repeat: no-repeat;
    //   background-size: contain;
    //   background-position: 100% 100%;
    // }

    // .item-text {
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   width: 100%;
    //   padding: 16px;
    //   font-size: 18px;
    //   color: #fff;
    //   text-align: left;
    //   z-index: 2;
    //   font-family: $font500;

    //   @media (max-width: $mobileResponsiveMax) {
    //     font-size: 16px;
    //     padding: 24px 16px;
    //   }
    // }
  }
  .article-btn-row {
    overflow: hidden;
    a {
      float: left;

      width: 230px;
      height: 56px;
      line-height: 56px;

      @media (max-width: $mobileResponsiveMax) {
       float: none;
       width: 335px;
       height: 48px;
       line-height: 48px;
       font-family: $font500;
       font-weight: 500;
       font-size: 16px;
      }
    }
  }

}
