.header-clear-v3 {

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  height: 100px;

  @media (min-width: $tabletVMin) {
    height: 112px;
  }

  @media (min-width: $template1400) {
  }

}