.get-started-v3 {
  background-color: #17181B;
  border-radius: 48px;
  max-width: 1392px;
  margin: auto;
  margin-bottom: 15px;
  padding-top: 150px;
  padding-bottom: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 150px;
  overflow: hidden;
  transition: all .3s;

  @media (max-width: $mobileResponsiveMax) {
    width: calc(100% - 40px);
    margin-left: 20px;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 20px;
    margin-bottom: 48px;
  }

  position: relative;

  &:before {
    width: 774px;
    height: 816px;
    display: block;
    content: "";
    position: absolute;
    z-index: 0;
    background-image: url('/assets-v3/target.svg');
    background-repeat: no-repeat;
    background-size: 686px;
    background-position: 0px 0px;
    top: -105px;
    left: 70px;
    opacity: 0.3;

    @media (max-width: $mobileResponsiveMax) {
      width: 100%;
      height: 320px;
      background-size: contain;
      background-image: url('/assets-v3/target-accordeon-01.svg');
      opacity: 0.8;
      left: 0;
      top: 0px;
      background-size: cover;
      z-index: 1;
    }
  }

  .get-started--rows {
    width: 888px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 2;
    position: relative;
    
    @media (max-width: $mobileResponsiveMax) {
      
    }
  }

  .section-title--row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 100px;

    @media (max-width: $mobileResponsiveMax) {
      flex-direction: column-reverse;
      align-items: flex-start;
      margin-bottom: 44px;
      padding-left: 8px;
      padding-right: 18px;
    }
  }
  
  .section-title {
    font-size: 48px;
    line-height: 56px;
    color: #fff;
    text-align: left;
    font-family: $font600;
    font-weight: 600;
    
    @media (max-width: $mobileResponsiveMax) {
      font-size: 32px;
      line-height: 40px;
      br {
        display: none;
      }
    }
  }

  .section-title--tag {
    color: #CCD9E8;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    font-family: $font500;
    font-weight: 500;

    @media (max-width: $mobileResponsiveMax) {
      margin-bottom: 24px;
      font-size: 12px;
      line-height: 16px;
      br {
        display: none;
      }
    }
  }

  .get-started-columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 24px;
    margin-bottom: 44px;

    @media (max-width: $mobileResponsiveMax) {
      flex-direction: column;
      height: auto;
      margin-bottom: 24px;
    }

    .item {
      width: 50%;
      border-radius: 32px;
      padding-left: 32px;
      padding-right: 32px;
      padding-top: 40px;
      padding-bottom: 40px;
      background-color: #232528;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      position: relative;

      @media (max-width: $mobileResponsiveMax) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 32px;
        padding-top: 32px;
      }

      .top-row {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border-bottom: 1px dashed #fff;
        padding-bottom: 24px;
        margin-bottom: 24px;
        min-height: 380px;

        @media (max-width: $mobileResponsiveMax) {
          min-height: 80px;
        }

        .item-title {
          color: #fff;
          font-size: 24px;
          line-height: 32px;
          text-align: left;
          margin-bottom: 28px;
          font-family: $font500;
          font-weight: 500;

          @media (max-width: $mobileResponsiveMax) {
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 24px;
          }
  
        }

        .item-subtitle {
          color: #9CA3AF;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 24px;
          font-family: $fontComissioner;

          @media (max-width: $mobileResponsiveMax) {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 24px;
          }

        }

        .price-block {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          justify-content: flex-start;
          margin-bottom: 31px;

          @media (max-width: $mobileResponsiveMax) {
            margin-bottom: 24px;
          }

          .price-text {
            color: #9CA3AF;
            font-size: 12px;
            line-height: 16px;
            margin-right: 6px;
            text-transform: uppercase;
            font-family: $font500;
            font-weight: 500;
            position: relative;
            top: -3px; 

            @media (max-width: $mobileResponsiveMax) {
              font-size: 12px;
              line-height: 16px;
              margin-right: 18px;
            }

          }
          .price {
            font-size: 32px;
            line-height: 40px;
            color: #FE8798;
            font-family: $font600;
            font-weight: 600;

            @media (max-width: $mobileResponsiveMax) {
              font-size: 32px;
              line-height: 40px;
            }
          }
        }

        .desc {
          color: #FFFFFF;
          font-size: 16px;
          line-height: 24px;
          text-align: left;
          font-family: $fontComissioner;

          @media (max-width: $mobileResponsiveMax) {
            font-size: 14px;
            line-height: 20px;
          }
        }

      }

      .bottom-row {
        transition: all .3s;
        height: 368px;
        overflow: hidden;

        @media (max-width: $mobileResponsiveMax) {
          height: 208px;
        }

        .for-title {
          color: #FFFFFF;
          font-size: 16px;
          line-height: 24px;
          text-align: left;
          margin-bottom: 16px;
          font-family: $font600;
          font-weight: 600;

          @media (max-width: $mobileResponsiveMax) {
     
          }
        }
        .for{
          color: #FFFFFF;
          font-size: 16px;
          line-height: 24px;
          text-align: left;
          padding-left: 24px;
          position: relative;
          font-family: $fontComissioner;
          font-weight: normal;

          @media (max-width: $mobileResponsiveMax) {
     
          }
          img {
            width: 16px;
            height: 16px;
            display: block;
            position: absolute;
            top: 0;
            left: 0;

            @media (max-width: $mobileResponsiveMax) {
     
            }
          }
        }
      }

      .button-row {
        position: relative;
        z-index: 2;
        background-image: url('/assets-v3/get-started--fader.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 100% 100%;
        height: 338px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding: 40px 32px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 3;

        @media (max-width: $mobileResponsiveMax) {
          height: 280px;
        }

        .togglr {
          color: #9CA3AF;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 22px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-end;
          cursor: pointer;
          font-family: $fontComissioner;

          @media (max-width: $mobileResponsiveMax) {
     
          }

          span {

            &.see-all {
              display: block;
            }
            &.see-less {
              display: none;
            }
          }

          img {
            width: 48px;
            height: 48px;
            margin-bottom: 8px;
        

            @media (max-width: $mobileResponsiveMax) {
     
            }
          }
        }
        .button {
          margin: 0;
          width: 100%;
          border-radius: 24px;
          height: 80px;
          line-height: 80px;
          font-size: 16px;
          font-family: $font600;
          font-weight: 600;

          @media (max-width: $mobileResponsiveMax) {
            height: 56px;
            line-height: 56px;
            width: 180px;
            border-radius: 20px;
            font-family: $font500;
            font-weight: 500;
            font-size: 14px;
          }
        }
      }

      &.full {
        padding-bottom: 250px;

        @media (max-width: $mobileResponsiveMax) {
          padding-bottom: 250px;
        }

        .bottom-row {
          height: auto;

          @media (max-width: $mobileResponsiveMax) {
            height: auto;
          }
          
        }

        .button-row {
          background-image: none;

          .togglr {
            span {

              &.see-all {
                display: none;
              }
              &.see-less {
                display: block;
              }
            }
          }
        }
      
      } 

    }
  }

  .section-note--row {
    text-align: center;
    color: #fff;
    font-size: 24px;
    line-height: 32px;
    font-family: $font600;
    font-weight: 600;

    @media (max-width: $mobileResponsiveMax) {
      text-align: left;
      font-size: 20px;
      line-height: 28px;
      padding-left: 8px;
      padding-right: 8px;
    }
  }

 }
