
.hero-form-v32 {
 
  background-color: #F1F3F5;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 552px;
  height: 544px;
  border-radius: 44px;
  // padding: 60px;


  padding: 32px 40px 16px 40px;

  @media (max-width: $mobileResponsiveMax) {
    width: 100%;
    // height: 464px;
    height: auto;
    border-radius: 16px;
    // padding: 24px 16px;
    margin-bottom: 24px;

    padding: 32px 0px 16px 0px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin: auto;
    padding: 0;
    left: 0;

    width: 432px;

    padding-top: 8px;

    @media (max-width: $mobileResponsiveMax) {
      width: 302px;
      margin-bottom: 8px;
    }

    .fields {
      margin-bottom: 22px;
      width: 100%;

      @media (max-width: $mobileResponsiveMax) {
    
      }
    }

    .button-row {
      width: 100%;

      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;

      padding-top: 0;

      @media (max-width: $mobileResponsiveMax) {
    
      }

      .button {

        @media (min-width: $tabletVMin) {
          width: 220px;
          height: 56px;
        }

        @media (max-width: $mobileResponsiveMax) {
          // width: 210px;
          width: 280px;
          height: 46px;
        }
      }
    }

    .input-title {
      color: #6C6D78;
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 8px;
      font-family: $fontComissioner;
      font-weight: normal;

      @media (max-width: $mobileResponsiveMax) {
    
      }
    }

    .input-block {
      background-color: #fff;
      border: 1px solid #E4E7EA;
      border-radius: 12px;
      overflow: hidden;
      height: 56px;
      width: 100%;
      margin-bottom: 25px;

      @media (max-width: $mobileResponsiveMax) {
    
      }
    }

    input {
      height: 100%;
      width: 100%;
      border: mome;
      outline: none;
      background-color: transparent;
      color: #17181B;
      font-family: $fontComissioner;
      font-weight: normal;

      @media (max-width: $mobileResponsiveMax) {
        padding-left: 24px;
      }
    }
  }

  .hero-form-result {
    width: 100%;
    height: 100%;

    display: none;

    @media (max-width: $mobileResponsiveMax) {
      width: 100%;
    } 

    h3 {
      font-family: $font700;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #0E1924;
      margin-bottom: 30px;

      @media (max-width: $mobileResponsiveMax) {
        font-size: 20px;
        line-height: 30px;
      } 
    }

    .hero-form-book {
      width: 405px;
      height: 403px;
      margin: auto;

      @media (max-width: $mobileResponsiveMax) {
        width: 280px;
        height: 278px;
      } 

      img {
        width: 100%;

        @media (max-width: $mobileResponsiveMax) {
      
        }
      }
    }
  }


}

.congrats-op-v32 {
  .hero-form-v32 {
    form {
      .button-row  {
        .button {
          width: 305px;
        }
      }
    }
  }
}