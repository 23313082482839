.footer-small-v3 {
    padding-bottom: 48px;
  
    @media (max-width: $mobileResponsiveMax) {
  
      padding-bottom: 20px;
    }
  
    .footer-small--content {
  
      padding: 90px 114px;
      max-width: 1344px;
      margin: auto;
      border-radius: 48px;
  
      background-color: #17181B;
  
      @media (max-width: $mobileResponsiveMax) {
        padding: 32px 16px;
        width: calc(100% - 40px);
        margin: auto;
        border-radius: 20px;
      }
  
      .container {
        margin: auto;
  
      }
    
    
      .row-1  {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 48px;
        margin-bottom: 64px;
       
  
        @media (max-width: $mobileResponsiveMax) {
          justify-content: space-between;
          width: 288px;
          margin: auto;
          padding-bottom: 24px;
          margin-bottom: 24px;

          border-bottom: 1px solid #232528;
        }
  
        .col-1,
        .col-2{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 48px;
  
          @media (max-width: $mobileResponsiveMax) {
            flex-direction: column;
            gap: 24px;
          }
        }
  
        a {
          color: #9CA3AF;
          font-size: 16px;
          line-height: 24px;
          text-decoration: none;
          font-family: $font600;
          font-weight: 600;
  
          &:hover {
            text-decoration: underline;
          }
  
          @media (max-width: $mobileResponsiveMax) {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

      .row-2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        justify-content: center;
        gap: 24px;
        padding-bottom: 48px;
        margin-bottom: 48px;

        border-bottom: 1px solid #232528;

        @media (max-width: $mobileResponsiveMax) {
        //   flex-direction: column;
          flex-direction: row;
          gap: 24px;
          padding-bottom: 24px;
          margin-bottom: 24px;
        }

        .col-1,
        .col-2{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 48px;
  
          @media (max-width: $mobileResponsiveMax) {
            flex-direction: column;
            gap: 24px;
          }
        }

        .a--footer__soc {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 24px;
            font-size: 20px;
            line-height: 28px;
            color: #9CA3AF;
            text-decoration: none;
  
            @media (max-width: $mobileResponsiveMax) {
              gap: 24px;
              font-size: 16px;
              line-height: 24px;
              font-family: $font600;
              font-weight: 600;
            }
  
            &:hover {
              text-decoration: underline;
            }
  
            img {
              width: 9px;
              height: 9px;
            }
          }
      }

      .row-2-2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        justify-content: center;
        gap: 24px;
        padding-bottom: 48px;
        margin-bottom: 48px;

        border-bottom: 1px solid #232528;

        @media (max-width: $mobileResponsiveMax) {
        //   flex-direction: column;
          flex-direction: row;
          gap: 24px;
          padding-bottom: 24px;
          margin-bottom: 24px;

          justify-content: flex-start;
          align-items: flex-start;
        }

        .col-1,
        .col-2{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 48px;
  
          @media (max-width: $mobileResponsiveMax) {
            flex-direction: column;
            gap: 24px;

            

            &.col-1{ 
              // align-items: flex-end;
              align-items: flex-start;
            }
            &.col-2{
              // align-items: flex-end;
              align-items: flex-start;
             }
          }
        }

        .a--footer__norm {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 24px;
            font-size: 20px;
            line-height: 28px;
            color: #9CA3AF;
            text-decoration: none;
  
            @media (max-width: $mobileResponsiveMax) {
              gap: 24px;
              font-size: 16px;
              line-height: 24px;
            }
  
            &:hover {
              text-decoration: underline;
            }
  
            img {
              width: 9px;
              height: 9px;
            }
          }
      }

      .row-3  {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
  
        // color: #fff;
        color: #9CA3AF;
        font-size: 14px;
        text-align: center;

        opacity: 0.5;
      
  
        @media (max-width: $mobileResponsiveMax) {
            font-size: 12px;

        }
      }


      .row-1  {
        display: none!important
      }
      .row-2  {
        display: none!important;
      }
      .row-2-2  {}
      .row-3  {}
    }
  } 
  