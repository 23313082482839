.hero-home-v32 {
  overflow: hidden;
  position: relative;

  max-width: 1344px;
  height: 864px;
  margin: auto;

  margin-top: 24px;
  // margin-bottom: 80px;
  margin-bottom: 35px;

  padding-top: 216px;

  background-image: url('/assets-v3-2/hero-v4.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 100%;
  position: relative;


  @media (max-width: $mobileResponsiveMax) {
    // width: 100%;
    width: calc(100% - 40px);
    height: auto;
    margin: auto;
    padding: 0 20px;
    background-image: url('/assets-v3-2/hero-v4--mobile.jpg');
  }

  @media (max-width: $mobileResponsiveMax) {
      padding-top: 32px;
      // padding-top: 92px;
      margin-top: 60px;
      border-radius: 16px;
      overflow: hidden;
      // padding-bottom: 32px;
      padding-bottom: 8px;
      margin-bottom: 48px;
  }


  .hero-text {
    color: white;
    width: 100%;
    padding-left: 44px;
    width: 920px;
    
    z-index: z('hero-text');

    @media (max-width: $mobileResponsiveMax) {
      position: static;
      top: 0;
      padding-left: 0;
      width: 100%;
    }

    .subtitle {
      font-size: 32px;
      line-height: 41px;
      margin-bottom: 24px;
      font-family: $font600;
      font-weight: 600;

      @media (max-width: $mobileResponsiveMax) {
        font-size: 24px;
        line-height: 36px;
        text-align: left;
        margin-bottom: 24px;
        padding-left: 0;
      }
  
    }

    .button {

    }
    
  }

  
  .hero-btn-row {
    display: flex; 
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 305px;

    @media (max-width: $mobileResponsiveMax) {

    }
  }

  .title {
    font-size: 48px;
    line-height: 62px;
    margin-bottom: 24px;
    font-family: $font600;
    font-weight: 600;
    color: white;

    @media (max-width: $mobileResponsiveMax) {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 24px;
      text-align: left;
    }

    &.mobile--only {
      display: none;

      @media (max-width: $mobileResponsiveMax) {
        display: block;
      }
    }

    &.desktop--only {
      display: block;

      @media (max-width: $mobileResponsiveMax) {
        display: none;
      }
    }

  }

  .hero-form {
      display: none;
  }

  &.with-form {

    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: center;

    gap: 44px;

    @media (min-width: $tabletVMin) {
      padding-top: 206px;
    }

    @media (max-width: $mobileResponsiveMax) {
      flex-direction: column;
      justify-content: flex-start;
      gap: 0px;

      width: 100%;
    }

    .hero-text {
      width: 655px;

      @media (min-width: $tabletVMin) {
        padding-left: 0;
        padding-right: 30px;
      }

      @media (max-width: $mobileResponsiveMax) {
        width: 100%;
      }
      
    }
    .hero-form-show {
      display: none;
    }
    .hero-form {
      display: block;
    }
  }

  // ! Finish page - Congrats
  &.hero-finish {
    flex-direction: row-reverse;

    background-image: url('/assets-v3-2/hero-v4--02.jpg');

    @media (min-width: $tabletVMin) {
      padding-top: 206px;
    }

    @media (max-width: $mobileResponsiveMax) {
      flex-direction: column-reverse;
      background-image: url('/assets-v3-2/hero-v4--02--mobile.jpg');

      margin-top: 60px; 
    }

    .title {
      font-size: 32px;
      line-height: 41px;
      padding-top: 60px;
      font-family: $font600;
      font-weight: 600;

      @media (max-width: $mobileResponsiveMax) {
       font-size: 32px;
       line-height: 40px;
       margin-bottom: 24px;
       text-align: left;
       padding-top: 0;
       padding-top: 8px;
       padding-right: 10px;
      }
    }
  }

  &.no-op {
    padding-top: 48px;

    @media (min-width: $tabletVMin) {
      padding-top: 0px;
    }
    .hero-text {
      height: 100%;
      width: 100%;
      display: flex; 
      flex-direction: row; 
      align-items: center; 
      justify-content: center;


      @media (max-width: $mobileResponsiveMax) {
        display: block;
        // padding-top: 48px;
        padding-bottom: 110px;
      }

      h1 {
        text-align: center;
        padding: 18px 32px 30px;
        background-image: url('/assets-v3-2/no-op.svg');
        background-repeat: no-repeat;
        background-size: contain;
        font-family: $font600;
        font-weight: 600;
        color: #000000;
        font-size: 48px;
        line-height: 62px;
        width: 1164px;
        height: 250px;


        @media (max-width: $mobileResponsiveMax) {
          font-size: 32px;
          line-height: 40px;
          text-align: left;
          color: #fff;
          background-image: none;
          padding: 0;

          height: auto;
          width: auto;
        }
      }
    }
  }

  // .hero-video {
  //   display: block;
  //   // display: none;
  //   width: 100%;
  //   position: relative;

  //   @media (max-width: $mobileResponsiveMax) {
        
  //   }



  //   video {
  //     width: 100%;
  //     display: block;

  //     @media (max-width: $mobileResponsiveMax) {
        
  //     }
  
  //   }

  //   .hero-video-fader {
  //     display: block;
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     z-index: z('hero-video-fader');
  //     width: 100%;
  //     height: 100%;
  //     background-color: rgba(0, 0, 0, 0.5);

  //     @media (max-width: $mobileResponsiveMax) {
        
  //     }
  
  //   }
  // }
}

