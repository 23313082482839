.copy-tooltip {
    display: none;
    background-color: $color-theme-2;
    border-radius: 8px;
    color: $color-white;
    position: fixed;
    top: 125px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 101;
    padding: 10px 15px;
    max-width: 800px;
    width: 100%;
    font-size: 16px;
    font-family: $font500;
    font-weight: 500;
    opacity: 1;
    &.active {
        display: block;
    }
}