.mobile-menu-opener {
  width: 20px;
  height: 20px;
  position: fixed;
  top: 30px;
  right: 20px;

  @media (min-width: $template1400) {
    display: none !important;
  }
}

.mobile-menu-closer {
  cursor: pointer;
  float: right;
  width: 30px !important;
  height: 30px !important;

  @media (min-width: $template1400) {
    display: none !important;
  }
}

#mobile-menu,
.mobile-menu {
  // display: block;
  display: none;

  @media (min-width: $template1400) {
    display: none !important;
  }

  z-index: 1000;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;

  .mm-fader {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 1;
  }
  .mm-menu {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 30px 20px;
  }
  .mm-header {
    display: flex;
    height: 50px;
    flex-grow: 0;
    width: 100%;
    justify-content: flex-end;
  }
  .mm-footer {
    display: flex;
    height: 50px;
    flex-grow: 0;
  }
  .mm-list {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    // align-items: flex-start;
    align-items: center;
    justify-content: center;

    a {
      display: block;
      margin: 0 10px;
      text-decoration: none;
      color: #fff;
      font-family: $font700;
      font-weight: 700;
      min-height: 30px;
      margin-bottom: 20px;
      font-size: 16px;
    }
  }
}
