.header-full-v3 {

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  height: 108px;
  display: flex;
  align-items: center;


  // background-color: white;

  @media (max-width: $mobileResponsiveMax) {
    height: 48px;
  }

  &.default {
    background-color: #fff;
  }

  .header-full-content {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1400px;
 
    margin: auto;

    @media (max-width: $mobileResponsiveMax) {
      border-top: 20px solid white;
    }


    .logo {
      // width: 203px;
      float: left;
      // margin-right: 48px;
      padding-right: 48px;

      width: calc(203px + 48px + 8px);
      background-color: white;
      padding-left: 8px;
      height: 108px;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      @media (max-width: $mobileResponsiveMax) {
        // width: 150px;
        padding-right: 20px;
        width: calc(150px + 20px + 20px);
        
        padding-top: 8px;
        height: 48px;
        padding-left: 20px;
        position: relative;
        top: -20px;

        // border-radius: 0 0 24px 0 ;
        border-radius: 0 0 14px 0 ;
      }
  
      img {
        width: 100%;
      }
    }
  }
  

  .menu-row {

    width: calc(100% - 252px);

    height: 108px;

    float: left;
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    justify-content: flex-start;
    // background-color: white;

    @media (max-width: $mobileResponsiveMax) {
        display: none;
    }

    .first {
      display: flex;
      flex-direction: row;
      gap: 24px;
      justify-content: flex-end;
      align-items: center;
      flex-shrink: 0;

      background-color: white;
      padding-right: 200px;
      border-radius: 0 0 24px 0;

      @media (max-width: $mobileResponsiveMax) {
        
      }

      a {
        color: #6C6D78;
        font-size: 16px;
        font-family: $font600;
        font-weight: 600;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }

        @media (max-width: $mobileResponsiveMax) {
        
        }
      }
    }

    .middle {
      width: 100%;
      flex-shrink: 1;
      flex-grow: 1;
      display: flex;
      border-top: 24px solid white; 
      // background-color: red;

      .radiuser {
        // width: 24px;
        // height: 24px;
        width: 26px;
        height: 26px;
        position: relative;
        z-index: 1;
        transform: rotate(90deg);
        background-image:
        radial-gradient(circle at 100% 0, rgba(0,0,0,0) 23px, #fff 24px);
        // radial-gradient(circle at 100% 0, rgba(0,0,0,0) 23px, #f00 24px);
      }

      .middle-1 {
        width: 50px;
        height: 50px;
        flex-shrink: 0;

        .radiuser {
          left: -2px;
          top: -2px;
        }

      }
      .middle-2 {
        width: 100%;
        flex-shrink: 1;
        flex-grow: 1;
        display: flex;
      }
      .middle-3 {
        width: 50px;
        height: 50px;
        flex-shrink: 0;


        .radiuser {
          transform: rotate(180deg);
          float: right;

          right: -2px;
          top: -2px;
        }
        

      }
    }
    .last { 
      width: calc(218px + 8px);
      display: flex;
      flex-direction: row;
      padding-left: 24px;
      padding-right: 8px;
      border-radius: 0 0 0 24px;
      background-color: white;
      flex-shrink: 0;

      @media (max-width: $mobileResponsiveMax) {
        
      }
     
      a {
        height: 56px;

        @media (max-width: $mobileResponsiveMax) {
        
        }
      }
    }
    
  }
}