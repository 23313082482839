.sw > .tab-content {

    @media screen and (min-width: $tabletVMin) {
    
    }

    @media screen and (min-width: $template1400) {

    }

    @media screen and (max-height:  $mobileResponsiveMax) { 

    }

    @media screen and (max-height: $quizSmallScreenHeight) { 

    }

}


.sw-theme-progress {
  height: 100%;

  min-height: 800px;

  @media (min-width: $template1400) {
    display: flex;
    align-items: center;
    background-color: $color-page-bg;
  }

  @media screen and (max-width: $mobileResponsiveMax) {
    min-height: 600px !important;
  }

  @media screen and (max-height: $quizSmallScreenHeight) {
    // min-height: 500px !important;
    min-height: 600px !important;
  }

  .page-wrapper {
    @media (min-width: $template1400) {
      width: 100%;
    }
  }
}



// ! Small screen height Start
.sw > .tab-content {
  height: calc(100% - 30px) !important;

}

@media screen and (max-height: $quizSmallScreenHeight) {
  .sw > .tab-content {
    // min-height: $quizSmallScreenHeight !important;
    min-height: 500px !important;
  } 
}


#quiz-wizard {
  @media screen and (max-height: $quizSmallScreenHeight) {

    .page-step--logo {
      height: 50px;

      .logo-01 {
        width: 180px;
        background-size: 180px;
        height: 30px;
      }
    }

    .page-step--logo--under {
      margin-top: 10px;
    }

    

   
  }
}

.page-step {
  @media screen and (max-height: $quizSmallScreenHeight) {
    .action-row {
      // margin-bottom: 0px;
      // height: 0px;
    }

    .button-row {
      .button {
        z-index: 5000;
        position: relative;
      }
    }

    form {
      margin-bottom: 0px;

      .input-block {
        height: 52px;
        line-height: 52px;

        input[type=radio] + label:before {
          top: 18px;
        }

        input[type=radio]:checked + label:before {
          top: 17px;
        }
      }

      
    }
  }
}

// ! Small screen height End

// remove red banner from quiz-page
.quiz-page {
  .urgent-msg {
    display: none;
  }
}
