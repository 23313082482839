// Noto Sans
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600;700&display=swap');

// Commissioner
@import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@400;500;600&display=swap');

@font-face {
  font-family: 'PoppinsRegular';
  src: url('/fonts/Poppins/Poppinsregular.woff') format('woff'),
    url('/fonts/Poppins/Poppinsregular.woff2') format('woff'),
    url('/fonts/Poppins/Poppinsregular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
} 

@font-face {
  font-family: 'PoppinsBold';
  src: url('/fonts/Poppins/Poppinsbold.woff') format('woff'),
    url('/fonts/Poppins/Poppinsbold.woff2') format('woff'),
    url('/fonts/Poppins/Poppinsbold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'PoppinsSemiBold';
  src: url('/fonts/Poppins/Poppinspoppins-boldsemibold.woff') format('woff'),
    url('/fonts/Poppins/Poppinspoppins-boldsemibold.woff2') format('woff'),
    url('/fonts/Poppins/Poppinspoppins-boldsemibold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'PoppinsMedium';
  src: url('/fonts/Poppins/Poppinspoppins-semiboldmedium.woff') format('woff'),
    url('/fonts/Poppins/Poppinspoppins-semiboldmedium.woff2') format('woff'),
    url('/fonts/Poppins/Poppinspoppins-semiboldmedium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'PoppinsLight';
  src: url('/fonts/Poppins/Poppinslight.woff') format('woff'),
    url('/fonts/Poppins/Poppinslight.woff2') format('woff'),
    url('/fonts/Poppins/Poppinslight.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
}