.menu-mobile-opener-v3 {
  // width: 20px;
  // height: 20px;

  width: 34px;
  height: 54px;

  border-radius: 0 0 0 14px;
  background-color: #fff;

  position: fixed;
  // top: 30px;
  padding-top: 20px;
  // top: 16px;
  top: -4px;
  right: 20px;
  z-index: z('menu-mobile-opener');


  // display: none !important;

  img {
    width: 24px;
    height: 24px;
    position: relative;
    left: 10px;
    top: -2px;
    cursor: pointer;

  }

  @media (min-width: $template1400) {
    display: none !important;
  }

  @media (max-width: $mobileResponsiveMax) {
  
  }
}

.menu-mobile-close-v3{
  cursor: pointer;
  float: right;
  width: 30px !important;
  height: 30px !important;
  z-index: z('menu-mobile-close');

  img {
    width: 24px;
    height: 24px;
    position: relative;
    right: -7px;
    top: 5px;
  }

  @media (min-width: $template1400) {
    display: none !important;
  }

}

.menu-mobile-v3 {
  // display: block;
  display: none;

  @media (min-width: $template1400) {
    display: none !important;
  }

  z-index: 1000;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;

  .menu-mobile--top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    height: 48px;
    // border-bottom: 1px solid #b4b0b0;
    width: calc(100% - 40px);
    margin: auto;

    z-index: 20;
    position: relative;

    &:after {
      width: 100%;
      height: 1px;
      background-color: #DFE4EA;
      content: "";
      display: block;
      bottom: -5px;
      left: 0;
      position: absolute;
    }

    .logo-mm {
      display: flex!important;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      // padding-right: 20px;
      // width: calc(150px + 20px + 20px);
      padding-top: 8px;
      height: 48px;
      // padding-left: 20px;
      z-index: 2;
      position: relative;

      img {
        width: 150px;
      }

    }
  }

  .menu-mobile--bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 20px;

    z-index: 20;
    // position: relative;

    height: 84px;
    width: calc(100% - 40px);
    margin: auto;

    padding-bottom: 24px;
    padding-top: 20px;


    &:after {
      width: 100%;
      height: 1px;
      background-color: #DFE4EA;
      content: "";
      display: block;
      top: -5px;
      left: 0;
      position: absolute;

    
    }

    .soc-mm {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
     

      padding-top: 8px;
      height: 40px;
      width: 184px;

      a {
          width: 40px;
          height: 40px;

          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          border-radius: 8px;
          border: 1px solid #DFE4EA;
          background-color: #F1F3F5;

          img {
            width: 24px;
          }
      }

    

    }
  }

  .menu-mobile-fader{
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    // background-color: rgba(0, 0, 0, 0.9);
    // background-color: #e4e7ea;
    background-color: #F1F3F5;
    z-index: 1;
    z-index: z('menu-mobile-fader');
  }
  .menu-mobile-wrap {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 30px 20px;
  }

  .menu-col {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: z('menu-col');

    a {
      display: block;
      margin: 0 10px;
      text-decoration: none;
      color: #17181B;
      min-height: 30px;
      margin-bottom: 32px;
      font-size: 24px;

      font-weight: 500;
    }

    .button {
      color :#fff;
      font-size: 14px;
      border-radius: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 6px; 
      height: 48px;
      width: 288px;
      margin-top: 34px;
    }
  }
}
