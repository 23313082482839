.header-transparent {
  position: relative;
}
.header-transparent {
  &.white {
    background-color: #fff;
  }
  height: 50px;
  height: 50px;
  position: relative;
  margin-bottom: -50px;
  width: 100%;
  z-index: z('header-transparent');
  @media (min-width: $tabletVMin) {
    height: 80px;
    position: relative;
    margin-bottom: -80px;
  }
  @media (min-width: $template1400) {
    height: 110px;
    margin-bottom: -110px;
  }
  .header-transparent-content {
    height: 70px;
    display: flex;
    align-items: center;
    @media (min-width: $tabletVMin) {
      height: 80px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    @media (min-width: $template1400) {
      height: 110px;
    }
    .logo-header-transparent {
      float: left;
      width: 198px;
      height: 30px;
      background-image: url('/images/2023/logo_04.svg');
      background-position: 50% 0px;
      background-size: contain;
      background-repeat: no-repeat;
      position: relative;
      top: 10px;
      &.light {
        background-image: url('/images/2023/logo_03.svg');
      }
      @media (min-width: $tabletVMin) {
        position: static;
        top: 0;
      }
      @media (min-width: $template1400) {
        width: 280px;
        height: 44px;
        background-position: 0px 0px;
      }
    }
    .menu-row {
      display: flex;
      align-items: center;
      a {
        display: none;
        @media (min-width: $tabletVMin) {
          display: flex;
          align-items: center;
          margin-left: 22px;
          font-size: 18px;
          text-decoration: none;
          text-transform: uppercase;
          color: black;
          white-space: nowrap;
          font-family: $font500;
          font-weight: 500;
          &.active,
          &:hover {
            color: $color-theme;
          }
        }
        @media (min-width: $template1400) {
          margin-left: 28px;
        }
      }
      &.home {
        a {
          @media (min-width: $tabletVMin) {
            color: white;
          }
          &:hover {
            color: $color-theme;
          }
        }
      }
    }
    .button {
      float: right;
      height: 41px;
      line-height: 41px;
      border-radius: 3px;
      padding-left: 17px;
      padding-right: 17px;
      &,
      &:hover {
        color: white !important;
      }
      @media (min-width: $tabletVMin) {
        font-stretch: normal;
        font-style: normal;
        position: relative;
      }
      @media (min-width: $template1400) {
        height: 36px;
        line-height: 36px;
        padding-left: 22px;
        padding-right: 22px;
      }
    }
    .menu-mobile-opener {
      width: 30px;
      height: 30px;
      background-image: url('/ico/menu.svg');
      // light only for the home page
      // background-image: url('/ico/menu-light.svg');
      background-repeat: no-repeat;
      background-position: 100% 50%;
      background-size: contain;
      background-size: 30px;
      position: relative;
      z-index: z('menu-mobile-opener');
      cursor: pointer;
      position: absolute;
      right: 20px;
      top: 30px;
      @media (min-width: $tabletVMin) {
        display: none !important;
      }
    }

    .menu-mobile-close {
      width: 30px;
      height: 30px;
      background-image: url('/ico/close-lite.svg');
      background-repeat: no-repeat;
      background-position: 50% 100%;
      background-size: contain;
      background-size: 30px;
      position: relative;
      z-index: z('menu-mobile-close');
      position: absolute;
      right: 20px;
      top: 30px;
      cursor: pointer;
      @media (min-width: $tabletVMin) {
        display: none !important;
      }
    }
    .menu-mobile {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      @media (min-width: $tabletVMin) {
        display: none !important;
      }
      .menu-mobile-wrap {
        position: relative;
        width: 100%;
        height: 100%;
      }
      .menu-mobile-fader {
        background-color: rgba(0, 0, 0, 0.9);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: z('menu-mobile-fader');
      }
      .menu-col {
        padding: 50px;
        position: relative;
        z-index: z('menu-col');
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        a {
          color: #fff;
          line-height: 30px;
          text-decoration: none;
          text-transform: uppercase;
          margin-bottom: 20px;
          &.active,
          &:hover {
            color: $color-theme;
          }
        }
        .button {
          float: none;
          height: 41px;
          line-height: 41px;
          font-size: 16px;
          border-radius: 6px;
          font-stretch: normal;
          font-style: normal;
          width: auto;
          padding-left: 0;
          padding-right: 0;
          margin: 0 -20px 20px;
          max-width: calc(100% + 40px);
          &,
          &:hover {
            color: white !important;
          }
        }
      }
    }
  }
}

.menu-mobile {
  .link-button {
    margin: 0 auto;
  }
}

.header-transparent.home .header-transparent-content .menu-mobile-opener {
  background-image: url('/ico/menu-light.svg');
}

body.scroll-off {
  overflow: hidden !important;
}

.select-course-pu-version {
  .header-transparent {
    .header-transparent-content {
      .logo-header-transparent {
        &.light {
          background-image: url('/images/2023/logo_04.svg');
        }
      }
    }
  }
}

// header dropdown
.desktop-wide-wrap {
  &.dropdown-wrap {
    padding-top: 10px;
    padding-bottom: 10px;
    @media (min-width: $tabletVMin) {
      position: absolute;
      top: 75px;
      right: 0;
      left: 0;
    }
    .sign-up-dropdown {
      display: none;
      max-width: max-content;
      flex-direction: column;
      background-color: $color-important-bg;
      padding: 10px;
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      margin: 0 0 0 auto;
      @media (min-width: $tabletVMin) {
        padding: 20px 10px;
      }
      @media (min-width: $template1400) {
        right: 7vw;
      }
      &.open {
        display: flex;
      }
      a.button {
        max-width: none;
        margin: 0;
        &+.button {
          margin: 10px 0 0;
        }
      }
      .link-button {
        margin: 10px auto 0;
      }
    }
  }
}
.header-transparent,
.header-first-page {
  .desktop-wide-wrap {
    &.dropdown-wrap {
      @media (max-width: $mobileResponsiveMax) {
        display: none;
      }
    }
  }
}
.menu-row {
  .button {
    &.active {
      .arrow-ico {
        transform: rotate3d(1, 0, 0, 180deg);
      }
    }
    .arrow-ico {
      margin-left: 5px;
      transition: all 0.2s ease-out;;
    }
  }
}