.tabs-v3 {
  width: 100%;
  // overflow: hidden;
  clear: both;

  .titles {
    display: flex;

    .title {
      height: 20px;
      text-align: center;
      margin-bottom: 13px;
      color: #000;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 100%;
      padding: 0 24px;

      @media (min-width: $tabletVMin) {
        font-size: 17px;
      }

      &.current {
        font-family: $font600;
        font-weight: 600;
      }

      &.title2 {
        width: 50%;
      }
      &.title3 {
        width: 33.33%;
      }

      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }

  .cols {
    display: flex;

    .col {
      display: flex;
      height: 6px;
      background-color: $color-tab;

      &.col2 {
        width: 50%;
      }
      &.col3 {
        width: 33.33%;
      }

      &.current {
        background-color: $color-tab-current;
      }

      &.passed {
        background-color: $color-tab;
        position: relative;

  
        &:before {
          content: '';
          display: block;
          width: 70px;
          height: 70px;
          background-image: url('/ico/tab-checked.svg');
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
          left: calc(50% - 35px);
          top: calc(50% - 32px);
        }

        @media (min-width: $template1400) {
            &:before {
              content: '';
              display: block;
              width: 86px;
              height: 86px;
              background-image: url('/ico/tab-checked.svg');
              background-size: contain;
              background-repeat: no-repeat;
              position: absolute;
              left: calc(50% - 43px);
              top: calc(50% - 40px);
            }   
        }
      }
    }
  }
}
