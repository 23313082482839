form {
  padding: 0;
  // overflow: hidden;
  position: relative;
  left: -24px;
  width: calc(100% + 48px);
  padding-left: 24px;  
  padding-top: 17px; 

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }

  select,
  textarea,
  input {
    &,
    &:hover,
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }

  .textarea-block {
    overflow: hidden;
    width: 100%;
    padding: 0;
    width: calc(100% - 24px);
    box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    margin-bottom: 17px;
    font-size: 15px;
    color: $color-input;

    @media (min-width: $tabletVMin) {
      // font-size: 18px;
      font-size: 17px;
    }

    textarea {
      &,
      &.focus,
      &.active {
        border: none;
        width: 100%;
        height: 150px;
        padding: 16px;
        outline: none;

        @media (min-width: $tabletVMin) {
          // font-size: 18px;
          font-size: 17px;
        }
      }
    }
  }

  .input-title {
    display: block;
    margin-bottom: 9px;
    font-family: $font700;
    font-weight: 700;
    font-size: 13px;

    @media (min-width: $tabletVMin) {
      font-size: 16px;
    }
  }

  .alert.alert-danger {
    font-size: 11px;
    color: #d8374f;
    padding-top: 5px;
    position: relative;
    top: -17px;

    @media (min-width: $tabletVMin) {
      font-size: 12px;
      top: -15px;
      padding-left: 9px;
    }
  }

  .select-block,
  .input-block {
    overflow: hidden;
    width: 100%;
    padding: 0;
    width: calc(100% - 24px);
    height: 62px;
    box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    margin-bottom: 17px;
    line-height: 62px;
    padding-left: 10px;
    padding-right: 20px;
    font-size: 15px;
    color: $color-input;
    border-radius: 6px;

    // todo: check this
    border: 1px solid rgba(0, 0, 0, 0.03);

    @media (min-width: $tabletVMin) {
      // font-size: 18px;
      font-size: 17px;
    }

    select {
      &,
      &.focus,
      &.active {
        color: $color-input;
        border: none;
        width: 100%;
        height: 100%;
        display: block;
        width: 100%;
        border: none;
        padding: 16px;
        outline: none;
        font-size: 14px;

        @media (min-width: $tabletVMin) {
          font-size: 17px;
        }
      }
    }

    input[type='email'],
    input[type='tel'],
    input[type='date'],
    input[type='number'],
    input[type='text'] {
      &,
      &.focus,
      &.active {
        border: none;
        width: 100%;
        height: 100%;
        display: block;
        border: none;
        padding: 16px;
        outline: none;
        font-size: 14px;

        @media (min-width: $tabletVMin) {
          font-size: 17px;
        }
      }
    }

    input[type='date'] {
      &,
      &.focus,
      &.active {
        width: calc(100% + 20px);
        -webkit-appearance: none;
        // -webkit-appearance: textfield;
        -moz-appearance: textfield;
        background: none;
        background-color: transparent;

        -webkit-appearance: none;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

        @media (min-width: $tabletVMin) {
          width: 100%;
        }

        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 1000px #f6f6f6 inset !important;
        }
      }
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='radio'] {
      display: none;

      & + label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        display: block;

        // Blue highlight disable
        -webkit-tap-highlight-color: transparent;

        &:before {
          position: absolute;
          left: 0;
          top: 24px;
          content: '';
          display: block;
          width: 16px;
          height: 16px;
          background-image: url('/ico/radio.svg');
          background-repeat: no-repeat;
          background-size: contain;
        }
      }

      &:checked + label {
        &:before {
          position: absolute;
          left: 0;
          top: 22px;
          width: 18px;
          height: 18px;
          background-image: url('/ico/radio-checked.svg');
          background-repeat: no-repeat;
          background-size: contain;
          display: block;
          z-index: 1;
        }
      }
    }

    input[type='text']:disabled {
      background-color: white;
    }
  }

  .input-block-opened {
    position: relative;
    z-index: 1;
    top: -20px;
    border-top: dashed 1px #cdd9e8;
    border-radius: 0 0 6px 6px;
    background-color: #fff;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.06);
    overflow: hidden;
    padding-top: 30px;
    margin-right: 24px;
    padding-left: 17px;
    padding-bottom: 30px;

    .input-block {
      width: calc(100% - 17px);
      padding: 0;

      iframe {
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        position: relative;
        top: -2px;
        left: -2px;
      }
    }

    .input-cols {
      // overflow: hidden;

      .input-col2 {
        width: 50%;
        float: left;
      }

      .input-col3 {
        width: 33.3%;
        float: left;
      }
    }

    .button-row {
      width: calc(100% - 17px);
    }

    button {
      display: block !important;
      margin: auto !important;
    }
  }

  .select-block {
    background-image: url('/ico/dropdown.svg');
    background-repeat: no-repeat;
    background-position: right 20px top 50%;
    background-size: 14px;
    padding-right: 0px;

    select {
      background-color: transparent;
      padding-right: 40px;
    }
  }
}

// #date-selector--mask {
//   background-image: url('/images/calendar-days-solid--light.svg');
//   background-repeat: no-repeat;
//   background-size: 20px;
//   background-position: right 10px bottom 50%;
// }
