.page-finish {
  padding-top: 50px;
  background-color: $color-page-start;
  min-height: 100vh;
  overflow: hidden;

  @media (max-height: $smallScreenHeight) {
    padding-top: 20px;
  }

  @media (min-width: $tabletVMin) {
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-width: $template1400) {
    max-width: none;
  }

  .wrapper {
    @media (min-width: $tabletVMin) {
      background-color: $color-page-start;
      margin: auto;
      max-width: 90%;
    }
    @media (min-width: $template1400) {
      max-width: 1024px;
      background-color: $color-page-start;
      margin: auto;
    }
  }

  .action-row {
    .ico {
      float: right;
      position: relative;
      top: 5px;
    }
    .logo-03 {
      float: left;

      @media (max-width: $mobileResponsiveMax) {
        float: none;
        margin: auto;
        width: 212px;
        height: 40px;
        background-size: 212px;
      }
    }
  }

  .link-row {
    text-align: center;
    padding-left: 22px;
    padding-right: 22px;

    a {
      text-decoration: none;
      color: #000;
      font-size: 16px;
      line-height: 20px;
      font-family: $font700;
      font-weight: 700;
      max-width: 300px;
      display: block;
      margin: auto;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .share-row {
    font-size: 16px;
    line-height: 22px;
    font-family: $font700;
    font-weight: 700;
    text-align: center;

    .social {
      margin-top: 26px;
    }
  }

  .button-row {
    overflow: hidden;
    margin-bottom: 28px;

    &.result {
      margin-top: 26px;
      margin-bottom: 48px;
    }
  }

  form {
    overflow: hidden;
    padding-top: 48px;
    padding-right: 24px;
    padding-bottom: 100px;

    @media (min-width: $tabletVMin) {
      max-width: 500px;
      margin: auto;
      clear: both;
      padding-left: 0;
      padding-right: 0;
      left: 0;
    }

    .title {
      margin-bottom: 45px;
    }

    strong {
      text-align: center;
      display: block;
      font-size: 20px;
      line-height: 30px;
      color: #000;
      font-family: $font700;
      font-weight: 700;
      &.main {
        color: $color-theme;
      }

      @media (max-width: $mobileResponsiveMax) {
        font-size: 24px;
      }
    }

    .input-block {
      height: 52px;
      width: 100%;
    }

    .input-title {
      @media (max-width: $mobileResponsiveMax) {
        font-size: 17px;
      }
    }
    input[type='email'],
    input[type='tel'],
    input[type='date'],
    input[type='number'],
    input[type='text'] {
      &,
      &.focus,
      &.active {
        @media (max-width: $mobileResponsiveMax) {
          font-size: 17px;
        }
      }
    }

    .button {
      @media (max-width: $mobileResponsiveMax) {
        font-size: 17px;
      }
    }
  }

  .footer-small {
    @media (min-width: $tabletVMin) and (max-width: $tabletVMax) {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      margin-left: 0;
    }
    @media (min-width: $template1400) {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      margin-left: 0;
    }
  }
}


// 2023 Design variant 
.page-finish {
  .desktop-wide-wrap {

    width: 960px;
    padding-left: 0;
    padding-right: 0;

    @media (max-width: $mobileResponsiveMax) {
      width: 100%;
      padding-left: 24px;
      padding-right: 24px;
    }

    

    .page-finish--columns {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      @media (max-width: $mobileResponsiveMax) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }

      .page-finish--form {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        max-width: 400px;

        .page-finish--form__title {
          font-size: 36px;
          font-weight: 700;
          margin-bottom: 20px;

          padding-top: 30px;

          @media (max-width: $mobileResponsiveMax) {
            font-size: 32px;
            padding-top: 30px;
            padding-bottom: 20px;
          }
        }

        form {
          padding-top: 0;
          padding-bottom: 0;

          width: 400px;

          @media (max-width: $mobileResponsiveMax) {
            // width: 100%;
            width: calc(100% + 48px);
          }

          .button-row {
            overflow: hidden;
            margin-bottom: 0px;
            width: 100%;
        
            &.result {
              margin-bottom: 0px;
            }

            button {
              width: 100%;
              max-width: 500px;
            }
          }
        }

        em {
          font-size: 14px;
          line-height: 1.2;
        }
      }
      .page-finish--img {

        @media (max-width: $mobileResponsiveMax) {
          padding-top: 40px;
          padding-bottom: 150px;
        }

        img {
          display: block;
          width: 100%;
        }
      }
   
    }

    form {
      @media (max-width: $mobileResponsiveMax) {

      }
    } 
  }
}