.congrats-op-v32 {
 
  @media (max-height: $smallScreenHeight) {

  }

  @media (min-width: $template1400) {
 
  }

  .page--op--wrap {
    overflow: visible;
  }
}