.class-top-section {
  background-color: #eef2f7;
  background-image: url('/images/ellipse.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  padding-top: 88px;
  padding-bottom: 52px;

  @media (max-width: $mobileResponsiveMax) {
    background-position: bottom 70px right 50%;
    background-size: 375px;
  }

  @media (min-width: $tabletVMin) {
    background-image: none;
    padding-top: 102px;
    padding-bottom: 52px;
    overflow: hidden;
  }

  @media (min-width: $template1400) {
    min-height: 815px;
  }

  &.reschedule-top-section {
    @media (max-width: $mobileResponsiveMax) {
      background-position: bottom 70px right -250px;
    }
  }

  .desktop-wide-wrap {
    overflow: visible;
    @media (max-width: $mobileResponsiveMax) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .reschedule-logo {
    display: flex;
    justify-content: center;
  }

  .class-top-section-grid {
    display: flex;
    flex-direction: column;

    @media (min-width: $tabletVMin) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;
    }

    .round-desktop {
      display: none;

      @media (min-width: $tabletVMin) {
        position: absolute;
        z-index: 1;
        display: block;

        background-image: url('/images/ellipse.svg');
        background-size: contain;
        background-repeat: no-repeat;

        background-position: 50% 50%;
        bottom: -70px;
        right: -320px;
        width: 400px;
        height: 410px;
      }

      @media (min-width: $template1400) {
        bottom: -100px;
        right: -320px;
        width: 500px;
        height: 510px;
      }
    }

    // reschedule
    &.reschedule {
      justify-content: center;
      flex-wrap: wrap;
      gap: 40px;
      align-items: stretch;
      margin: 0 20px;
      @media (min-width: $tabletH) {
        gap: 22px;
        margin: 0;
      }
      @media (min-width: $template1400Full) {
        gap: 40px;
      }
      .round-desktop {
        @media (min-width: $tabletVMin) {
          bottom: -50px;
        }
      }
      .text-block {
        &.reschedule-text-block {
          background-color: #fff;
          border-radius: 20px;
          padding: 32px 20px;
          width: 100%;
          max-width: 513px;
          margin: 48px auto 0;
          box-shadow: none;
          @media (min-width: $tabletH){
            padding: 59px 31px 20px 42px;
            margin: 40px 0 0;
            width: 50%;
          }
          @media (min-width: $template1400Full) {
            width: 100%;
          }
          .title {
            text-align: left;
            font-family: $font600;
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 18px;
            padding-left: 0;
            padding-right: 0;
            color: #1E3C5C;
            @media (min-width: $tabletVMin){
              font-size: 20px;
              margin-bottom: 10px;
            }
          }
          .description {
            font-size: 13px;
            text-align: left;
            padding: 0;
            @media (min-width: $tabletH) {
              font-family: $font400;
              font-size: 16px;
            }
          }
        }
      }
      .class-calendar-block {
        width: 100%;
        max-width: 513px;
        border-radius: 20px;
        margin: 0 auto;
        box-shadow: none;
        padding: 30px 15px 20px;
        @media (min-width: $tabletH) {
          max-width: 432px;
          padding: 23px 20px 20px;
          margin: 40px 0 0;
          width: 50%;
        }
        @media (min-width: $template1400Full) {
          width: 100%;
        }
        .calendar-row {
          margin: 0 auto;
          max-width: 392px;
          @media (min-width: $tabletVMin) {
            max-width: 392px;
          }
        }
      }

      #schedule-calendar--custom {
        .tr-select {
          div {
            font-family: $font600;
            font-weight: 600;
            font-size: 20px;
            color: #17181B;
            margin: 0 auto 20px;
            @media (min-width: $tabletH) {
              color: #1E3C5C;
              font-size: 20px;
            }
          }
        }
        .clndr-month-year {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          text-align: left;
          margin: 0 0 20px;
          .p-year,
          .p-month {
            text-align: left;
            color: #6C6D78;
            font-size: 16px;
            padding: 0;
          }
          .p-year {
            margin-left: 5px;
          }
        }
        .mNav {
          top: 0;
          @media (min-width: $tabletH) {
            top: 0;
          }
          & > div {
            &.inactive {
              opacity: 0.5;
            }
          }
          .a-prev {
            background-image: url(/svg/arrow-prev.svg);
          }
          .a-next {
            background-image: url(/svg/arrow-next.svg);
          }
        }
        .header-day {
          height: 24px;
          width: 100%;
        }
        .tr-days {
          border: 1px solid #F5F8FE;
          background-color: #F5F8FE;
          display: grid;
          grid-template-columns: repeat(7,minmax(0,1fr));
          @media (min-width: $tabletH) {
            margin: 0 0 8px;
          }
          div {
            border: 1px solid #F5F8FE;
          }
          .wDays {
            font-size: 12px;
            color: #94959F;
            background-color: #F5F8FE;
            text-transform: uppercase;
            font-family: $fontComissioner;
          }
        }
        .tr-bordered {
          border: none;
          display: grid;
          grid-template-columns: repeat(7,minmax(0,1fr));
          & > div {
            border: none;
            font-size: 14px;
            height: 48px;
            width: 100%;
          }
        }
        .days {
          .day {
            color: rgba(57, 98, 140, 0.3);
            &.event {
              color: rgba(57, 98, 140, 1);
            }
            &.event--current {
              color: #D8374F;
              position: relative;
              &::before {
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                z-index: 1;
                width: 40px;
                height: 40px;
                border: 1px solid #D8374F;
                border-radius: 50%;
                @media (min-width: $tabletVMin) {
                  width: 48px;
                  height: 48px;
                }
              }
            }
            &.today {
              &::after {
                background-color: #FE8798;
                width: 4px;
                height: 4px;
                margin-left: -2px;
              }
            }
            .schedule {
              line-height: 2;
              font-size: 14px;
              @media (max-width: $mobile) {
                left: -30px;
                width: 165px;
              }
              li {
                transition: all 0.2s ease;
                &:hover {
                  color: #D8374F;
                }
              }
            }
          }
        }
      }

      .button-row {
        width: 100%;
        form {
          padding: 0;
          left: auto;
          width: 100%;
        }
        .button {
          text-transform: uppercase;
          font-size: 16px;
          font-family: $font600;
          font-weight: 600;
          border-radius: 16px;
          max-width: 200px;
        }
      }

      .radio-events {
        flex-direction: column;
        .radio-item {
          max-width: 100%;
        }
      }
    }

    .radio-events {
      display: flex;
      gap: 15px;
      margin: 0 auto;
      @media (min-width: $tabletH) {
        margin: 30px 0 0;
      }
      .radio-item {
        width: 100%;
        max-width: 200px;
        input[type="radio"] {
          display: none;
          &:checked ~ label {
            background: #39628C;
            span {
              color: #fff;
            }
          }
        }
        label {
          display: block;
          padding: 20px 15px;
          border: 1px dashed #CCD9E8;
          border-radius: 24px;
          cursor: pointer;
          position: relative;
          transition: 0.15s ease-in-out 0s;
          height: 100%;
          @media (min-width: $tabletVMin) {
            padding: 25px 22px;
          }
          span {
            display: block;
            font-size: 14px;
            color: #6C6D78;
            margin: 0;
            text-align: left;
            @media (min-width: $tabletVMin) {
              font-size: 16px;
            }
          }
          .event-num {
            color: #fff;
            background-color: #D8374F;
            font-size: 12px;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            line-height: 1.8;
            margin: 0 0 8px;
          }
          .event-price {
            font-size: 18px;
            font-family: $font500;
            font-weight: 500;
            color: #17181B;
            display: inline-block;
            vertical-align: middle;
            margin: 0 0 8px 5px;
            @media (min-width: $tabletVMin) {
              font-size: 20px;
              margin: 0 0 8px 8px;
            }
          }
          .event-txt {
            padding: 0 0 0 30px;
            text-align: left;
            @media (min-width: $tabletVMin) {
              padding: 0 0 0 35px;
            }
          }
        }
      }

    }

  }

  .text-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media (min-width: $tabletVMin) {
      justify-content: flex-start;
      padding-right: 20px;
      padding-top: 100px;
    }
    @media (min-width: $template1400) {
      justify-content: flex-start;
      padding-right: 20px;
      padding-top: 114px;
    }
    .title {
      font-size: 26px;
      color: #000;
      line-height: 1.4;
      margin-bottom: 16px;
      text-align: center;
      padding-left: 30px;
      padding-right: 30px;
      font-family: $font700;
      font-weight: 700;
      @media (min-width: $tabletVMin) {
        text-align: left;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 24px;
      }
      @media (min-width: $template1400) {
        font-size: 26px;
        line-height: 2;
        color: #1e3c5c;
        margin-bottom: 8px;
        text-align: left;
      }
    }

    .description {
      text-align: center;
      color: #1e3c5c;
      font-size: 17px;
      line-height: 2;
      margin-bottom: 19px;

      @media (max-width: $mobileResponsiveMax) {
        padding-left: 24px;
        padding-right: 24px;
      }

      @media (min-width: $tabletVMin) {
        text-align: left;
      }
      @media (min-width: $template1400) {
        text-align: left;
        max-width: 558px;
        font-size: 20px;
        line-height: 1.9;
        margin-bottom: 15px;
      }
    }

    span {
      font-size: 16px;
      color: $color-theme;
      text-align: center;
      margin-bottom: 17px;

      @media (min-width: $tabletVMin) {
        text-align: left;
      }

      @media (min-width: $template1400) {
        text-align: left;
        font-size: 20px;
        margin-bottom: 20px;
      }
    }

    .cost-block {
      font-size: 32px;
      font-weight: 600;
      color: $color-theme;
      text-align: center;
      margin: auto;
      width: 115px;
      height: 50px;
      line-height: 50px;
      margin-bottom: 48px;
      background-color: #f5faff;
      border: 1px dashed #cdd9e8;
      font-family: $font700;
      font-weight: 700;
      @media (min-width: $tabletVMin) {
        margin: 0;
        margin-bottom: 38px;
      }

      @media (min-width: $template1400) {
        text-align: center;
        font-size: 50px;
        width: 172px;
        height: 78px;
        line-height: 78px;
        margin: 0;
        margin-bottom: 28px;
      }
    }

    .life-fire {
      display: none;

      @media (min-width: $tabletVMin) {
        display: block;
        width: 100%;
        max-width: 765px;
        border-radius: 6px;
        border: 1px solid $color-theme;
        padding: 12px 15px;
        font-size: 17px;
        text-align: left;
        color: $color-theme;
        background-color: #eef2f7;
      }

      @media (min-width: $template1400) {
        display: block;
        width: 100%;
        max-width: 555px;
        border-radius: 6px;
        border: 1px solid $color-theme;
        padding: 17px 20px;
        font-size: 17px;
        text-align: left;
        color: $color-theme;
        background-color: #eef2f7;
      }
    }
  }
}
