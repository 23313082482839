.steps-section {
  margin-bottom: 16px;

  .title {
    // font-size: 16px;
    // line-height: 24px;
    line-height: 1.5;
    font-family: $font700;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;
    padding: 0 30px;

    // font-size: 20px;
    font-size: 26px;

    @media (min-width: $tabletVMin) {
      // font-size: 20px;
      font-size: 32px;
    }
  }

  .steps {
    display: flex;
    flex-direction: column;

    .step {
      display: flex;
      flex-direction: column;

      .step-title {
        display: flex;
        line-height: 1.7;
        margin-bottom: 0px;
        font-family: $font700;
        font-weight: 700;
        color: $color-theme;
        text-align: center;
        justify-content: center;

        // font-size: 15px;
        font-size: 17px;
        margin-bottom: 4px;
        // font-size: 24px;

        @media (min-width: $tabletVMin) {
          // font-size: 14px;
          margin-bottom: 0px;

          font-size: 24px;
        }

        @media (min-width: $template1400) {
          margin-bottom: 18px;
        }
      }

      .step-content {
        display: flex;
        text-align: center;
        color: $color-step;
        line-height: 1.7;
        justify-content: center;

        // font-size: 24px;

        // font-size: 14px;
        font-size: 17px;

        padding-left: 15px;
        padding-right: 15px;

        // @media (min-width: $tabletVMin) {
        //   font-size: 17px;
        // }

        @media (min-width: $tabletVMin) {
          // font-size: 14px;
          // font-size: 17px;
          padding-left: 0;
          padding-right: 0;
        }

        @media (min-width: $template1400) {
          // font-size: 14px;
          line-height: 1.9;
        }
      }

      .step-divider {
        display: flex;
        justify-content: center;
        width: 1px;
        height: 40px;
        border-left: dashed 1px $color-important-border;
        margin-left: 50%;
        margin-bottom: 4px;

        @media (min-width: $tabletVMin) {
          // border-left: dashed 2px $color-important-border;
        }
      }
    }
  }
}
