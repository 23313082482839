.want-your-license {
  background-color: #eef2f7;
  padding-top: 99px;
  padding-bottom: 41px;

  background-image: url('/images/map-light.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;

  @media (min-width: $tabletVMin) {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media (min-width: $template1400) {
    padding-top: 142px;

    height: min-760px;
    background-size: 840px;
    background-position: 50% 111%;
  }

  .desktop-wide-wrap {
    @media (max-width: $mobileResponsiveMax) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .title {
    font-family: $font700;
    font-weight: 700;
    color: #000;
    text-align: center;
    font-size: 24px;
    margin-bottom: 56px;
    line-height: 1.42;

    @media (min-width: $tabletVMin) {
      font-size: 43px;
      line-height: 1.55;
      text-align: center;
      margin-bottom: 140px;
    }

    @media (min-width: $template1400) {
      font-size: 31px;
      margin-bottom: 41px;
    }
  }

  .want-your-license-grid {
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (min-width: $tabletVMin) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
    }

    @media (min-width: $template1400) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
    }

    .item {
      overflow: hidden;
      background-color: #fff;
      overflow: hidden;

      width: 324px;
      margin-bottom: 25px;

      display: flex;
      flex-direction: column;

      @media (min-width: $tabletVMin) {
        // width: 270px;
        width: 320px;
        margin-bottom: 0;
        min-height: 440px;

        position: relative;
        padding-bottom: 90px;

        &:first-of-type {
          // border-right: 1px solid #f5faff;
          border-right: 1px solid #cdd9e8;
        }
      }

      @media (min-width: $template1400) {
        // width: 250px;
        width: 290px;
        // min-height: 445px;
        // min-height: 470px;
      }

      .top-row {
        width: 100%;
        overflow: hidden;
        height: 65px;

        @media (min-width: $template1400) {
          height: 62px;
        }

        .item-title {
          padding-top: 15px;
          padding-left: 19px;
          float: left;
          font-size: 19px;
          font-weight: bold;
          height: 100%;
          display: flex;
          align-items: center;
          color: black;

          width: 250px;
          overflow: hidden;

          @media (min-width: $template1400) {
            // padding-top: 0;
            padding-top: 10px;
            padding-left: 17px;
            font-size: 18px;
            width: 220px;
            line-height: 1.3;
          }
        }

        .price-block {
          background-color: #f5faff;
          width: 67px;
          border: 1px dashed #cdd9e8;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          float: right;
          height: 100%;
          padding-left: 8px;

          @media (min-width: $tabletVMin) {
            width: 63px;
          }

          @media (min-width: $template1400) {
            width: 63px;
            padding-left: 7px;
          }

          .price {
            color: $color-theme;
            font-family: $font700;
            font-weight: 700;
            font-size: 19px;

            @media (min-width: $tabletVMin) {
            }

            @media (min-width: $template1400) {
              font-size: 20px;
              margin-bottom: 0;
            }
          }

          .hours {
            color: $color-theme-2;
            font-size: 13px;
            font-weight: bold;

            @media (min-width: $tabletVMin) {
            }

            @media (min-width: $template1400) {
              font-size: 12px;
            }
          }
        }
      }

      .bottom-row {
        padding-top: 14px;
        padding-left: 18px;
        padding-right: 18px;

        @media (min-width: $template1400) {
          padding-top: 27px;
          padding-left: 18px;
          padding-right: 18px;
        }

        .desc-title {
          opacity: 0.5;
          color: $color-theme-2;
          // font-size: 10px;
          // font-size: 12px;
          font-size: 17px;
          line-height: 2;

          @media (min-width: $tabletVMin) {
            // font-size: 10px;
            margin-bottom: 5px;
            line-height: 1;
          }

          @media (min-width: $template1400) {
          }

          &.for-title {
            padding-top: 16px;

            @media (min-width: $template1400) {
              padding-top: 15px;
              // font-size: 13px;
              font-size: 17px;
              margin-bottom: 2px;
            }
          }
        }

        .desc {
          color: $color-theme-2;
          // font-size: 10px;
          // font-size: 12px;
          font-size: 15px;
          // line-height: 2.2;
          line-height: 1.5;

          @media (min-width: $tabletVMin) {
            // font-size: 11px;

            font-size: 14px;
            // line-height: 2.2;
            line-height: 1.8;
          }

          @media (min-width: $template1400) {
            // font-size: 11px;
          }
        }

        .for {
          position: relative;
          padding-left: 13px;

          @media (min-width: $tabletVMin) {
          }

          @media (min-width: $template1400) {
            padding-left: 16px;
          }

          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 9px;
            left: 1px;
            width: 5px;
            height: 5px;
            background-image: url('/images/for.svg');
            background-repeat: no-repeat;
            background-position: 0 0;
            background-size: contain;

            @media (min-width: $template1400) {
              width: 10px;
              height: 7px;
              top: 8px;
              left: 0;
            }
          }
        }

        .button-row {
          clear: both;
          overflow: hidden;
          // text-align: left;
          text-align: center;
          padding-top: 16px;
          // padding-bottom: 20px;
          padding-bottom: 5px;

          @media (min-width: $tabletVMin) {
            padding-top: 25px;
            // padding-bottom: 25px;
            padding-bottom: 5px;

            height: 90px;
            width: 100%;
            position: absolute;
            text-align: center;
            bottom: 10px;
            left: 0;
            margin: auto;
          }

          a.a-btn {
            text-decoration: none;
            display: block;
            color: #fff;
            cursor: pointer;
            margin: auto;

            background: $color-theme;
            width: 186px;
            height: 40px;
            line-height: 40px;
            // font-size: 10px;
            font-size: 14px;

            border-radius: 6px;
            text-align: center;

            @media (min-width: $tabletVMin) {
              width: 178px;
              height: 36px;
              line-height: 36px;
              // font-size: 11px;
              // font-size: 12px;
              font-size: 14px;
            }

            &:hover {
              background: $color-button-hover;
            }

            // span {
            // font-weight: 600;
            // }
          }

          a.more {
            // font-size: 12px;
            font-size: 14px;
            margin: auto;
            font-family: $font300;
            color: #000;
            text-decoration: none;
            text-align: center;
            line-height: 2.5;

            &:hover {
              // color: $color-button-hover;
              text-decoration: underline;
            }

            @media (min-width: $tabletVMin) {
              position: relative;
              top: 2px;
            }
          }
        }
      }
    }
  }
}
