.copyright-section {
  background-color: #eef2f7;
  text-align: center;
  padding-top: 32px;
  // padding-bottom: 32px;
  padding-bottom: 72px;

  @media (min-width: $template1400) {
    padding-top: 43px;
    padding-bottom: 46px;
  }

  p {
    // font-size: 18px;
    font-size: 17px;
    line-height: 1.5;
    // font-weight: 500;
    color: $color-theme-2;
    // font-family: $font700;
    font-family: $font400;

    @media (min-width: $template1400) {
      // font-size: 25px;
      font-size: 17px;
    }
  }
  &.copyright-white {
    font-family: $fontComissioner;
    color: #2A3E58;
    background-color: $color-white;
    font-size: 12px;
    padding: 16px 0;
    @media (min-width: $tabletVMin) {
      font-size: 14px;
      padding: 32px 0;
    }
  }
}
