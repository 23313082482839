.important {
  background-color: $color-important-bg;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: dashed 1px $color-important-border;

  & + .important {
    margin-top: 20px;
  }

  .text {
    color: $color-important;
    // font-size: 13px;
    // line-height: 24px;
    line-height: 1.8;
    font-family: $font700;
    font-weight: 700;
    padding: 21px 24px 19px 24px;

    // font-size: 16px;
    font-size: 13px;

    @media (min-width: $tabletVMin) {
      font-size: 16px;
    }
  }

  .description {
    line-height: 1.6;
    color: $color-important-description;
    text-align: center;

    font-size: 15px;
    @media (min-width: $tabletVMin) {
      font-size: 15px;
    }
  }

  .time {
    color: $color-theme;
    font-family: $font700;
    font-weight: 700;
    font-size: 18px;

    @media (min-width: $tabletVMin) {
      font-size: 24px;
    }
  }

  &.double {
    padding-right: 0;
    flex-direction: row;

    .text {
      justify-content: left;
      width: 70%;
      padding-right: 24px;
      border-right: dashed 1px $color-important-border;
    }

    .time {
      width: 30%;
      justify-content: center;
      text-align: center;
    }
  }
}
