.logos-v32 {
  width: 1280px;
  margin: auto;
  margin-bottom: 150px;

  @media (max-width: $mobileResponsiveMax) {
    width: 100%;
    margin-bottom: 48px;
  }

  h2 {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 80px;
    text-align: center;
    color: #000;
    font-family: $font600;
    font-weight: 600;

    @media (max-width: $mobileResponsiveMax) {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 24px;
      padding: 0 20px;
    }
  }
  .logos--desktop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 48px;
    height: 99px;

    @media (max-width: $mobileResponsiveMax) {
      display: none;
    }

    img {
      display: block;

      &.i1 {width: 102px;}
      &.i2 {width: 140px;}
      &.i3 {width: 103px;}
      &.i4 {width: 123px;}
      &.i5 {width: 201px;}
      &.i6 {width: 150px;}
      &.i7 {width: 173px;}
    }
  }

  .logos--mobile {
    display: none;

    @media (max-width: $mobileResponsiveMax) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 32px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .logos-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 48px;
      width: 100%;

      &.center {
        justify-content: center;

        @media (max-width: $mobileResponsiveMax) {
          margin-top: -12px;
          margin-bottom: -13px;
        }
      }

      img {
        display: block;
  
        &.i1 {width: 65px;}
        &.i2 {width: 69px;}
        &.i3 {width: 110px;}
        &.i4 {width: 75px;}
        &.i5 {width: 107px;}
        &.i6 {width: 96px;}
        &.i7 {width: 185px;}
      }
    }
  }

 }
