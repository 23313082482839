.articles-section-v3 {
  padding-top: 150px;
  max-width: 1344px;
  margin: auto;
  padding-bottom: 248px;

  @media (max-width: $mobileResponsiveMax) {
    padding-top: 0;
    padding-bottom: 48px;
    &.dot-1 {
      .item {
        display: none;
      }
      .item:nth-child(1),
      .item:nth-child(2) {
        display: block;
      }
    }
    &.dot-2 {
      .item {
        display: none;
      }
      .item:nth-child(3),
      .item:nth-child(4) {
        display: block;
      }
    }
  }
  .title {
    color: $color-theme-2;
    font-size: 42px;
    font-family: $font600;
    font-weight: 600;
    line-height: 56px;
    margin-bottom: 24px;

    @media (max-width: $tabletVMax) {
      text-align: center;
    }

    @media (max-width: $mobileResponsiveMax) {
      font-size: 38px;
      line-height: 40px;
      margin-bottom: 8px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .text {
    font-size: 20px;
    text-align: left;
    max-width: 774px;
    margin-bottom: 64px;
    font-family: $fontComissioner;
    color: #6B6B6B;

    @media (max-width: $tabletVMax) {
      text-align: center;
      margin: 0 auto 35px;
    }

    @media (max-width: $mobileResponsiveMax) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 24px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .articles-list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 64px;

    @media (max-width: $tabletVMax) {
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
    }

    @media (max-width: $mobileResponsiveMax) {
      flex-direction: column;
      margin-bottom: 24px;
    }
  }

  .article-dots {
    display: none;

    @media (max-width: $mobileResponsiveMax) {
      margin-bottom: 32px;
      height: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
    .dot {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #E4E7EA;
      cursor: pointer;
      &.active {
        width: 8px;
        height: 8px;
        background-color: #1E3C5C;
      }
    }
  }

  .item {
    width: 100%;
    max-width: 318px;
    height: 380px;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      display: block;
    }
    &:before {
      display: block;
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('/assets-v3/fader-article.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 100% 100%;
    }
    .item-text {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 16px;
      font-size: 18px;
      color: $color-white;
      text-align: left;
      z-index: 2;
      font-family: $font500;
      font-weight: 500;

      @media (max-width: $mobileResponsiveMax) {
        font-size: 16px;
        padding: 24px 16px;
      }
    }
  }
  .article-btn-row {
    a {
      width: 100%;
      max-width: 230px;
      height: 56px;
      line-height: 56px;
      margin: 0;
      font-size: 16px;

      @media (max-width: $tabletVMax) {
        margin: 0 auto;
      }

      @media (max-width: $mobileResponsiveMax) {
        max-width: 335px;
        height: 48px;
        line-height: 48px;
        font-family: $font500;
        font-weight: 500;
      }
    } 
  }
}
