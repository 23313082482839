.features-v32 {

  width: 1005px;
  margin: auto;
  margin-bottom: 48px;

  @media (max-width: $mobileResponsiveMax) {
    padding: 0 20px;
    width: 100%;
    margin-bottom: 32px;
  }

  h2 {
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    margin-bottom: 80px;
    font-family: $font600;
    font-weight: 600;

    @media (max-width: $mobileResponsiveMax) {
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      margin-bottom: 24px;
    }
  }
  
  .features-row {

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 17px;

    @media (max-width: $mobileResponsiveMax) {
      flex-direction: column;
      gap: 14px;
    }
  }

  .feat {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 32px 34px;
    border: 1px solid #F1F1F1;
    background-color: #F9F9F9;
    border-radius: 24px;
    width: 33.33%;
    height: 268px;

    @media (max-width: $mobileResponsiveMax) {
      width: 100%;
      height: auto;
      padding: 32px 42px;
    }

    h4 {
      margin: 0;
      padding: 0;
      color: #17181B;
      font-size: 20px;
      line-height: 28px;
      font-family: $font500;
      font-weight: 500;
      margin-bottom: 24px;
      text-transform: uppercase;

      @media (max-width: $mobileResponsiveMax) {
        font-size: 18px;
        margin-bottom: 16px;
      }
    }

    ul {
      margin: 0;
      padding: 0;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 16px;

      margin-left: 34px;
      padding-left: 0px;

      @media (max-width: $mobileResponsiveMax) {
        gap: 0px;
      }
    }

    p {
      margin: 0;
      padding: 0;
      font-family: $fontComissioner;
      font-weight: normal;
    }

    li {
      margin: 0;
      padding: 0;
      white-space: nowrap;

      @media (max-width: $mobileResponsiveMax) {
        margin-bottom: 8px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    p, li {
      color: #6C6D78;
      font-size: 16px;
      line-height: 24px;
      text-align: left;

      @media (max-width: $mobileResponsiveMax) {
      
      }
    }

   img {
    display: block;
    margin-bottom: 24px;

    &.i1 {
      width: 97px;

      @media (max-width: $mobileResponsiveMax) {
        width: 73px;
      }
    }
    &.i2 {
      width: 77px;

      @media (max-width: $mobileResponsiveMax) {
        width: 57px;
      }
    }
    &.i3 {
      width: 80px;

      @media (max-width: $mobileResponsiveMax) {
        width: 60px;
      }
    }
   }
  }

 }
