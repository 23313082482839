.how-to-v32 {
  background-color: #F1F3F5;
  max-width: 1392px;
  margin: auto;
  padding: 65px 24px 80px;
  border-radius: 44px;
  margin-bottom: 150px;

  @media (min-width: $tabletVMin) {
  }

  @media (max-width: $mobileResponsiveMax) {
    padding: 40px 16px;
    border-radius: 16px;
    margin-bottom: 48px;
    width: calc(100% - 40px);
  }

  h2 {
    color: #17181B;
    text-align: left;
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 44px;
    font-family: $font600;
    font-weight: 600;

    @media (min-width: $tabletVMin) {
      padding-right: 50px;
    }

    @media (max-width: $mobileResponsiveMax) {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 32px;
    }

    &.mobile--only{
      display: none;
      @media (max-width: $mobileResponsiveMax) {
        display: block;
      }
    }
    &.desktop--only{
      display: block;
      @media (max-width: $mobileResponsiveMax) {
        display: none;
      }
    }

    span {
      color :#D8374F;
    }
  }

  p {
    color: #6C6D78;
    margin: 0;
    padding: 0;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 32px;
    font-family: $fontComissioner;

    @media (max-width: $mobileResponsiveMax) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 16px;
    }

    & + p {
      margin-top: -8px;

      @media (max-width: $mobileResponsiveMax) {
        margin-top: -8px;
      }
    }
  }

  .info {
    background-color: #fff;
    border: 1px dashed #000;
    padding: 32px 55px 32px 32px;
    border-radius: 24px;
    margin-bottom: 80px;

    @media (max-width: $mobileResponsiveMax) {
      padding: 32px 35px 32px 16px;
      margin-bottom: 32px;
      border-radius: 16px;
    }

    p {
      text-align: left;
      color: #6C6D78;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 16px;
      font-family: $fontComissioner;
  
      @media (max-width: $mobileResponsiveMax) {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;
      }

      & + p {
        margin-top: 0;
      }
    }

    .info-title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px;
      font-family: $font600;
      font-weight: 600;

      @media (max-width: $mobileResponsiveMax) {
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 16px;
      }

    }
  }

  .info-1 {
    p {
      margin: 0;
    }
  }

  .step-section {
    margin-bottom: 80px;

    @media (max-width: $mobileResponsiveMax) {
      margin-bottom: 32px;
    }

    p {
      @media (min-width: $tabletVMin) {
        margin-bottom: 24px;
      }
    }
  }

  .step-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 546px;
    margin-bottom: 24px;

    @media (max-width: $mobileResponsiveMax) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 16px;
    }

    .step-number {
      text-align: left;
      color: #1E3C5C;
      font-size: 24px;
      line-height: 32px;
      font-family: $font600;
      font-weight: 600;

      @media (max-width: $mobileResponsiveMax) {
        font-size: 18px;
        line-height: 27px;
        color: #1E3C5C;
      }
    }

    .step-arrow {
      width: 24px;
      height: 24px;
      
      @media (max-width: $mobileResponsiveMax) {
        width: 20px;
        height: 20px;
      }

      img {
        width: 100%;
      }
    }
    
  }

  .img {
    width: 100%;
    margin-bottom: 24px;

    @media (max-width: $mobileResponsiveMax) {
      width: 100%;
      margin-bottom: 24px;
    }

    img {
      display: block;
      width: 100%;
    }

    &.mobile--only{
      display: none;
      @media (max-width: $mobileResponsiveMax) {
        display: block;
      }
    }
    &.desktop--only{
      display: block;
      @media (max-width: $mobileResponsiveMax) {
        display: none;
      }
    }

    &.lefter {
      position: relative; 
      right: -110px;

      @media (max-width: $mobileResponsiveMax) {
        text-align: left;
        overflow: hidden;
        position: static;
      }
     
      img {
        width: 320px;
        float: left;
        position: relative;
        top: 18px;

        @media (max-width: $mobileResponsiveMax) {
          width: 180px;
          position: static;
        }
      }
    }

    &.righter {
    
      @media (max-width: $mobileResponsiveMax) {
        text-align: right;
        overflow: hidden;
      }
     
      img {
        width: 320px;
        float: right;

        @media (max-width: $mobileResponsiveMax) {
          width: 180px;
        }
      }
    }
  }

  .step-title {
    text-align: left;
    color: #17181B;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
    font-family: $font600;
    font-weight: 600;

    @media (max-width: $mobileResponsiveMax) {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 16px;
    }

    span {
      color: #D8374F;
    }
  }

  .step-section--button {
    padding-top: 20px;
    padding-left: 90px;

    @media (max-width: $mobileResponsiveMax) {
      padding-top: 0px;
      padding-left: 0px;
    }

    .step-section--button--title {
      color: #17181B;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px;
      font-family: $font600;
      font-weight: 600;

      @media (max-width: $mobileResponsiveMax) {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 16px;
      }
    }

    .button {
      width: 215px;
      height: 56px;

      @media (max-width: $mobileResponsiveMax) {

      }
    }
  }

  // Desktop

  .step-row-1-1 {
    @media (min-width: $tabletVMin) {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 54px;
    }

    .step-col-1-1-1 {
      @media (min-width: $tabletVMin) {
        width: 546px;
      }
    }
    .step-col-1-1-2 {
      @media (min-width: $tabletVMin) {
        width: 774px;

        img {
          border-radius: 26px;
          overflow: hidden;
        }
      }
    }
  }

  .step-row-1-2 {
    @media (min-width: $tabletVMin) {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: -24px;
    }

    .step-col-1-2-1 {
      @media (min-width: $tabletVMin) {
        width: 546px;

        img {
          border-radius: 26px;
          overflow: hidden;
        }
      }
    }
    .step-col-1-2-2 {
      @media (min-width: $tabletVMin) {
        width: 774px;

        .info {
          margin-bottom: 0;
          padding: 32px 25px 14px 24px;

          p {
            margin-bottom: 16px;
            font-size: 23px;
          }

        }
      }
    }
  }

  .step-row-3-1 {
    @media (min-width: $tabletVMin) {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 80px;
    }

    .step-col-3-1-1 {
      @media (min-width: $tabletVMin) {
        width: 546px;
      }
    }
    .step-col-3-2-1 {
      @media (min-width: $tabletVMin) {
        width: 774px;
      }
    }
  }
 
 }
