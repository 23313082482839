.how-much-section {
  padding-top: 40px;
  padding-left: 23px;
  padding-right: 23px;
  padding-bottom: 34px;

  @media (min-width: $tabletVMin) {
    padding-top: 90px;
    padding-bottom: 115px;
  }

  .heading-section {
    .title {
      strong {
        margin-bottom: 24px;
        font-size: 24px;

        @media (min-width: $tabletVMin) {
          margin-bottom: 48px;
          font-size: 26px;
        }
      }
    }
  }

  .info-section {
    border: dashed 1px $color-important-border;
    max-width: 830px;
    margin: auto;
    border-radius: 16px;
    overflow: hidden;

    .info-block {
      border-top: dashed 1px $color-important-border;
      padding-left: 16px;
      padding-right: 16px;

      @media (min-width: $tabletVMin) {
        padding-left: 30px;
        padding-right: 30px;
      }

      &.price {
        border-top: none;
        background-color: #fff;
        padding-top: 21px;
        padding-bottom: 20px;
        color: #000;
        line-height: 1.7;
        font-family: $font700;
        font-weight: 700;
        text-align: center;

        font-size: 24px;
        @media (min-width: $tabletVMin) {
          font-size: 17px;
        }

        .cost {
          line-height: 1.5;
          color: $color-theme;

          font-size: 42px;
          @media (min-width: $tabletVMin) {
            font-size: 42px;
          }
        }
      }

      &.description {
        padding-top: 20px;
        padding-bottom: 20px;
        line-height: 1.8;
        text-align: left;
        color: #2a3e58;
        background-color: #fff;

        // font-size: 20px;
        font-size: 15px;

        @media (min-width: $tabletVMin) {
          font-size: 15px;
        }

        p {
          margin-bottom: 20px;

          @media (min-width: $tabletVMin) {
            // margin-bottom: 30px;
            margin-bottom: 18px;
          }

          ol {
            margin-left: 20px;
    
            @media (min-width: $tabletVMin) {
              margin-left: 40px;  
            }
            li {
              margin-bottom: 5px;
            }
          }
        }

        .description--ul {
          margin: 0;
          padding: 0 18px;
          list-style: none;
          margin-bottom: 62px;
      
          @media (min-width: $template1400) {
            padding: 5px 22px 0px;
            margin-bottom: 44px;
          }
      
          li {
            color: $color-theme-2;
            // font-size: 12px;
            font-size: 17px;
            line-height: 2;
            margin-bottom: 24px;
            font-family: $font500;
            font-weight: 500;
      
            @media (min-width: $template1400) {
              // font-size: 14px;
              font-size: 17px;
              line-height: 1.8;
              // margin-bottom: 38px;
              margin-bottom: 18px;
            }
      
            ol {
              margin-left: 20px;
      
              @media (min-width: $tabletVMin) {
                margin-left: 40px;  
              }
              li {
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }
  }

  .button-row {
    margin-top: 50px;
  }
}
