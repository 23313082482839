.img-review-v32 {
  width: 100%;
  margin-bottom: 100px;

  @media (max-width: $mobileResponsiveMax) {
    padding: 0 20px;
    margin-bottom: 48px;
  }

  .img-review--mobile {
    display: none;

    @media (max-width: $mobileResponsiveMax) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  .img-review--desktop {
    display: flex;
    width: 1230px;
    margin: auto;

    @media (max-width: $mobileResponsiveMax) {
      display: none;
    }
  }

  .img-review--row {
    width: 100%;
    gap: 24px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: $mobileResponsiveMax) {
      // gap: 16px;
      gap: 12px;
    }

  }
  .img-review--col {
    gap: 16px;
    width: 432px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    @media (max-width: $mobileResponsiveMax) {
      // gap: 16px;
      gap: 12px;
      width: 100%;
    }
    
    &.min {
      width: 318px;
    }
  
  }

  img {
    display: block;
    width: 100%;

    @media (max-width: $mobileResponsiveMax) {
      // width: 50%;
      width: 48%;
    }
  }

 }
