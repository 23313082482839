.header-transparent-v3 {
  position: relative;
}

.header-transparent-v3 {
  &.white {
    background-color: #fff;
  }

  height: 50px;

  height: 50px;
  position: relative;
  margin-bottom: -50px;
  width: 100%;
  z-index: z('header-transparent');

  @media (min-width: $tabletVMin) {
    height: 80px;
    position: relative;
    margin-bottom: -80px;
  }

  @media (min-width: $template1400) {
    // height: 167px;
    // margin-bottom: -167px;
    height: 110px;
    margin-bottom: -110px;
  }

  .header-transparent-content {
    // height: 50px;
    height: 70px;

    display: flex;
    align-items: center;

    @media (min-width: $tabletVMin) {
      height: 80px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    @media (min-width: $template1400) {
      // height: 167px;
      height: 110px;
    }

    .logo-header-transparent {
      float: left;
      width: 198px;
      height: 30px;
      background-image: url('/images/2023/logo_04.svg');
      background-position: 50% 0px;
      background-size: contain;
      background-repeat: no-repeat;

      position: relative;
      top: 10px;

      &.light {
        background-image: url('/images/2023/logo_03.svg');
      }

      @media (min-width: $tabletVMin) {
        position: static;
        top: 0;
      }

      @media (min-width: $template1400) {
        width: 280px;
        height: 44px;
        background-position: 0px 0px;
      }
    }

    .menu-row {
      display: flex;
      align-items: center;

      a {
        display: none;

        @media (min-width: $tabletVMin) {
          display: block;
          margin-left: 22px;
          // font-size: 14px;
          font-size: 18px;
          text-decoration: none;
          text-transform: uppercase;
          color: black;
          white-space: nowrap;
          font-family: $font500;
          font-weight: 500;

          &.active,
          &:hover {
            color: $color-theme;
          }
        }

        @media (min-width: $template1400) {
          margin-left: 28px;
          // font-size: 14px;
        }
      }

      &.home {
        a {
          @media (min-width: $tabletVMin) {
            color: white;
          }

          &:hover {
            color: $color-theme;
          }
        }
      }
    }

    .button {
      float: right;
      height: 41px;
      line-height: 41px;
      border-radius: 3px;
      padding-left: 17px;
      padding-right: 17px;

      &,
      &:hover {
        color: white !important;
      }

      @media (min-width: $tabletVMin) {
        font-stretch: normal;
        font-style: normal;
        position: relative;
      }

      @media (min-width: $template1400) {
        height: 36px;
        line-height: 36px;
        padding-left: 22px;
        padding-right: 22px;
      }
    }

    .menu-mobile-opener {
      width: 30px;
      height: 30px;
      background-image: url('/ico/menu.svg');
      // light only for the home page
      // background-image: url('/ico/menu-light.svg');
      background-repeat: no-repeat;
      background-position: 100% 50%;
      background-size: contain;
      background-size: 30px;
      position: relative;
      // z-index: 1;
      z-index: z('menu-mobile-opener');
      cursor: pointer;

      position: absolute;
      right: 20px;
      // top: 10px;
      top: 30px;

      @media (min-width: $tabletVMin) {
        display: none !important;
      }
    }

    .menu-mobile-close {
      width: 30px;
      height: 30px;
      background-image: url('/ico/close-lite.svg');
      background-repeat: no-repeat;
      background-position: 50% 100%;
      background-size: contain;
      background-size: 30px;
      position: relative;
      // z-index: 3;
      z-index: z('menu-mobile-close');
      position: absolute;
      right: 20px;
      top: 30px;
      cursor: pointer;

      @media (min-width: $tabletVMin) {
        display: none !important;
      }
    }

    .menu-mobile {
      display: none;

      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      @media (min-width: $tabletVMin) {
        display: none !important;
      }

      .menu-mobile-wrap {
        position: relative;
        width: 100%;
        height: 100%;
      }

      .menu-mobile-fader {
        background-color: rgba(0, 0, 0, 0.9);

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // z-index: 1;
        z-index: z('menu-mobile-fader');
      }

      .menu-col {
        padding: 50px;
        position: relative;
        // z-index: 2;
        z-index: z('menu-col');
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        a {
          color: #fff;
          line-height: 30px;
          text-decoration: none;
          text-transform: uppercase;
          margin-bottom: 20px;

          &.active,
          &:hover {
            color: $color-theme;
          }
        }

        .button {
          float: none;
          height: 41px;
          line-height: 41px;
          font-size: 13px;
          border-radius: 6px;
          padding-left: 17px;
          padding-right: 17px;

          font-stretch: normal;
          font-style: normal;
          width: auto;
          margin: 0;

          &,
          &:hover {
            color: white !important;
          }
        }
      }
    }
  }
}

.header-transparent-v3.home .header-transparent-content .menu-mobile-opener {
  background-image: url('/ico/menu-light.svg');
}
