.after--header-first--small {
  padding-top: 140px;

  @media (min-width: $tabletVMin) {
    padding-top: 140px;
  }

  &.faq-section--responsive {
    padding-top: 140px;

    @media (min-width: $tabletVMin) {
      padding-top: 140px;
    }

    .faq-section {
      padding-top: 0;
    }
  }
}
.page-main.page-main--homepage-v2 {
  .after--header-first--small {
    // padding-top: 90px;
    padding-top: 0px;

    @media (min-width: $tabletVMin) {
      // padding-top: 90px;
      padding-top: 0px;
    }

    &.faq-section--responsive {
      // padding-top: 90px;
      padding-top: 0px;

      @media (min-width: $tabletVMin) {
        // padding-top: 90px;
        padding-top: 0px;
      }

      .faq-section {
        padding-top: 0;
      }
    }
  }
}

.header-first-page {
  background-color: #f5faff;
  border-bottom: 13px solid #e2e2e2;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  height: 100px;

  @media (min-width: $tabletVMin) {
    height: 112px;
  }

  @media (min-width: $template1400) {
  }

  &.small {
    border-bottom: 0;

    .menu-hide {
      display: none !important;
    }
  }

  .logo-01 {
    float: left;
    width: 183px;
    height: 43px;
    background-position: 50% 50%;
    background-size: 183px;

    @media (min-width: $template1400) {
    }
  }

  .header-first-content {
    height: 90px;

    display: flex;
    align-items: center;

    @media (min-width: $tabletVMin) {
      height: 80px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    @media (min-width: $template1400) {
      height: 110px;
    }

    .menu-row {
      display: flex;
      align-items: center;

      a {
        display: none;

        @media (min-width: $tabletVMin) {
          display: flex;
          align-items: center;
          margin-left: 22px;
          font-size: 18px;
          text-decoration: none;
          text-transform: uppercase;
          color: black;
          white-space: nowrap;
          font-family: $font500;
          font-weight: 500;
          &.active,
          &:hover {
            color: $color-theme;
          }
        }

        @media (min-width: $template1400) {
          margin-left: 28px;

        }
      }

      &.home {
        a {
          @media (min-width: $tabletVMin) {
            color: white;
          }

          &:hover {
            color: $color-theme;
          }
        }
      }
    }

    .button {
      float: right;
      height: 41px;
      line-height: 41px;
      border-radius: 3px;
      padding-left: 17px;
      padding-right: 17px;

      &,
      &:hover {
        color: white !important;
      }

      @media (min-width: $tabletVMin) {
        font-stretch: normal;
        font-style: normal;
        position: relative;
      }

      @media (min-width: $template1400) {
        height: 36px;
        line-height: 36px;
        padding-left: 22px;
        padding-right: 22px;
      }
    }

    .menu-mobile-opener {
      width: 30px;
      height: 30px;
      background-image: url('/ico/menu.svg');
      background-repeat: no-repeat;
      background-position: 100% 50%;
      background-size: contain;
      background-size: 30px;
      position: relative;
      z-index: z('menu-mobile-opener');
      cursor: pointer;

      position: absolute;
      right: 20px;
      top: 30px;

      @media (min-width: $tabletVMin) {
        display: none !important;
      }
    }

    .menu-mobile-close {
      width: 30px;
      height: 30px;
      background-image: url('/ico/close-lite.svg');
      background-repeat: no-repeat;
      background-position: 50% 100%;
      background-size: contain;
      background-size: 30px;
      position: relative;
      z-index: z('menu-mobile-close');
      position: absolute;
      right: 20px;
      top: 30px;
      cursor: pointer;

      @media (min-width: $tabletVMin) {
        display: none !important;
      }
    }

    .menu-mobile {
      display: none;

      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      // z-index: 1;
      z-index: z('menu-mobile');

      @media (min-width: $tabletVMin) {
        display: none !important;
      }

      .menu-mobile-wrap {
        position: relative;
        width: 100%;
        height: 100%;
      }

      .menu-mobile-fader {
        background-color: rgba(0, 0, 0, 0.9);

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: z('menu-mobile-fader');
      }

      .menu-col {
        padding: 50px;
        position: relative;
        z-index: z('menu-col');
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .menu-hide {
          display: none !important;
        }

        a {
          color: #fff;
          line-height: 30px;
          text-decoration: none;
          text-transform: uppercase;
          margin-bottom: 20px;

          &.active,
          &:hover {
            color: $color-theme;
          }
        }

        .button {
          float: none;
          height: 41px;
          line-height: 41px;
          font-size: 16px;
          border-radius: 6px;
          font-stretch: normal;
          font-style: normal;
          width: auto;
          
          padding-left: 0;
          padding-right: 0;
          margin: 0 -20px 20px;
          max-width: calc(100% + 40px);
          &,
          &:hover {
            color: white !important;
          }
        }
      }
    }
  }

  // Page scroll progressbar
  progress {
    position: fixed;
    left: 0;
    width: 100%;
    height: 13px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background: transparent;
    color: $color-theme-2;

    top: 87px;

    @media (min-width: $tabletVMin) {
      top: 99px;
    }
  }

  progress::-webkit-progress-bar {
    background: transparent;
    border-radius: 0 5px 5px 0;
  }

  progress::-webkit-progress-value {
    background: $color-theme-2;
    border-radius: 0 5px 5px 0;
  }
  progress::-moz-progress-bar {
    background: $color-theme-2;
    border-radius: 0 5px 5px 0;
  }
  .progress-container {
    width: 100%;
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    height: 5px;
    display: block;
  }

  .progress-bar {
    background: $color-theme-2;
    width: 0%;
    display: block;
    height: inherit;
  }
}

.like-home {
  .after--header-first--small {
    padding-top: 30px;

    @media (min-width: $tabletVMin) {
      padding-top: 50px; 
    }
  }

  .header-transparent .header-transparent-content .logo-header-transparent.light {
    background-image: url('/images/2023/logo_04.svg');
  }
}


// ! No Desktop Menu + No Mobile Menu + Book now button
.menu-book--only {
  &:not(.quiz-page) {
  
    .header-first-page .header-first-content {
      justify-content: space-between;

      .menu-row a {
          &{
              display: none;
          } 
          &.button {
            display: flex;
            align-items: center;
          }
      }
    }
    .menu-mobile-opener {
      display: none!important;
    }

    .header-first-page {
      .desktop-wide-wrap {
        &.dropdown-wrap {
          position: absolute;
          top: 75px;
          right: 0;
          left: 0;
          display: block;
        }
      }
    }
  }
}

