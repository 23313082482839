.page-error {
  padding-top: 50px;
  background-color: $color-page-start;

  display: flex;
  flex-direction: column;

  @media (max-height: $smallScreenHeight) {
    padding-top: 20px;
  }

  @media (min-width: $template1400) {
    max-width: none;
  }

  .hero {
    margin-bottom: 50px;
  }

  .container {
    display: flex;
    flex-grow: 1;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 130px;
    margin-top: 130px;

    @media (max-width: $mobileResponsiveMax) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .error-image-block {
    max-width: 200px;
    margin-bottom: 50px;

    @media (max-height: $smallScreenHeight) {
      max-width: 100px;
      margin-bottom: 0px;
    }

    img {
      width: 100%;
    }
  }
  .find-out {
    text-align: center;
    margin-bottom: 84px;

    @media (max-height: $smallScreenHeight) {
      margin-bottom: 50px;
    }

    strong {
      text-align: center;
      display: block;
      font-size: 27px;
      line-height: 40px;
      color: #000;
      font-family: $font700;
      font-weight: 700;
      &.main {
        color: $color-theme;
      }
    }
  }

  .link-row {
    text-align: center;
    padding-left: 22px;
    padding-right: 22px;

    color: #000;
    font-size: 16px;
    line-height: 20px;
    max-width: 550px;
    display: block;
    a {
      color: $color-theme;
      font-family: $font700;
      font-weight: 700;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
