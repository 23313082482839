.page.page-main.check-results {

  padding-top: 0;

  @media (min-width: $tabletVMin) {

    padding-top: 0;
  }
}

