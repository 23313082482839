.page-quiz--v1 {

  .sw-theme-progress {
    background-color: #fff;
    padding-left: 13px;
    padding-right: 13px;
  }
  #quiz-wizard.sw-theme-progress > .nav {
    flex-direction: row !important;
  }

  .page-step--logo {
    height: 70px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    @media (min-width: $template1400) {
      height: 160px;
      margin-top: 30px;
      align-items: center;
    }
  }

  .page-step--logo--under {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-top: 20px;
    padding: 0 10px;
    max-width: 520px;
    font-family: $font700;
    font-weight: 700;
    padding-bottom: 10px;
    font-size: 16px;
    line-height: 1.5;
    color: #000000;

    @media (min-width: $tabletVMin) {
      padding-bottom: 50px;
      font-size: 20px;
      margin: auto;
      margin-top: 20px;
      align-items: center;
    }
  }

  .page-step {
    max-width: 500px;

    @media (max-height: $smallScreenHeight) {

    }
    @media (max-width: $mobileResponsiveMax) {
      background-color: #fff;
    }

    @media (min-width: $template1400) {
      padding-bottom: 100px;
      border-radius: 10px;
      min-height: 0;
    }

    .action-row {
      margin-bottom: 27px;
      height: 20px;
    }

    .question {
      font-family: $font700;
      font-weight: 700;
      padding-bottom: 10px;
      font-size: 16px;
      line-height: 1.5;
      color: #000000;

      @media (min-width: $tabletVMin) {
        padding-bottom: 15px;
        font-size: 20px;
      }
    }

    .question-no {
      height: 50vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 50px;
      line-height: 2;
    }

    form {
      overflow: hidden;
      margin-bottom: 20px;

      .input-block {
        border-radius: 0px;
      }
    }

    .button-row {
      overflow: hidden;
    }
  }
}