.hero-home--wrapper-v3 {
  border-bottom: 24px solid white;
  background-color: #fff;
}
.hero-home-v3 {
  overflow: hidden;
  position: relative;
  height: 100%;
  max-width: 1392px;
  margin: auto;
  border-top: 24px solid white;
  margin-bottom: 24px;
  border-radius: 0 0 24px 0 ;
  background-image: url('/assets-v3/hero-img--mobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 100%;
  position: relative;

  @media (max-width: $mobileResponsiveMax) {
        height: 460px;
        width: calc(100% - 40px);
        margin: auto;
        border-radius: 0 0 24px 24px ;
        border-top: 20px solid white;
  }

  @media (max-width: $mobileResponsiveMax) {
      padding-top: 170px;
  }

    .hero-text {
      color: white;
      width: 100%;
      padding-left: 24px;
      position: absolute;
      top: 240px;
      z-index: z('hero-text');

      @media (max-width: $mobileResponsiveMax) {
        position: static;
        top: 0;
      }
 
      .title {
        font-size: 64px;
        line-height: 1.4;
        margin-bottom: 32px;
        font-family: $font600;
        font-weight: 600;

        @media (max-width: $mobileResponsiveMax) {
          font-size: 32px;
          line-height: 40px;
          margin-bottom: 8px;
          text-align: left;
        }
      }

      .subtitle {
        font-size: 24px;
        opacity: 0.76;
        line-height: 1.9;
        font-family: $fontComissioner;
        font-weight: normal;

        @media (max-width: $mobileResponsiveMax) {
          font-size: 14px;
          line-height: 20px;
          text-align: left;
        }
      }
  }

  .radiuser {
    width: 24px;
    height: 24px;
    background-image:
    radial-gradient(circle at 100% 0, rgba(0,0,0,0) 23px, #fff 24px);
    position: absolute;
    transform: rotate(90deg);
    z-index: z('hero-radiuser');
    &.r1 {
      transform: rotate(90deg);
      top: 84px;
      left: 0;
    }
    &.r2 {
      transform: rotate(180deg);
      top: 84px;
      right: 0px;
    }
    &.r3 {
      transform: rotate(0deg);
      bottom: 244px;
      left: 0;
    }
    &.r4 {
      transform: rotate(0deg);
      bottom: 0;
      left: 366px;
    }
    @media (max-width: $mobileResponsiveMax) {
        display: none;
    }
  }

  .hero-video {
    display: block;
    width: 100%;
    position: relative;

    @media (max-width: $mobileResponsiveMax) {
        
    }

    video {
      width: 100%;
      display: block;

      @media (max-width: $mobileResponsiveMax) {
        
      }
  
    }

    .hero-video-fader {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: z('hero-video-fader');
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);

      @media (max-width: $mobileResponsiveMax) {
        
      }
  
    }
  }
}

.hero-bottom-v3 {
 
}

.hero-text---btn-v3 {

  @media (max-width: $mobileResponsiveMax) {
        
  }

  &__desktop {
    background-color: white!important;

    width: calc(24px + 342px);
      height: calc(24px + 220px );
      padding-top: 24px;
      padding-right: 24px;
      border-radius: 0 24px 0 0  ;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: z('hero-video-btn-desktop');

      @media (max-width: $mobileResponsiveMax) {
        display: none;   
       }
      
    .button {
      width: 342px;
      height: 220px;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
      margin: 0;
      display: block;
      max-width: none;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: $mobileResponsiveMax) {
       
       }
    }

  }
  &__mobile {
   display: none;

   @media (max-width: $mobileResponsiveMax) {
    display: block;   
   }

    .button {
      @media (max-width: $mobileResponsiveMax) {
        font-size: 14px;
        font-family: $font500;
        font-weight: 500;
      }
    }
  }

}
.hero-arrow-v3 {
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: 64px;
  right: 64px;

  @media (max-width: $mobileResponsiveMax) {
    width: 18px;
    height: 18px;
    bottom: 16px;
    right: 16px;
  }

  img {
    @media (max-width: $mobileResponsiveMax) {
      width: 100%;
    }
  }
}
.hero-logos-v3 {
  position: absolute;
  bottom: 24px;
  left: 390px;
  z-index: z('hero-video-logos');

  @media (max-width: $mobileResponsiveMax) {
    display: none;
  }

  .logos-row {
    width: 376px;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: $mobileResponsiveMax) {
        
    }

    .item {
      height: 100%;

      @media (max-width: $mobileResponsiveMax) {
        
      }

      img {
        height: 100%;
      }
    }
  }
}