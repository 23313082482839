#schedule-calendar {
  width: 100%;
  // border: 0px solid #333333;
  border-collapse: collapse;

  * {
    box-sizing: border-box;
  }

  .tr-gap {
    height: 12px;

    @media (min-width: $template1400) {
      height: 12px;
    }
  }
  td {
    text-align: center;
    font-size: 10px;
    width: 38px;
    // height: 38px;
    height: 37px;

    @media (min-width: $template1400) {
      width: 50px;
      height: 50px;
      font-size: 13px;
    }

    a {
      text-decoration: none;
      color: #000;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .tr-days {
    td {
      border: 1px solid #f5faff;
    }
    .wDays {
      text-align: center;
      background-color: #f5faff;
      color: #000;
      font-size: 12px;

      @media (max-width: $mobileResponsiveMax) {
        height: 33px;
      }

      @media (min-width: $template1400) {
        font-size: 13px;
      }
    }
  }

  .tr-bordered {
    td {
      border: 1px dashed #cdd9e8;

      position: relative;
    }

    .noclass {
      color: rgba(0, 0, 0, 0.2);
    }

    .no_opening {
    }

    .noevent {
    }

    .withevent {
      position: relative;
      cursor: pointer;

      .schedule {
        position: absolute;
        z-index: 2;
        padding: 5px 8px;
        background-color: #000;
        color: #fff;
        font-size: 10px;
        border-radius: 6px;
        top: -30px;
        left: -20px;
        text-align: left;
        display: none;
        white-space: nowrap;

        @media (min-width: $tabletVMin) {
          left: -90px;
        }

        @media (min-width: $template1400) {
          font-size: 14px;
          top: -50px;
          left: -50px;
          padding: 8px 14px;
        }

        span {
          font-size: 14px;

          @media (min-width: $template1400) {
            font-size: 20px;
          }
        }
      }

      &:hover {
        .schedule {
          display: block;
        }
      }

      .round {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #ff5872;
        position: absolute;
        bottom: 5px;
        left: 50%;
        margin-left: -2px;
        z-index: 1;

        @media (min-width: $template1400) {
          width: 8px;
          height: 8px;
          bottom: 6px;
          margin-left: -5px;
        }
      }

      a {
        text-decoration: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
      }

      span {
        text-decoration: none;

        div {
          text-decoration: none;
          // color: #ffffff;
        }
      }
    }
  }

  .p-year {
    text-align: center;
    font-weight: bold;
    color: #2e2e2e;
    font-size: 10px;
    padding-bottom: 7px;

    @media (min-width: $template1400) {
      font-size: 16px;
      padding-top: 10px;
      padding-bottom: 3px;
    }
  }
  .p-month {
    font-weight: bold;
    text-align: center;
    color: #2e2e2e;
    font-size: 19px;

    @media (min-width: $template1400) {
      font-size: 28px;
    }
  }

  .tr-select {
    td {
      font-size: 11px;
      text-align: center;
      padding-bottom: 4px;
      font-family: $font700;
      font-weight: 700;

      @media (min-width: $template1400) {
        font-size: 14px;
        padding-bottom: 12px;
        padding-top: 13px;
        height: 41px;
      }
    }
  }

  .cMonth {
    position: relative;
  }
  .mNav {
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    text-transform: uppercase;
    width: 70px;
    text-align: right;

    position: absolute;
    top: 8px;
    right: 2px;

    @media (min-width: $template1400) {
      width: 140px;
      top: 17px;
      right: 0;
    }

    a {
      display: inline-block;
      background-color: #eef2f7;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      cursor: pointer;
      background-size: cover;
      width: 18px;
      height: 18px;
      border-radius: 50%;

      @media (min-width: $template1400) {
        width: 26px;
        height: 26px;
      }
    }

    .a-prev {
      background-image: url('/svg/cal-prev.svg');
    }
    .a-next {
      background-image: url('/svg/cal-next.svg');
      margin-left: 8px;

      @media (min-width: $template1400) {
        margin-left: 10px;
      }
    }
  }

  .day_listing {
    a {
      color: #ffffff;
      text-decoration: none;
    }
  }
}
