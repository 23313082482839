.page-start {
  padding-top: 50px;
  background-color: $color-page-start;

  .mobile-vertical-centered {
    @media (max-height: $smallScreenHeight) {
      display: flex;
      height: calc(100vh - 101px);
      flex-direction: column;
      justify-content: center;
    }
  }

  @media (max-height: $smallScreenHeight) {
    padding-top: 20px;
  }

  @media (min-width: $template1400) {
    padding-top: 70px;
    padding-bottom: 100px;
    background-color: $color-page-bg;
    max-width: none;
  }

  .action-row {
    .ico {
      float: right;
      position: relative;
      top: 5px;
    }
    .logo-03 {
      float: left;
    }
  }

  .hero {
    height: 160px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-height: $smallScreenHeight) {
      height: 80px;
      margin-bottom: 30px;
    }

    div {
      display: flex;
    }
  }

  .find-out {
    text-align: center;
    margin-bottom: 84px;

    @media (max-height: $smallScreenHeight) {
      margin-bottom: 30px;
    }

    strong {
      text-align: center;
      display: block;
      font-size: 27px;
      line-height: 40px;
      color: #000;
      font-family: $font700;
      font-weight: 700;
      &.main {
        color: $color-theme;
      }
    }
  }

  .link-row {
    text-align: center;
    padding-left: 22px;
    padding-right: 22px;
    color: #000;
    font-family: $font500;
    font-weight: 500;

    @media (max-height: $smallScreenHeight) {
      padding-left: 40px;
      padding-right: 40px;
    }

    a {
      text-decoration: none;
      color: #000;
      font-size: 16px;
      line-height: 20px;
      font-family: $font700;
      font-weight: 700;
      max-width: 300px;
      display: block;
      margin: auto;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .share-row {
    font-size: 16px;
    line-height: 22px;
    font-family: $font700;
    font-weight: 700;
    text-align: center;

    .social {
      margin-top: 26px;
    }
  }

  .button-row {
    overflow: hidden;
    margin-bottom: 28px;

    &.result {
      margin-top: 26px;
      margin-bottom: 48px;
    }

    @media (max-height: $smallScreenHeight) {
      padding-top: 30px;
    }
  }

  form {
    overflow: hidden;
    padding-top: 48px;

    .title {
      margin-bottom: 45px;
    }

    strong {
      text-align: center;
      display: block;
      font-size: 20px;
      line-height: 30px;
      color: #000;
      font-family: $font700;
      font-weight: 700;
      &.main {
        color: $color-theme;
      }
    }

    .input-block {
      height: 52px;
    }
  }
}
