.page-course-video {
    background-color: #F5F8FE;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    .page-flat-content {
        display: flex;
        flex: 1;
    }

    // topbar
    .course-topbar {
        position: relative;
        display: flex;
        align-items: center;
        padding: 16px 20px;
        @media (min-width: $tabletVMax) {
            padding: 18px 24px;
            border-bottom: 1px solid #F1F0F2;
            background-color: $color-white;
        }
        .lang-menu {
            position: relative;
            margin: 0 0 0 auto;
            .flag-ico {
                width: 21px;
                height: 15px;
                border-radius: 3px;
            }
            .main-lang {
                font-size: 14px;
                border-radius: 12px;
                background: $color-white;
                border: 1px solid #F1F0F2;
                padding: 15px 12px;
                cursor: pointer;
                text-transform: uppercase;
                display: flex;
                align-items: center;
                position: relative;
                z-index: 9;
                @media (min-width: $tabletVMax) {
                    padding: 12px 20px;
                    background: #F3F3F6;
                    border: 1px solid #F3F3F6;
                }
                .flag-ico {
                    margin-right: 12px;
                }
            }
            .name-lang {
                font-family: $fontComissioner;
                padding-right: 4px;
                line-height: 1.2;
                @media (min-width: $tabletVMin) {
                    font-size: 16px;
                }
            }
            .dropdown {
                list-style: none;
                font-family: $fontComissioner;
                font-size: 14px;
                color: $color-primary;
                margin: 3px 0 0;
                padding: 0;
                position: absolute;
                z-index: 99;
                right: 0;
                display: none;
                border-radius: 12px;
                background-color: $color-white;
                padding: 12px;
                box-shadow: 0px 4px 12px -10px $color-primary;
                li {
                    padding: 12px 16px;
                    border-radius: 8px;
                    cursor: pointer;
                    min-width: 152px;
                    @media (min-width: $tabletVMin) {
                        min-width: 256px;
                    }
                    svg {
                        margin-right: 12px;
                    }
                    & + li {
                        margin-top: 12px;
                    }
                    &:hover {
                        background: rgba(99, 174, 255, 0.15);
                    }
                    a {
                        color: $color-primary;
                        text-decoration: none;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }

    // left sidebar
    .course-sidebar {
        width: 320px;
        padding: 32px 20px;
        border-right: 1px solid #EBEBEB;
        background: $color-white;
        flex-direction: column;
        z-index: 9;
        display: none;
        &.open {
            @media (max-width: $tabletVMax) {
                display: flex;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: $color-white;
                padding: 69px 20px;
            }
        }
        @media (min-width: $tabletVMax) {
            display: flex;
        }
        .logo-header-course {
            display: none;
            margin: 0 auto 12px;
            @media (min-width: $tabletVMax) {
                display: block;
            }
            img {
                max-width: 225px;
            }
        }
        .sidebar-list {
            list-style-type: none;
            counter-reset: li;
            padding: 20px 0 10px;
            border-bottom: 1px solid #EBEBEB;
            flex: 1 1 0%;
            @media (min-width: $tabletVMax) {
                border-top: 1px solid #EBEBEB;
                max-height: calc(100vh - 265px);
                overflow-y: auto;
            }
            li {
                color: $color-primary;
                font-family: $fontComissioner;
                font-weight: 500;
                font-size: 14px;
                line-height: 1.6;
                padding: 13px 20px;
                border-radius: 12px;
                display: flex;
                cursor: pointer;
                transition: all .25s;
                &.active,
                &:hover {
                    background: rgba(99, 174, 255, 0.15);
                }
                & + li {
                    margin-top: 12px;
                }
                &::before {
                    content: counter(li)'.';
                    counter-increment: li 1;
                    padding-right: 6px;
                }
            }
            .locked{
                color: $color-locked;
            }
        }
        .logout-btn {
            font-family: $fontComissioner;
            font-size: 16px;
            display: flex;
            align-items: center;
            width: 100%;
            padding: 28px 35px 0;
            border: none;
            background-color: transparent;
            cursor: pointer;
            transition: all 0.25s;
            &:hover {
                opacity: .85;
            }
            .logout-ico {
                margin-right: 12px;
            }
        }
    }

    // main content
    .course-main {
        flex: 1;
        .video-wrap {
            padding: 0 24px 24px;
            @media (min-width: $tabletVMin) {
                padding: 24px;
            }
            .embed-container {
                --video--width: 1073;
                --video--height: 540;
                position: relative;
                padding-bottom: calc(var(--video--height) / var(--video--width) * 100%); /* 41.66666667% */
                overflow: hidden;
                max-width: 100%;
                background: black;
                border-radius: 12px;
                @media (min-width: $tabletVMin) {
                    border-radius: 24px;
                }
            }
            .embed-container iframe,
            .embed-container object,
            .embed-container embed {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
        .video-title-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 20px 0;
            @media (min-width: $tabletVMax) {
                flex-direction: row;
            }
        }
        .video-title {
            font-family: $font600;
            font-weight: 600;
            font-size: 16px;
            color: $color-primary;
            display: none;
            @media (min-width: $tabletVMax) {
                font-size: 24px;
                display: block;
                margin-top: 0;
            }
            &.title-mobile {
                display: block;
                margin-bottom: 30px;
                @media (min-width: $tabletVMax) {
                    display: none;
                }
            }
        }
    }

    // burger - mobile menu button
    .mobile-button {
        position: absolute;
        width: 48px;
        height: 48px;
        top: 50%;
        left: 20px;
        background-color : transparent;
        cursor: pointer;
        transition: all 0.3s ease;
        transform: translateY(-50%);
        z-index: 9;
        background-color: $color-white;
        padding: 12px;
        border-radius: 8px;
        border: 1px solid #F1F0F2;
        @media (min-width: $tabletVMax) {
            display: none;
        }
        &.active {
            background-color: #F3F3F6;
            span {
                opacity: 0;
            }
            &:before {
                transform: rotate3d(0, 0, 1, 45deg);
            }
            &:after {
                transform: rotate3d(0, 0, 1, -45deg);
            }
        }
        span {
            position: absolute;
            width: 18px;
            height: 2px;
            left: 0;
            right: 0;
            top: 50%;
            margin: auto;
            overflow: hidden;
            text-indent: 200%;
        }
        &:before,
        &:after,
        span {
            background-color: $color-primary;
            transition: all ease 0.3s;
        }
        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0;
            height: 2px;
            width: 18px;
            left: 0;
            right: 0;
            top: 50%;
            margin: auto;
            transform-origin: 50% 50%;
        }
        &:before {
            transform: translate3d(0, -5px, 0);
        }
        &:after {
            transform: translate3d(0, 5px, 0);
        }
    }
}

.banner-section {
    border-radius: 20px;
    background: url('/images/banner-bg.png'), lightgray 50% / cover no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 15px 10px 50px;
    text-align: center;
    width: 100%;
    gap: 15px;
    @media (min-width: $tabletH) {
        flex-direction: row;
        text-align: left;
        max-width: 900px;
        padding: 15px 20px 25px;
        gap: 20px;
    }
    .img-holder {
        width: 100%;
        max-width: 305px;
        margin: -40px auto auto;
        @media (min-width: $tabletH) {
            margin: 10px 0 0 -35px;
        }
        img {
            max-width: 100%;
        }
    }
    .text-holder {
        color: $color-white;
        max-width: 500px;
        .title {
            font-size: 28px;
            font-weight: 600;
            margin: 0 0 16px;
        }
        .button {
            font-size: 20px;
            border-radius: 15px;
            width: 100%;
            max-width: 341px;
            margin: 0 auto 25px;
            height: 42px;
            line-height: 42px;
            @media (min-width: $tabletH) {
                border-radius: 20px;
                max-width: 451px;
                margin: 0 0 16px;
                height: 56px;
                line-height: 56px;
            }
        }
        .description {
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -0.48px;
            text-wrap: balance;
        }
        .highlight {
            font-family: $font600;
            font-weight: 600;
            text-decoration: underline;
        }
    }
}
