.section--send_text-v3 {
  background-color: #18314E;

  max-width: 1344px;
  margin: auto;
  margin-bottom: 80px;

  border-radius: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 32px;
  padding-left: 114px;

  position: relative;

    @media (max-width: $mobileResponsiveMax) {
      flex-direction: column;

      padding: 32px 12px 14px 12px;
      border-radius: 48px;
      
      width: calc(100% - 40px);
      margin: auto;
      margin-bottom: 48px;
    }

    .b-img-1 {
      display: block;
      content: "";
      position: absolute;
      z-index: 1;
      top: 64px;
      left: 66px;
      width: 138px;
      height: 138px;
      // background-image: url('/assets-v3/bullet-sms-01.svg');
      // background-size: contain;
      // background-repeat: no-repeat;
      // background-position: 0 0;
      opacity: 0.25;

      @media (max-width: $mobileResponsiveMax) {
        // display: none;
        top: 32px;
        left: 32px;
        width: 78px;
        height: 78px;
      }
    }
    .b-img-2 {
      display: block;
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 100px;
      left: 50%;
      width: 64px;
      height: 64px;
      // background-image: url('/assets-v3/bullet-sms-02.svg');
      // background-size: contain;
      // background-repeat: no-repeat;
      // background-position: 0 0;
      opacity: 0.25;

      @media (max-width: $mobileResponsiveMax) {
        display: none;
      }
    }
    .target-img-3 {
      display: block;
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 0px;
      right: 0px;
      width: 536px;
      height: 536px;
      // background-image: url('/assets-v3/target-sms-01.svg');
      // background-size: contain;
      // background-repeat: no-repeat;
      // background-position: 0 0;
      opacity: 0.25;

      @media (max-width: $mobileResponsiveMax) {
        // display: none;
        
        width: 267px;
        height: 267px;
        left: 50%;
        top: 109px;
        margin-left: -133px;
        // background-position: 50% 109px;
        // background-size: 267px;
        z-index: 0.5;
      }
    }

    .text {
      @media (max-width: $mobileResponsiveMax) {
        margin-bottom: 44px;
      }
    }

    .text--desktop {
      color: white;
      text-align: left;
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 64px;
      padding-top: calc(124px - 32px);
      font-family: $font600;
      font-weight: 600;

      @media (max-width: $mobileResponsiveMax) {
        display: none;
      }
    }
    .text--mobile {
      display: none;

      @media (max-width: $mobileResponsiveMax) {
        display: block;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 24px;
        text-align: center;
        color: white;
        font-family: $font500;
        font-weight: 500;
      }
    }

    .button {
      float: left;
      height: 80px;
      line-height: 80px;
      width: 400px;
      border-radius: 24px;
      font-size: 16px;
      display: block;
      max-width: 400px;
      position: relative;
      z-index: 2;

      @media (max-width: $mobileResponsiveMax) {
        height: 56px;
        line-height: 56px;
        width: 180px;
        border-radius: 20px;
        font-size: 14px;
        float: none;
        font-family: $font500;
        font-weight: 500;
      }
    }

    .imgs {
      position: relative;

      width: 478px;
      height: 474px;

      @media (max-width: $mobileResponsiveMax) {
          width: 311px;
          height: 309px;
          margin: auto;
      }

      .img-1 {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        // width: 200px;
        z-index: 1;

        @media (max-width: $mobileResponsiveMax) {
          
        }

      }
      .img-2 {
        position: absolute;
        top: 0;
        right: 0;

        height: 100%;
        // width: 200px;
        z-index: 2;

        @media (max-width: $mobileResponsiveMax) {
          
        }
      }
    }

 }
