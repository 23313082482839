.steps-horizontal {
  background-color: #fff;
  padding-top: 50px;
  padding-bottom: 26px;

  @media (min-width: $tabletVMin) {
  }

  @media (min-width: $template1400) {
    padding-top: 34px;
    padding-bottom: 33px;
  }

  .title {
    line-height: 1.5;
    font-family: $font700;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;
    padding: 0 20px;
    // font-size: 25px;
    font-size: 23px;

    @media (min-width: $tabletVMin) {
      padding: 0 30px;
      // font-size: 20px;
      // font-size: 24px;
      margin-bottom: 90px;
      font-size: 32px;
    }

    @media (min-width: $template1400) {
      // font-size: 26px;
      font-weight: 900;
      margin-bottom: 97px;
    }
  }

  .steps {
    display: flex;
    flex-direction: column;

    @media (min-width: $template1400) {
      flex-direction: row;
      width: calc(100% - 100px);
      margin: auto;
      justify-content: space-between;
      position: relative;

      &:before {
        display: block;
        content: '';
        width: calc(100% - 300px);
        height: 2px;
        border-top: dashed 1px #ccd7e5;
        position: absolute;
        top: -22px;
        left: 50%;
        margin-left: calc(150px - 50%);
      }
    }

    .step {
      display: flex;
      flex-direction: column;

      @media (max-width: $mobileResponsiveMax) {
        margin-bottom: 43px;
      }

      @media (min-width: $template1400) {
        max-width: 300px;
        position: relative;
        padding: 0 20px 20px 20px;

        &:before {
          display: block;
          content: '';
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #d8374f;
          position: absolute;
          left: 50%;
          margin-left: -5px;
          top: -26px;
        }
      }

      .step-title {
        display: flex;
        line-height: 1.7;
        font-family: $font700;
        font-weight: 700;
        color: $color-theme;
        text-align: center;
        justify-content: center;

        // font-size: 15px;
        font-size: 17px;
        margin-bottom: 4px;

        @media (min-width: $tabletVMin) {
          // font-size: 14px;
          margin-bottom: 0px;

          // TODO: Check this ! (ML)
          // font-size: 24px;
        }

        @media (min-width: $template1400) {
          margin-bottom: 18px;
        }
      }
      .step-content {
        display: flex;
        text-align: center;
        color: $color-step;
        line-height: 1.7;
        justify-content: center;

        // font-size: 14px;
        font-size: 17px;
        padding-left: 15px;
        padding-right: 15px;

        @media (min-width: $tabletVMin) {
          // font-size: 14px;
          padding-left: 0;
          padding-right: 0;
        }

        @media (min-width: $template1400) {
          // font-size: 14px;
          // line-height: 1.9;
        }
      }
      .step-divider {
        display: flex;
        justify-content: center;
        width: 1px;
        height: 40px;
        border-left: dashed 1px $color-important-border;
        margin-left: 50%;
        margin-bottom: 4px;

        @media (min-width: $tabletVMin) {
          // border-left: dashed 2px $color-important-border;
        }

        @media (min-width: $template1400) {
          display: none;
        }
      }
    }
  }
}
