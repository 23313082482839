// placeholders
@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

//input,
//textarea {
//  @include input-placeholder {
//    color: $grey;
//  }
//}

// z-index structure
@function z($name) {
  @if index($z-indexes, $name) {
    @return (length($z-indexes) - index($z-indexes, $name))+1;
  } @else {
    @warn 'There is no item "#{$name}" in this list; choose one of: #{$z-indexes}';
    @return null;
  }
}

$z-indexes: (
  'sms-mobile-close',
  'sms-mobile-opener',
  'sms-col',
  'sms-mobile-fader',
  'menu-mobile',
  'promo-banner',
  'promo-banner--close',
  'modal',
  'schedule',
  'header-transparent',
  'menu-mobile-close',
  'menu-mobile-opener',
  'menu-col',
  'hero-video-btn-desktop',
  'hero-video-logos',
  'menu-mobile-fader',
  'site-header',
  'contact-form-block',
  'slick-arrow',
  'layer',
  'layer',
  'play-btn-img',
  'play-btn',
  'hero-text',
  'hero-radiuser',
  'hero-video-fader',
  'page-wrapper',
  'site-footer'
);

//.site-header {
//  z-index: z('site-header');
//}

// truncate
@mixin truncate($truncation-boundary) {
  max-width: $truncation-boundary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// This ration for the Responsive mobile from Small Mobile
@function vwm($size) {
  // 375px = 100vw
  // 1px = 0.2666666666666667vw
  $ratioVWM: 0.2666666666666667; // This is most correct ratio for the responsive
  $vwmSize: $size * $ratioVWM;
  @return $vwmSize * 1vw;
}
